import React, { useEffect, useReducer, useRef, useState } from 'react'
import { loadCourseDocuments, loadDegrees, loadProfile, loadQuestions, saveQuestionDegree } from '../utils/Network';
import { getDefaultName, getDefaultValue, getUser, isDocument, isImage, isVideo } from '../utils';
import Modal from 'react-modal';
import UrlEmbed from '../components/UrlEmbed';
import { useStateValue } from '../StateProvider';


export default function RefereePanel({ course, is_rated }) {

    const [loading, setLoading] = useState(null);
    const [modal_file, setModalFile] = useState(null);
    const [modal_url, setModalUrl] = useState(null);
    const [modal_talent, setModalTalent] = useState(null);
    const [modal_artist, setModalArtist] = useState(null);

    const [courseDocuments, setCourseDocuments] = useState(null);
    const [selectedCourseDocument, setSelectedCourseDocument] = useState(null);
    const [modalQuestions, setModalQuestions] = useState(null);
    const [referee, setReferee] = useState(null);
    const [modal_degrees, setModalDegrees] = useState(null);
    const [modal_loading, setModalLoading] = useState(null);
    const [update_questions, setUpdateQuestions] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [, forceUpdatePage] = useReducer((x) => x + 1, 0);


    const user = getUser();
    useEffect(() => {
        setLoading(true)

        loadCourseDocuments(course, is_rated).then(data => {
            
            if(data.results){
                var courseDocuments = data.results
                if(is_rated)
                    courseDocuments.sort((a,b) => b.rate - a.rate);
                setCourseDocuments(courseDocuments)

            }

            setLoading(false)
        })

        loadProfile().then(referee => {
            setReferee(referee);

        });


    }, [])

    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x=>x.key == str );
    
        if (translate) return getDefaultValue(translate);
        else return "#" + str;
      };

    const PrepareModal = (courseDocument, file, url, talent_id) => {


        if (url === "undefined") url = null

        setModalDegrees(null)
        setModalFile(file)
        setModalUrl(url)
        setModalTalent(talent_id)
        setSelectedCourseDocument(courseDocument)
        if (!referee) setModalIsOpen(false);

        setModalArtist(courseDocument.userdocument.usertalent.user.profile)

        setModalLoading(true)
        loadDegrees(courseDocument.course.id, courseDocument.userdocument.usertalent.user_id, courseDocument.userdocument.usertalent.talent.id, referee.user).then((degrees => {
            
            setModalDegrees(degrees)
            loadQuestions(courseDocument.userdocument.usertalent.talent.id).then((questions => {
                setModalQuestions(questions)
                setModalLoading(false)
            }))

        }))

        setModalIsOpen(true)
        forceUpdatePage()

    }

    const closeModal = () => {
        setModalIsOpen(false)

    }

    const getQuestionDegree = question_id => {
        if (!modal_degrees) return null;
        var degree_item = modal_degrees.find(
            x => x[0].question.id == question_id
        )
        if (degree_item) {
            return degree_item[0].degree
        }


    }

    const onChange = (e) => {

        let question_id = e.target.getAttribute("qid");
        let degree_id = e.target.getAttribute("dgid");
        var value = e.target.value;

        let temp = modalQuestions;
        var q = temp.find(x => x.id == question_id)
        q.degree = value;
        q.degree_id = degree_id;
        setModalQuestions(temp)

    }

    const saveChanges = (e) => {

        setUpdateQuestions(false)
        modalQuestions.forEach(question => {
            saveQuestionDegree(question.degree_id, selectedCourseDocument.course.id, question.id, question.degree, selectedCourseDocument.userdocument.usertalent.user_id, selectedCourseDocument.userdocument.usertalent.talent.id, referee.user).then(data => {

            })
        });

        var temp = courseDocuments;
        var item = temp.find(x=>x.id == selectedCourseDocument.id);
        item.is_rated=true;
        if(is_rated==0){
            const index = temp.indexOf(item);
            temp.splice(index, 1); 
        }
        setCourseDocuments(temp)
        closeModal()

        setUpdateQuestions(Math.floor(Math.random() * 100))

    }

    return (
        <div className="">

            <Modal
                closeTimeoutMS={500}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="Modal Modal-lg"
                ariaHideApp={false}
                overlayClassName="Overlay"
            >
                <div className="modal-content">
                    <div className="modal-header">
                    <img className="rounded-circle user-logo" width="40" height="40" alt="" src={modal_artist && modal_artist.avatar ? modal_artist.avatar : "/assets/images/user.png"}></img>
                        <h4 className="modal-title lan-rtl" id="defaultModalLabel">
                            {translate('Rate')} {modal_artist && modal_artist.first_name}</h4>
                        <button type="button" className="close" onClick={closeModal}>&times;</button>
                    </div>
                    <div className="modal-body row">

                        <div className="col-md-8 file-container flex-center">

                            {modal_url &&
                                <UrlEmbed url={modal_url} referee={true} />
                            }

                            {!modal_url &&
                                <>

                                    {modal_file && isImage(modal_file) &&
                                        <img src={modal_file} className="img-fluid  " alt="" />
                                    }
                                    {modal_file && isVideo(modal_file) &&
                                        <video controls width="100%" >
                                            <source src={modal_file} type="video/mp4" />
                                        </video>
                                    }
                                    {modal_file && isDocument(modal_file) &&
                                        <a href={modal_file} target="_blank">
                                            <img src="assets/images/pdf_icon.png" className="img-fluid border-radius-0 " alt="" />
                                        </a>
                                    }
                                </>
                            }
                        </div>
                        <div className="col-md-4">
                            {modal_loading &&
                                <>
                                    <div className="bounce-loader">
                                        <div className="bounce1"></div>
                                        <div className="bounce2"></div>
                                        <div className="bounce3"></div>
                                    </div>
                                </>
                            }
                            {!modal_loading &&
                                <>
                                    {modalQuestions && modalQuestions.map(item => {

                                        var degree_item = getQuestionDegree(item.id)
                                        return (
                                            <div key={item.id} className="question">
                                                <p className="mb-1 lan-text-right">{getDefaultName(item.names)} </p>
                                                <input type="range" min="0" max="10" defaultValue={degree_item && degree_item.degree} dgid={degree_item && degree_item.id} qid={item.id} onChange={onChange} className={`form-control-range mb-3 ${item.degree < 3 ? "range-danger" : null}`} />
                                            </div>
                                        )
                                    }
                                    )}
                                </>
                            }

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light pull-left kfont" onClick={closeModal}>{translate('Cancel')}</button>
                        <button type="button" className="btn btn-primary pull-right" onClick={saveChanges} data-dismiss="modal">{translate('Save Changes')}</button>
                    </div>
                </div>

            </Modal>


            <div className=" mt-2 mx-0 row mb-4">
                {loading &&
                    <div className="loader-container">
                        <div className="bounce-loader">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                        </div>
                    </div>
                }
                {courseDocuments && courseDocuments.map((item, index) => (
                    <div key={index} className={`pull-left mr-3 mb-3  `}>
                        <div className="portfolio-item " onClick={() => { PrepareModal(item, item.userdocument.file, item.userdocument.url, item.course.talent_id) }} >
                            <span className={`thumb-info  thumb-info-lighten circle circle-grandparent `}>
                                <span className="thumb-info thumb-info-centered-icons circle ">
                                    <span className={`thumb-info-wrapper ${item.is_rated ? "rated-border":"tusi-border"}  circle`}>

                                        {item.userdocument.usertalent.user.profile.avatar &&

                                            <img src={item.userdocument.usertalent.user.profile.avatar} className="document-user-img" alt="" />

                                        }

                                        {!item.userdocument.usertalent.user.profile.avatar &&
                                            <>
                                                {item.userdocument && item.userdocument.file && isImage(item.userdocument.file) &&
                                                    <img src={item.userdocument.file} className="jury-media" alt="" />
                                                }
                                                {item.userdocument && item.userdocument.file && isVideo(item.userdocument.file) &&
                                                    <video height="200"  >
                                                        <source src={item.userdocument.file} type="video/mp4" />
                                                    </video>
                                                }

                                                {item.userdocument && item.userdocument.file && isDocument(item.userdocument.file) &&
                                                    <img src="assets/images/pdf_icon.png" className="img-fluid border-radius-0 full-width jury-media" alt="" />
                                                }

                                            </>
                                        }


                                        <span className="thumb-info-action">
                                            <a href="#" data-toggle="modal" data-target="#defaultModal">
                                                <span className="thumb-info-action-icon thumb-info-action-icon-light"><i className="fas fa-play-circle text-dark text-dark"></i></span>
                                            </a>

                                        </span>
                                    </span>
                                </span>
                            </span>
                        </div>
                    </div>

                ))}
                <div className="clear"></div>
            </div>


        </div>

    )

}
