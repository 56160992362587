import React, { useEffect, useState } from 'react'
import { useStateValue } from '../StateProvider';
import { BuildTalents, getDate, getDefaultName, getDefaultValue } from '../utils';
import { loadFaqs, loadMenuTalents, loadPosts, loadVideoGalleries } from '../utils/Network';
import parse from 'html-react-parser'
import ShowVideoModal from '../components/dialogs/ShowVideoModal';

export default function FaqsContent({ id }) {

    const [loading, setLoading] = useState(false);
    const [faqs, setFaqs] = useState(null);
    const [active, setActive] = useState(null);
    const [show_video_is_open, setShowVideoIsOpen] = useState(false);
    const [video, setVideo] = useState(false);
    const [videos, setVideos] = useState(null)


    useEffect(() => {
        setLoading(true)
        document.title = 'KurdishTalent | Frequently asked questions '


        loadFaqs().then(faqs => {
            setFaqs(faqs)
            setLoading(false)
        })


        loadVideoGalleries("help").then(videos => {
            setVideos(videos);
        });
        setLoading(false)

    }, [])


    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x => x.key == str);

        if (translate) return getDefaultValue(translate);
        else return str;
    };


    return (
        <div id="main" role="main" className="main bg-blog">

            <section className="page-header pb-0 page-header-modern page-header-lg mb-0 pb-4  page-header-background   overlay-op-7 ">
                {/* <div className="container">
                    <div className="row">
                        <div className="col-md-12 align-self-center order-1">
                            <ul className="breadcrumb d-block text-center lan-rtl">
                                <li><a href="/" className="kfont">{translate('Home')}</a></li>
                                <li className={!id && "active kfont"}>{translate('FAQ')}</li>

                            </ul>
                        </div>

                        <div className="col order-3 appear-animation animated fadeInUp appear-animation-visible" data-appear-animation="fadeInUp" data-appear-animation-delay="0" data-appear-animation-duration="1s" >
                            <hr className=" slide-line blackhr" />
                        </div>
                    </div>
                </div> */}

            </section>

            <div className="container ">
                {loading &&
                    <>
                        <div className="bounce-loader">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                        </div>
                    </>
                }

                {!loading &&

                    <div className="container pb-4 mt-header">
                        <div className="overflow-hidden">
                            <h2 className=" font-weight-bold text-10 mb-2 pt-0 mt-0 appear-animation animated maskUp appear-animation-visible" data-appear-animation="maskUp" data-appear-animation-delay="300" style={{ animationDelay: "300ms" }}>
                                {translate("FAQ")}
                            </h2>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 order-lg-1">
                                <aside className="sidebar">
                                    <ul className="simple-post-list">
                                        {videos && videos.map(item => (
                                            <li key={item.id} className="lan-rtl">
                                                <div className="post-info lan-rtl lan-text-right " onClick={(e) => { e.preventDefault(); setVideo(item); setShowVideoIsOpen(true) }}>
                                                    <a href="#" className="lan-text-right  kfont" title={item.title}>
                                                        <div className="mb-2 vd-container">
                                                            <video width="100%" height="150" poster={item.image} >
                                                                <source src={item.file} type="video/mp4" />
                                                            </video>
                                                            <span className="infoicon">
                                                                <i className="fa fa-play"></i>
                                                            </span>
                                                        </div>
                                                    </a>


                                                </div>


                                            </li>
                                        ))}
                                    </ul>
                                </aside>
                            </div>
                            <div className="col-lg-9">

                                <div className="toggle toggle-primary m-0" data-plugin-toggle>


                                    {faqs?.map((item) => (
                                        <section key={item.id} onClick={() => { if (item.id != active) setActive(item.id); else setActive(0); }} className={`toggle ${active == item.id ? "active" : ""}`}>
                                            <a className="toggle-title kfont lan-rtl lan-text-right">{item.question}</a>
                                            <div className="toggle-content ">
                                                <p className="kfont lan-rtl lan-text-right">{parse(item.answer)}</p>
                                            </div>
                                        </section>
                                    ))}


                                </div>

                            </div>

                        </div>


                    </div>


                }


            </div>
            <ShowVideoModal key={video} isOpen={show_video_is_open} url={video.file} poster={video.image} setIsOpen={setShowVideoIsOpen} />

        </div>
    )

}
