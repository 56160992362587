import React from 'react'
import LatestPostsController from '../../Controller/LatestPostsController';
import { translate } from 'utils';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import PostItem from './PostItem';

function LatestPosts({ translations }) {

  const { isLoading, posts } = LatestPostsController();

  return (

    <div className="row mt-5 sm-mt-2 lan-rtl">
      <div className="col">
        {/* <h4 className='text-center'>{translate(translations, "Latest on Kurdish Talent")}</h4> */}
        <hr className="solid mt-3 lan-float-right" />
        <div className="clear"></div>
        {isLoading && <LoadingOverlay loading={true} />}
        <div className="row">
          {posts && posts.map((post, index) => (
            <div key={`post${post.id}`} className={`col-lg-4 mb-2 appear-animation animated fadeInRightShorter appear-animation-visible`}
              data-appear-animation="fadeInRightShorter"
              // data-appear-animation-delay="260"
              style={{ animationDelay: `${index + 0.5}s` }}
              >
              <PostItem key={"post_" + post.id} translations={translations} post={post} />
            </div>
          ))}


        </div>
      </div>
    </div>

  )
}

export default LatestPosts