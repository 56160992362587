import React from 'react'
import LoginForm from '../components/user/LoginForm';
import { useStateValue } from '../StateProvider';
import { getDefaultValue } from '../utils';
import parse from "html-react-parser";


export default function Validate(props) {

    var result = props.location.search;
    
    const queryString = props.location.search;
    const sp = new URLSearchParams(queryString);

    const emailValid = sp.get('email_valid');
    const message = sp.get('message');
    const email = sp.get('email');

    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x=>x.key == str );
    
        if (translate) return getDefaultValue(translate);
        else return "#" + str;
      };

        return (
            <div>
                <div role="main" className="main">

                    <section className="page-header pb-0 section-with-shape-divider page-header-modern bg-color-dark-scale-1 page-header-lg mb-0 pb-4  page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-7 bg-artist">
                        <div className="container">
                            <div className="row">

                                <div className="col-md-12 align-self-center p-static order-2 text-center">


                                    <h1 className="font-weight-bold text-light mb-20">
                                        
                                        {translate("Welcome to Kurdish Talent")}
                                    </h1>

                                </div>


                            </div>
                        </div>
                        <div className="shape-divider shape-divider-bottom" >
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 136" preserveAspectRatio="xMinYMin">
                                <path d="M 0 0.11 L 0 136 L 1920 136 L 1920 1.9 C 1650.0482 79.0955 1327.0648 134 980 134 C 615.6218 134 277.7878 84.5059 0 0.11 Z" fill="#212529" />
                            </svg>
                        </div>
                    </section>

                    <div className="container pt-3 pb-2">

                        <div className="row text-center pt-4">
                            <div className="col">
                                <h4 className="text-primary text-center lead tall mb-2 text-color-light">
                                    { emailValid=="True" && 
                                    parse(translate("Your Email Address verified successfully"))}

                                    { emailValid!='True' && 
                                    parse(translate("Activation Expired. please try to login again and activate your account."))}
                                </h4>

                                <div className="text-center">
                                <hr className="full-width" />
                                </div>

                                <LoginForm email={email} />

                                {/* <p className=" text-color-hover-white color-gray text-2 ">
                                    <a href="/" className="">{translate("Back to home")}</a>
                                </p> */}
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        )
    
}
