import React, { Component } from 'react'
import FaqsContent from './FaqsContent';

export default class Faqs extends Component {


    render() {
        let id = this.props.match.params.id;

        return (
            <div>
                <FaqsContent />

            </div>
        )

    }
}
