import { getPublicVoteCookie, getCurrentLanguageId, getToken } from "../utils";
import { app } from "../services/third-party/firebase";
import {
  getStorage,
  ref,
  deleteObject,
  
} from "firebase/storage";

// export const ApiBase = "http://127.0.0.1:8000/";
export const ApiBase = "https://kurdishtalent.com/api/";
// export const ApiBase = "https://demo.kurdishtalent.com/api/";
export const SiteBase = "https://kurdishtalent.com";

export const TOKEN_KEY = "cjtokennow"

let headers = new Headers();
let fileheaders = new Headers();

headers.append("Content-Type", "application/json");
headers.append("Accept", "application/json");
fileheaders.append("Accept", "application/json");
const auth = localStorage.getItem(TOKEN_KEY) || null;
if (auth) headers.append("Authorization", "Bearer " + auth);

fileheaders.append("Authorization", "Bearer " + auth);

export async function loadTalents(language_id) {
  const response = await fetch(
    `${ApiBase}api/talent/?language_id=${language_id}`,
    {
      headers: new Headers(headers),
      method: "GET",
    }
  );

  const data = await response.json();

  return data;
}

export async function loadTranslations() {

  const response = await fetch(
    `${ApiBase}api/translate/?size=100000`,
    {
      headers: new Headers(headers),
      method: "GET",
    }
  );

  const data = await response.json();

  if (data.results) {
    return data.results;
  }

}

export async function loadMenuTalents() {
  let language_id = getCurrentLanguageId();

  const response = await fetch(
    `${ApiBase}api/talent/?menu=1&language_id=${language_id}`,
    {
      headers: new Headers(headers),
      method: "GET",
    }
  );

  const data = await response.json();

  return data;
}

export async function loadTalent(id) {
  const response = await fetch(`${ApiBase}api/talent/${id}`, {
    headers: new Headers(headers),
    method: "GET",
  });

  const data = await response.json();

  return data;
}

export async function loadTalentDocument(talent_id) {
  const response = await fetch(`${ApiBase}api/talent/${talent_id}/documents`, {
    headers: new Headers(headers),
    method: "GET",
  });

  const data = await response.json();

  if (data.results) {
    return data.results[0];
  }
}

export async function loadUserTalents() {
  const response = await fetch(`${ApiBase}api/usertalent/`, {
    headers: new Headers(headers),
    method: "GET",
  });

  const data = await response.json();

  if (data.results) {
    return data.results;
  } else return data;
}

export async function loadUserTalentDocuments(usertalent_id,talent_id) {

  let response;
  if(usertalent_id){
    response = await fetch(
      `${ApiBase}api/usertalent/${usertalent_id}/userdocuments/`,
      {
        headers: new Headers(headers),
        method: "GET",
      }
    );
  }
  else if(talent_id){
    response = await fetch(
      `${ApiBase}api/talent/${talent_id}/userdocuments/`,
      {
        headers: new Headers(headers),
        method: "GET",
      }
    );
  }
  

  const data = await response.json();

  if (data.results) {
    return data.results;
  }
}


export async function loadUserDocuments() {

  let response;
  response = await fetch(
    `${ApiBase}api/userdocuments/`,
    {
      headers: new Headers(headers),
      method: "GET",
    }
  );
  

  const data = await response.json();

  if (data.results) {
    return data.results;
  }
}

export async function loadProfile() {
  if (auth) {
    const response = await fetch(`${ApiBase}auth/profiles/`, {
      headers: new Headers(headers),
      method: "GET",
    });

    const data = await response.json();

    if (data.results) {
      return data.results[0];
    }
  }
}

export async function loadCourse(id) {
  const response = await fetch(`${ApiBase}api/course/${id}/`, {
    headers: new Headers(headers),
    method: "GET",
  });

  const data = await response.json();

  return data;
}
export async function loadCourses() {
  const response = await fetch(`${ApiBase}api/course/`, {
    headers: new Headers(headers),
    method: "GET",
  });

  const data = await response.json();

  if (data.results) return data.results;
}

export async function loadSupportMessages() {
  const response = await fetch(`${ApiBase}api/support/`, {
    headers: new Headers(headers),
    method: "GET",
  });

  const data = await response.json();

  if (data.results) return data.results;
}

export async function loadSupportMessage(id) {
  const response = await fetch(`${ApiBase}api/supportmessages/${id}`, {
    headers: new Headers(headers),
    method: "GET",
  });

  const data = await response.json();

  return data;
}

export async function loadMessageReplies(message_id, page=1) {
  var filter_query = "";
  if (page) filter_query = "?page=" + page;

  const response = await fetch(`${ApiBase}api/supportmessages/${message_id}/replies${filter_query}`, {
    headers: new Headers(headers),
    method: "GET",
  });

  const data = await response.json();

  return data;
}

export async function saveReply(supportmessage_id, message) {
  let body = {
    message: message,
  };

  const response = await fetch(`${ApiBase}api/supportmessages/${supportmessage_id}/replies`, {
    headers: new Headers(headers),
    method: 'POST',
    body: JSON.stringify(body),
  });

  const data = await response.json();

  return data;
}

export async function loadNewestCourse(talent_id,loadall=false) {
  const response = await fetch(`${ApiBase}api/talent/${talent_id}/courses?${loadall ? "status=all" : ""}`, {
    headers: new Headers(headers),
    method: "GET",
  });

  const data = await response.json();

  if (data.results) return data.results[0];
}

export async function checkDocumentAvailability(url) {
  const result = await fetch(url, { method: 'HEAD' });
  return result.ok;
}



export async function addParticipantToCourse(
  course_id,
  usertalent_id,
  userdocument_ids
) {
  let body = {
    course: course_id,
    usertalent: usertalent_id,
    userdocument_ids: userdocument_ids,
  };
  console.log(body);
  const response = await fetch(`${ApiBase}api/courseparticipant/`, {
    headers: new Headers(headers),
    method: "POST",
    body: JSON.stringify(body),
  });

  const data = await response.json();

  return data;
}

export async function loadCourseParticipants(course_id) {
  const response = await fetch(
    `${ApiBase}api/courseparticipant?course=${course_id}`,
    {
      headers: new Headers(headers),
      method: "GET",
    }
  );

  const data = await response.json();

  if (data.results) {
    return data.results;
  }
}
export async function loadCourseDocuments(course, rating) {
  var query_text = "?size=300";
  if (course) query_text += "&course=" + course;
  if (rating != null && rating >= 0) query_text += "&is_rated=" + rating;

  const response = await fetch(`${ApiBase}api/coursedocument/${query_text}`, {
    headers: new Headers(headers),
    method: "GET",
  });

  const data = await response.json();

  if (data.results) {
    return data;
  }
}

export async function loadPublicCourseDocuments(params) {
  var query_text = "?size=30";
  if (params.size) query_text = "?size=" + params.size;
  if (params.page) query_text += "&page=" + params.page;
  if (params.course) query_text += "&course=" + params.course;
  if (params.rating) query_text += "&rating=" + params.rating;
  if (params.talent) query_text += "&talent=" + params.talent;

  const response = await fetch(
    `${ApiBase}api/publiccoursedocuments/${query_text}`,
    {
      headers: new Headers(headers),
      method: "GET",
    }
  );

  const data = await response.json();

  if (data.results) {
    return data.results;
  }
}

export async function loadCourseDocument(course) {
  const response = await fetch(
    `${ApiBase}api/coursedocument/?course=${course}`,
    {
      headers: new Headers(headers),
      method: "GET",
    }
  );

  const data = await response.json();

  if (data.results) {
    return data.results[0];
  }
}
export async function AddCourseDocument(course_id, userdocument_id) {
  let body = {
    course: course_id,
  };
  const response = await fetch(
    `${ApiBase}api/userdocument/${userdocument_id}/coursedocuments/`,
    {
      headers: new Headers(headers),
      method: "POST",
      body: JSON.stringify(body),
    }
  );

  const data = await response.json();

  return data;
}

export async function loadQuestions(talent_id) {
  let language_id = getCurrentLanguageId();
  const response = await fetch(
    `${ApiBase}api/talent/${talent_id}/questions/?language_id=${language_id}`,
    {
      headers: new Headers(headers),
      method: "GET",
    }
  );

  const data = await response.json();

  if (data.results) {
    return data.results;
  }
}
export async function loadDegrees(course, participant, talent, referee) {

  const response = await fetch(
    `${ApiBase}rep/refereedegreesummary?course=${course}&participant=${participant}&talent=${talent}&referee=${referee}`,
    {
      headers: new Headers(headers),
      method: "GET",
    }
  );

  const data = await response.json();

  if (!data.error) return data;
}
export async function loadPublicDegree(course, usertalent) {
  const auth = getToken();
  if (auth) {
    headers.delete("Authorization");
    headers.append("Authorization", "Bearer " + auth);
  }

  var cookie = getPublicVoteCookie()

  const response = await fetch(
    `${ApiBase}api/peopledegree?course=${course}&usertalent=${usertalent}&c=${cookie}`,
    {
      headers: new Headers(headers),
      method: "GET",
    }
  );

  const data = await response.json();

  if (data.results) return data.results[0];
}
export async function loadSettings() {
  const response = await fetch(`${ApiBase}api/settings/`, {
    headers: new Headers(headers),
    method: "GET",
  });

  const data = await response.json();

  if (data.results) return data.results[0];
}

export async function loadTestimonials(language) {
  const response = await fetch(
    `${ApiBase}api/testimonials/?language=${language}`,
    {
      headers: new Headers(headers),
      method: "GET",
    }
  );

  const data = await response.json();

  if (data.results) return data.results;
}
export async function loadPosts(talent, size = 10, page = 1,featured=null) {
  let language_id = getCurrentLanguageId();

  var query_text = "?size=" + size;
  if (page) query_text += "&page=" + page;
  if (talent) query_text += "&talent=" + talent;
  if (language_id) query_text += "&language=" + language_id;
  if (featured!=null) query_text += "&featured=" + featured;

  const response = await fetch(`${ApiBase}postapi/post/${query_text}`, {
    headers: new Headers(headers),
    method: "GET",
  });

  const data = await response.json();


  if (data.results) return data.results;
}

export async function loadFaqs(size = 50, page = 1) {
  let language_id = getCurrentLanguageId();


  var query_text = "?size=" + size;
  if (page) query_text += "&page=" + page;
  if (language_id) query_text += "&language=" + language_id;

  const response = await fetch(`${ApiBase}api/faq${query_text}`, {
    headers: new Headers(headers),
    method: "GET",
  });

  const data = await response.json();

  if (data.results) return data.results;
}

export async function loadPostsCount(talent) {
  let query_text = "?size=100000";
  if (talent) query_text += "&talent=" + talent;

  const response = await fetch(`${ApiBase}postapi/post/${query_text}`, {
    headers: new Headers(headers),
    method: "GET",
  });

  const data = await response.json();

  if (data.count) return data.count;
}
export async function loadPost(id) {
  const response = await fetch(`${ApiBase}postapi/post/${id}/`, {
    headers: new Headers(headers),
    method: "GET",
  });

  const data = await response.json();

  return data;
}

export async function loadCountries(page, size = 10, language_id) {
  var query_text = "?size=" + size;
  if (page) query_text += "&page=" + page;
  //if (language_id)
  //    query_text += "&language_id=" + language_id;

  const response = await fetch(`${ApiBase}base/country${query_text}`, {
    headers: new Headers(headers),
    method: "GET",
  });

  const data = await response.json();

  if (data.results) {
    var coutries = data.results;
    return data.results;
  }
}
export async function loadCountry(country_id) {
  const response = await fetch(`${ApiBase}base/country/${country_id}/`, {
    headers: new Headers(headers),
    method: "GET",
  });

  const data = await response.json();

  return data;
}
export async function loadCities(country_id) {
  const response = await fetch(
    `${ApiBase}base/country/${country_id}/cities?size=1000000`,
    {
      headers: new Headers(headers),
      method: "GET",
    }
  );

  const data = await response.json();

  return data;
}
export async function loadCity(id) {
  const response = await fetch(`${ApiBase}base/city/${id}/`, {
    headers: new Headers(headers),
    method: "GET",
  });

  const data = await response.json();

  return data;
}
export async function saveUserTalent(talent_id) {
  let body = {
    talent: talent_id,
  };
  const response = await fetch(`${ApiBase}api/usertalent/`, {
    headers: new Headers(headers),
    method: "POST",
    body: JSON.stringify(body),
  });

  const data = await response.json();

  return data;
}
export async function saveNewsletter(email) {
  let body = {
    email: email,
  };
  const response = await fetch(`${ApiBase}api/newsletter/`, {
    headers: new Headers(headers),
    method: "POST",
    body: JSON.stringify(body),
  });

  const data = await response.json();

  return data;
}
export async function saveSupportMessage(title,message) {
  let body = {
    title: title,
    message: message,
  };
  const response = await fetch(`${ApiBase}api/support/`, {
    headers: new Headers(headers),
    method: "POST",
    body: JSON.stringify(body),
  });

  const data = await response.json();

  return data;
}

export async function saveQuestionDegree(
  degree_id,
  course,
  question,
  degree,
  participant,
  talent,
  referee
) {
  if (degree_id)
    updateDegree(
      degree_id,
      course,
      question,
      degree,
      participant,
      talent,
      referee
    );
  else insertDegree(course, question, degree, participant, talent);
}

export async function savePublicDegree(degree_id, degree, course, usertalent,cookie) {
  const auth = getToken();
  if (auth) {
    headers.delete("Authorization");
    headers.append("Authorization", "Bearer " + auth);
  }

  if (degree_id) updatePublicDegree(degree_id, degree, course, usertalent);
  else insertPublicDegree(degree, course, usertalent,cookie);
}

async function insertPublicDegree(degree, course, usertalent,cookie) {
  let body = {
    course: course,
    degree: degree ? degree : 5,
    usertalent: usertalent,
    cookie: cookie,
  };
  const response = await fetch(`${ApiBase}api/peopledegree/`, {
    headers: new Headers(headers),
    method: "POST",
    body: JSON.stringify(body),
  });

  const data = await response.json();

  return data;
}

async function updatePublicDegree(degree_id, degree) {
  if (!degree) return;

  let body = {
    degree: degree,
  };

  const response = await fetch(`${ApiBase}api/peopledegree/${degree_id}`, {
    headers: new Headers(headers),
    method: "PATCH",
    body: JSON.stringify(body),
  });

  const data = await response.json();

  return data;
}

async function updateDegree(
  degree_id,
  course,
  question,
  degree,
  participant,
  talent,
  referee
) {
  //console.log(degree, degree_id)

  if (!degree) return;

  let body = {
    degree: degree,
  };

  const response = await fetch(`${ApiBase}api/degree/${degree_id}`, {
    headers: new Headers(headers),
    method: "PATCH",
    body: JSON.stringify(body),
  });

  const data = await response.json();

  return data;
}

async function insertDegree(course, question, degree, participant, talent) {
  let body = {
    course: course,
    degree: degree ? degree : 3,
    question: question,
    participant: participant,
    talent: talent,
  };

  const response = await fetch(`${ApiBase}api/degree/`, {
    headers: new Headers(headers),
    method: "POST",
    body: JSON.stringify(body),
  });

  const data = await response.json();

  return data;
}

export async function resendVerificationEmail(email) {
  let body = {
    email: email,
    redirect_url: `${SiteBase}/validate/`,
  };
  const response = await fetch(`${ApiBase}auth/resendverificationemail/`, {
    headers: new Headers(headers),
    method: "POST",
    body: JSON.stringify(body),
  });

  const data = await response.json();

  return data;
}
export async function requestReset(email) {
  let body = {
    email: email,
    redirect_url: `${SiteBase}/reset/`,
  };
  const response = await fetch(`${ApiBase}auth/request-reset-email/`, {
    headers: new Headers(headers),
    method: "POST",
    body: JSON.stringify(body),
  });

  const data = await response.json();

  return data;
}
export async function completeReset(token, uidb64, password) {
  let body = {
    token: token,
    uidb64: uidb64,
    password: password,
  };
  const response = await fetch(`${ApiBase}auth/password-reset-complete`, {
    headers: new Headers(headers),
    method: "PATCH",
    body: JSON.stringify(body),
  });

  const data = await response.json();

  return data;
}
export async function checkResetToken(token, uidb64) {
  const response = await fetch(
    `${ApiBase}auth/password-reset/${uidb64}/${token}/`,
    {
      headers: new Headers(headers),
      method: "GET",
    }
  );

  const data = await response.json();

  return data;
}

export async function uploadProfileImage(profile_id, file) {
  const formData = new FormData();

  formData.append("image", file, file.name);
  const response = await fetch(`${ApiBase}auth/profiles/${profile_id}/`, {
    headers: new Headers(fileheaders),
    method: "PATCH",
    body: formData,
  });

  const data = await response.json();

  return data;
}

export async function uploadUsertalentDocument(
  usertalent_id,
  document_id,
  file,
  url,
  id,
  talent_id
) {
  let formData = new FormData();
  formData.append("document", document_id);
  formData.append("url", url);

  console.log(document_id)


  if (file) formData.append("file", file, file.name);
  var response;

  if(id){
    response = await fetch(
      `${ApiBase}api/userdocument/${id}`,
      {
        headers: new Headers(fileheaders),
        method: "PUT",
        body: formData,
      }
    ).catch((error) => {
      console.log(error);
    });
  }
  else {
    if(usertalent_id){
      response = await fetch(
        `${ApiBase}api/usertalent/${usertalent_id}/userdocuments/`,
        {
          headers: new Headers(fileheaders),
          method: "POST",
          body: formData,
        }
      ).catch((error) => {
        console.log(error);
      });
    }
    else{ // Upload document when there is no usertalent_id
      response = await fetch(
        `${ApiBase}api/talent/${talent_id}/userdocuments/`,
        {
          headers: new Headers(fileheaders),
          method: "POST",
          body: formData,
        }
      ).catch((error) => {
        console.log(error);
      });
    }
    
  }
  

  const data = await response.json();

  return data;
}
export async function loadUserTalentCertificates(userttalent_id) {
  const response = await fetch(
    `${ApiBase}api/usertalent/${userttalent_id}/certs/`,
    {
      headers: new Headers(headers),
      method: "GET",
    }
  );

  const data = await response.json();

  return data.results;
}
export async function loadCertificates() {
  const response = await fetch(
    `${ApiBase}api/certificates`,
    {
      headers: new Headers(headers),
      method: "GET",
    }
  );

  const data = await response.json();

  return data.results;
}
export async function uploadCertificate(title, file) {
  let formData = new FormData();
  formData.append("title", title);
  formData.append("file", file, file.name);
  const response = await fetch(
    `${ApiBase}api/certificates`,
    {
      headers: new Headers(fileheaders),
      method: "POST",
      body: formData,
    }
  ).catch((error) => {
    console.log(error);
  });

  const data = await response.json();

  //console.log(response.body);

  return data;
}




export async function updateUserDocument(userdocument_id, document_id, file) {
  let formData = new FormData();
  formData.append("document", document_id);
  formData.append("file", file, file.name);
  const response = await fetch(
    `${ApiBase}api/userdocument/${userdocument_id}`,
    {
      headers: new Headers(fileheaders),
      method: "PUT",
      body: formData,
    }
  );

  const data = await response.json();

  return data;
}

export async function deleteUserTalent(usertalent_id) {
  const response = await fetch(`${ApiBase}api/usertalent/${usertalent_id}`, {
    headers: new Headers(headers),
    method: "DELETE",
  });
}

export async function deleteCourseParticipant(courseparticipant_id) {
  const response = await fetch(
    `${ApiBase}api/courseparticipant/${courseparticipant_id}`,
    {
      headers: new Headers(headers),
      method: "DELETE",
    }
  );
}
export async function loadCourseReferees() {
  const response = await fetch(`${ApiBase}api/coursereferee/`, {
    headers: new Headers(headers),
    method: "GET",
  });

  const data = await response.json();

  if (data.results) {
    return data.results;
  }
}

export async function deleteUserTalentCertificate(certificate_id) {
  const response = await fetch(`${ApiBase}api/cert/${certificate_id}`, {
    headers: new Headers(headers),
    method: "DELETE",
  });
}

const getfilename = (url)=>{
  return url ;
}


export async function deleteUserTalentDocument(userdocument) {

   if(userdocument.url?.includes("firebasestorage")){
     const storage = getStorage(app);
     var desertRef = ref(storage, userdocument.url); 
  
  //console.log(userdocument.url);

    deleteObject(desertRef).then(function() {
      // File deleted successfully
      console.log("File deleted successfully")
    }).catch(function(error) {
      // Uh-oh, an error occurred!
    });
   }

  const response = await fetch(
    `${ApiBase}api/userdocument/${userdocument.id}`,
    {
      headers: new Headers(headers),
      method: "DELETE",
    }
  );
}

export async function saveProfile(user) {
  let body = {
    first_name: user.first_name,
    last_name: user.last_name,
    gender: user.gender,
    phone_number: user.phone_number ? user.phone_number : "",
    birth_date: user.birth_date,
    country_id: user.country_id,
    city_id: user.city_id,
    livingcountry_id: user.livingcountry_id,
    livingcity_id: user.livingcity_id,
    //username: user.username,
    // //password: user.password,
    website: user.website ? user.website : "",
    facebook: user.facebook ? user.facebook : "",
    instagram: user.instagram ? user.instagram : "",
    youtube: user.youtube ? user.youtube : "",
    snapchat: user.snapchat ? user.snapchat : "",
    spotify: user.spotify ? user.spotify : "",
    comments: user.comments ? user.comments : "",
  };

  const response = await fetch(`${ApiBase}auth/profiles/${user.id}/`, {
    headers: new Headers(headers),
    method: "PATCH",
    body: JSON.stringify(body),
  });

  const data = await response.json();

  return data;
}

export async function changePassword(user) {
  let body = {
    password: user.password,
    first_name: user.first_name,
    last_name: user.last_name,
  };

  const response = await fetch(`${ApiBase}auth/profiles/${user.id}/`, {
    headers: new Headers(headers),
    method: "PUT",
    body: JSON.stringify(body),
  });

  const data = await response.json();

  return data;
}

export async function doLogin(user) {
  let body = {
    email: user.email,
    password: user.password,
  };

  const response = await fetch(`${ApiBase}auth/login/`, {
    headers: new Headers(headers),
    method: "POST",
    body: JSON.stringify(body),
  });

  const data = await response.json();

  return data;
}

export async function loadPages(url) {
  let language_id = getCurrentLanguageId();

  var query_text = "?url=" + url;
  if (language_id) query_text += "&language=" + language_id;

  const response = await fetch(`${ApiBase}postapi/page${query_text}`, {
    headers: new Headers(headers),
    method: "GET",
  });

  const data = await response.json();

  if (data.results) return data.results;
}


export async function loadVideoGalleries() {

  let language_id = getCurrentLanguageId();

  const response = await fetch(
    `${ApiBase}postapi/videogallery/?language=${language_id}`,
    {
      headers: new Headers(headers),
      method: "GET",
    }
  );

  const data = await response.json();

  if(data.results)
    return data.results;
}

export async function loadPopupMessages() {
  let language_id = getCurrentLanguageId();
  const response = await fetch(`${ApiBase}postapi/popupmessage/?language=${language_id}`, {
    headers: new Headers(headers),
    method: "GET",
  });

  const data = await response.json();

  if (data.results) return data.results;
}