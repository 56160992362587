import { useStateValue } from 'StateProvider';
import VideoCarousel from 'pages/shared/Home/components/videogallery/VideoCarousel'
import React, { useState } from 'react'
import { getDefaultValue, getUser } from 'utils';
import HomeCarousel from './HomeCarousel';
import parse from 'html-react-parser'
import LoginModal from 'components/user/LoginModal';

function SlideShowContent() {

    const [{ translations }] = useStateValue();
    const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);

    const user = getUser();


    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x => x.key == str);

        if (translate) return getDefaultValue(translate);
        else return "#" + str;
    };





    return (
        <>
            <div className="container-fluid ">
                <div className="row custom-header-min-height-1 align-items-center h-100 lan-rtl">
                    <div className="col-lg-5">

                        <div className="show-on-small  d-flex flex-column align-items-center justify-content-center h-100">
                            <h1
                                className="position-relative text-color-light text-5 line-height-5 font-weight-medium px-4 mb-2 appear-animation"
                                data-appear-animation="fadeInDownShorterPlus"
                                data-plugin-options="{'minWindowWidth': 0}"
                            >
                                <span className="position-absolute right-100pct top-50pct transform3dy-n50 opacity-3">
                                    <img
                                        src="assets/img/slides/slide-title-border-light.png"
                                        className="w-auto appear-animation"
                                        data-appear-animation="fadeInRightShorter"
                                        data-appear-animation-delay="250"
                                        data-plugin-options="{'minWindowWidth': 0}"
                                        alt=""
                                    />
                                </span>
                                {translate("Welcome To")}
                                <span className="position-relative">
                                    <span className="position-absolute left-50pct transform3dx-n50 top-0 mt-3">
                                        <img
                                            src="img/slides/slide-blue-line.png"
                                            className="w-auto appear-animation"
                                            data-appear-animation="fadeInLeftShorterPlus"
                                            data-appear-animation-delay="1000"
                                            data-plugin-options="{'minWindowWidth': 0}"
                                            alt=""
                                        />
                                    </span>
                                </span>
                                <span className="position-absolute left-100pct top-50pct transform3dy-n50 opacity-3">
                                    <img
                                        src="assets/img/slides/slide-title-border-light.png"
                                        className="w-auto appear-animation"
                                        data-appear-animation="fadeInLeftShorter"
                                        data-appear-animation-delay="250"
                                        data-plugin-options="{'minWindowWidth': 0}"
                                        alt=""
                                    />
                                </span>
                            </h1>
                            <h1 className='slide-kt-title' data-appear-animation="fadeInDownShorter"
                                data-appear-animation-delay="1000">{translate("Kurdish Talent")}</h1>
                            <p
                                className="text-thrive font-weight-light  mb-0 appear-animation"
                                data-appear-animation="fadeInDownShorter"
                                data-appear-animation-delay="1200"
                            >
                                {translate("A platform for talents to thrive")}
                            </p>
                            {/* <p
                        className="text-4 text-color-light font-weight-light opacity-7 mb-0"
                        data-plugin-animated-letters
                        data-plugin-options="{'startDelay': 2000, 'minWindowWidth': 0}"
                        >
                        {current_language && current_language === "so" && (
                            <>پێگەیەک بۆ ناساندن و بەرزنرخاندنی بەهرەکانی تۆ</>
                        )}
                        {current_language && current_language === "ku" && (
                            <>Platformek ji bo nasandin û nirxandina zîrektîyên te</>
                        )}
                        {(!current_language || current_language === "en") && (
                            <>A platform for talents to thrive</>
                        )}
                        </p> */}
                            <div className="flex-center full-width">
                                <hr />
                            </div>
                            <div className='full-width mt-1 mb-5'>
                                <HomeCarousel />
                            </div>
                            <p className="text-statistics text-center mb-1 lan-rtl text-light-1  appear-animation stats-text"
                                data-appear-animation="fadeInLeftShorter"
                                data-appear-animation-delay="250">{parse(translate("kurdish talent 2022 statistics").replace('-', '<br />'))}</p>

                            {!user && (
                                <div className="d-block mt-3 lan-text-right sm-text-center appear-animation animated fadeInDownShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="2000" >
                                    <a onClick={(e) => {
                                        e.preventDefault();
                                        setLoginModalIsOpen(true)
                                    }}
                                        class="kfont btn btn-primary btn-outline btn-outline-thin btn-outline-light-opacity-2 font-weight-semi-bold px-4 btn-py-2 text-3 text-color-light text-color-hover-dark ">
                                        {translate("Sign Up")} <i className="fas fa-arrow-right rotate-l-45 ms-2"></i></a>
                                </div>
                            )}
                        </div>

                        <div className=" hide-on-small col-half-section home-slide-content col-half-section-right custom-header-max-width-1 ms-auto justify-self-end">

                            <h2 className="text-light-2 text-color-gray2 mb-1 kfont lan-rtl lan-text-right mb-2 appear-animation"
                                data-appear-animation="fadeInDownShorter"
                                data-appear-animation-delay="1000">
                                {translate("Kurdish Talent")}
                            </h2>
                            <h1 className="font-weight-bold text-11  line-height-2 mb-3 "
                                data-appear-animation="fadeInDownShorter"
                                data-appear-animation-delay="1200">{translate("A platform for talents to thrive")}</h1>

                            <p className=" pt-2 lan-rtl lan-text-right text-light-2 text-5 text-light fon-weight-100  "
                                data-appear-animation="fadeInDownShorter"
                                data-appear-animation-delay="1600">{parse(translate("kurdish talent 2022 statistics").replace('-', '<br />'))}</p>

                            {/* <div className="d-flex counters pt-3">
                                <div className="custom-counter-1 pe-4 lan-text-right lan-rtl ">
                                    <div>
                                        <h3 data-append=" +" data-to="1200" className="mb-0 lan-text-right ">
                                            0
                                        </h3>
                                        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 117 12" width="117" height="12">
                                            <g>
                                                <path id="Path" className="s0" d="m51.3 0.8c24.9 0.4 67.8 2.3 67.8 2.3 0 0-33.6 0.1-67.5 1.7-37.5 1.7-46.9 6.9-46.9 6.9 0 0-6.4-4-4.3-7 1.7-2.2 26.5-4.3 50.9-3.9z" />
                                            </g>
                                        </svg>
                                        <p className='kfont mt-1 lan-text-right'>
                                            {translate("Participants")}
                                        </p>
                                    </div>
                                </div>
                                <div className="custom-counter-1 ps-3 lan-rtl lan-text-right">
                                    <div>
                                        <h3 data-append=" +" data-to="69" className="mb-0 lan-text-right ">
                                            0
                                        </h3>
                                        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 117 12" width="117" height="12">
                                            <g>
                                                <path id="Path" className="s0" d="m51.3 0.8c24.9 0.4 67.8 2.3 67.8 2.3 0 0-33.6 0.1-67.5 1.7-37.5 1.7-46.9 6.9-46.9 6.9 0 0-6.4-4-4.3-7 1.7-2.2 26.5-4.3 50.9-3.9z" />
                                            </g>
                                        </svg>
                                        <p className='kfont mt-1 lan-text-right'>
                                            {translate("Cities")}
                                        </p>
                                    </div>
                                </div>
                            </div> */}

                            {!user && (
                                <div className="d-block mt-5 lan-text-right sm-text-center appear-animation animated fadeInDownShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="2000" >
                                    <a onClick={(e) => {
                                        e.preventDefault();
                                        setLoginModalIsOpen(true)
                                    }}
                                        class="kfont btn btn-primary btn-outline btn-outline-thin btn-outline-light-opacity-2 font-weight-semi-bold px-4 btn-py-2 text-3 text-color-light text-color-hover-dark ">
                                        {translate("Sign Up")} <i className="fas fa-arrow-right rotate-l-45 ms-2"></i></a>
                                </div>
                            )}
                        </div>

                    </div>
                    <div className="col-lg-7 hide-on-small">
                        <HomeCarousel />
                    </div>

                </div>
            </div>
            <LoginModal key={loginModalIsOpen} setOpen={setLoginModalIsOpen} setLoggedIn={() => { }} isOpen={loginModalIsOpen} />

        </>
    )
}

export default SlideShowContent