import React, { useEffect, useState } from 'react'
import { getDefaultName } from '../../utils'
import { loadTalent } from '../../utils/Network'

export default function TalentName({id}) {

    const [talent , setTalent] = useState(null)
    useEffect(() => {
        if(id){
            loadTalent(id).then(talent =>{
                setTalent(talent)
            })
        }
    }, [])

    return (
        <>
            {talent && getDefaultName(talent.names)}
        </>
    )
}
