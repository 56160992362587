import React, { Component } from 'react'
import  './Signin.css';
import PageHeader from '../components/shared/PageHeader';
import ResetPanel from '../components/shared/ResetPanel';


export default class Reset extends Component {
 

    render() {
        
       
        return (
            <div>
                <PageHeader title="Reset Password" />
                <ResetPanel />
            </div>
        )
    }
}
