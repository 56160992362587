import React, { useState } from 'react'
import { useStateValue } from '../../StateProvider';
import { getDefaultValue } from '../../utils';
import AddUserTalentModal from '../dialogs/AddUserTalentModal'
import ModalCompleteProfile from '../dialogs/ModalCompleteProfile';

function WidgetUpdateProfile() {

    const [complete_profile_open, setCompleteProfileOpen] = useState(false);
 
    const [{ translations }] = useStateValue();
    const _t = (str) => {
        if (!translations) return str;
        var translate = translations.find((x) => x.key == str);

        if (translate) return getDefaultValue(translate);
        else return "#" + str;
    };


    return (
        <>
        {/* data-toggle="modal" data-target="#addUserTalentModal" */}
            <div className="kt-widget kfont" onClick={()=>{ setCompleteProfileOpen(true) }} > 
                <i className="fa fa-user"></i>
                {_t("Update profile")}
            </div>
            {/* <AddUserTalentModal /> */}
            <ModalCompleteProfile isOpen={complete_profile_open} message={'You can update your profile here'} setIsOpen={(value) => { setCompleteProfileOpen(value) }} />
        </>

    )
}

export default WidgetUpdateProfile