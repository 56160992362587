import { HttpService } from "utils/http";

export class ExploreHttpService {

    static getExplorePosts = async ({pageParam =1})  =>  {
        var service = new HttpService();
        var response = await service.get(`api/gallery/explore/?page=${pageParam}`);

        return {response , nextPage: pageParam + 1, totalPages: 3 };

    };

}