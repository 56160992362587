import React, { useState } from "react";
import Modal from "react-modal";
import { useStateValue } from "../../StateProvider";
import { getDefaultValue } from "../../utils";
import parse from 'html-react-parser'
import ProfileForm from "../forms/user/ProfileForm";

export default function ModalCompleteProfile({ isOpen = false, message = "", setIsOpen ,onSuccess}) {

  const closeModal = () => {
    setIsOpen(false);
  };


  const [{ translations }] = useStateValue();
  const translate = (str) => {
    if (!translations) return str;
    var translate = translations.find((x) => x.key == str);

    if (translate) return getDefaultValue(translate);
    else return "#" + str;
  };

  return (
    <Modal
      closeTimeoutMS={500}
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
    >
      <div className="modal-content ">

        <div className="modal-body lan-text-right bg-dark bg-upload-material">
              {message && <div className="mb-4">
                {parse(translate(message))}
              </div>}
              <ProfileForm onCancel={()=>{ setIsOpen(false) }} onSuccess={()=>{ setIsOpen(false);
                if(onSuccess){
                  onSuccess()
                }
              }} />
        </div>

      </div>
    </Modal>
  );
}
