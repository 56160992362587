import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { PostHttpService } from 'utils/http/post_http_service';

export default function LatestPostsController() {

  const [posts, setPosts] = useState(null);
  const [loading, setLoading] = useState(false);


    const { isLoading, data, refetch } = useQuery('latest_posts', () =>
    
        PostHttpService.loadPosts(null, 6,1,1),
        {
            onSuccess: (data) => {
                if (data.results) {
                    setPosts(data.results)

                }
            },
        }
    );
 


  return {
    isLoading,
    posts,
  };
}
