import React, { useState } from "react";
import Modal from "react-modal";
import { useStateValue } from "../../StateProvider";
import { getDefaultValue, toTime } from "../../utils";
import UrlEmbed from "../UrlEmbed";

export default function ModalDocumentPreview({ isOpen = false, setIsOpen, document }) {

  const closeModal = () => {
    setIsOpen(false);
  };


  const [{ translations }] = useStateValue();
  const translate = (str) => {
    if (!translations) return str;
    var translate = translations.find((x) => x.key == str);

    if (translate) return getDefaultValue(translate);
    else return "#" + str;
  };

  return (
    <Modal
      closeTimeoutMS={500}
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
    >
      {document && document.url && document.url != "undefined" &&
        <div className="modal-content bg-upload-material">
          <div className="modal-header no-border ">
            <button type="button" onClick={closeModal} className="close lan-float-left lan-ml-0" data-dismiss="modal" aria-hidden="true">&times;</button>
          </div>
          <div className="modal-body ">
            <p className="text-color-primary">
              <UrlEmbed url={document.url} />
            </p>

          </div>

        </div>

      }

      {document && (!document.url || document.url == "undefined") &&
        <>
          <button type="button" className="close modal-body-close" onClick={closeModal} data-dismiss="modal" aria-hidden="true">&times;</button>

          {document && document.isImage &&
            <img src={document.path} className="img-fluid border-radius-0 " alt="" />
          }
          {document && document.isDocument &&
            <UrlEmbed url={document.path} isDocument={document.isDocument} />
          }
          {document && !document.isImage && !document.isDocument &&
            <video key={document.path} controls className="video-fit-contain" >
              <source src={document.path} type="video/mp4" />
            </video>
          }
          {!document &&
            <img src="assets/images/file_upload.png" className="upload-icon img-fluid border-radius-0  height-122" alt="" />
          }
          
        </>
      }
    </Modal>
  );
}
