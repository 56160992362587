import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useStateValue } from '../../StateProvider';
import { getCourseNames, getDate, getDefaultName, getDefaultValue, getYoutubeId, isDocument, isImage, isYoutube } from '../../utils';
import { loadNewestCourse } from '../../utils/Network';
import ModalContestParticipation from '../dialogs/ModalContestParticipation';
import ModalDocumentPreview from '../dialogs/ModalDocumentPreview';
import ModalUserDocumentUpload from '../dialogs/ModalUserDocumentUpload';


function UserDocumentWidgetItem({ userDocument }) {
    const [documentPreviewModalFile, setDocumentPreviewModalFile] = useState(null);
    const [documentPreviewModalIsOpen, setDocumentPreviewModalIsOpen] = useState(false);
    const [contestParticipationModalIsOpen, setContestParticipationModalIsOpen] = useState(0);
    const [userDocumentUploadModalIsOpen, setUserDocumentUploadModalIsOpen] = useState(0);
    const [newest_course, setNewestCourse] = useState(null);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setLoading(true);

        loadNewestCourse(userDocument.talent.id).then((course) => {
            setNewestCourse(course);
        });

        setLoading(false);
    }, []);


    const [{ translations }] = useStateValue();
    const _t = (str) => {
        if (!translations) return str;
        var translate = translations.find(x => x.key === str);

        if (translate) return getDefaultValue(translate);
        else return "#" + str;
    };


    const handleClick = (file_path, url) => {
        openDocumentPreviewModal(file_path, url);
    };


    const openDocumentPreviewModal = (file_path, url) => {
        var file = {
            isImage: isImage(file_path),
            isDocument: isDocument(file_path),
            path: file_path,
            url: url
        }
        setDocumentPreviewModalFile(file)
        setDocumentPreviewModalIsOpen(true)
    }

    return (
        <div className='user-document-widget-item ' >
            <div className="row lan-rtl">
                <div className="col-md-12 info lan-text-right kfont lan-rtl">
                    {_t('You added this video for talent')} &nbsp;
                    <Link to="#talent">{getDefaultName(userDocument.talent.names)}</Link>  &nbsp;
                    {getDate(userDocument.created_at)}
                </div>
                <div className="col-md-4">
                    <div className="portfolio-item-preview" >
                        <span
                            onClick={() => {
                                // handleClick(
                                //     userDocument && userDocument.file,
                                //     userDocument && userDocument.url
                                // );
                                setUserDocumentUploadModalIsOpen(userDocument.talent.id)
                            }}
                            title={
                                userDocument && userDocument.courses !== "[]"
                                    ? getCourseNames(userDocument && userDocument.courses)
                                    : "View"
                            }
                            className={`thumb-info-wrapper border-radius-0 document-video-preview-simple`}
                        >
                            {userDocument && isImage(userDocument.file) && (
                                <img
                                    src={
                                        userDocument
                                            ? userDocument.file
                                            : "/assets/images/artist.jpg"
                                    }
                                    className=""
                                    alt=""
                                />
                            )}
                            {userDocument && isDocument(userDocument.file) && (
                                <img
                                    src="/assets/images/pdf_icon.png"
                                    style={{ width: 100 }}
                                    className=""
                                    alt=""
                                />
                            )}

                            {userDocument && isYoutube(userDocument.url) && (
                                <img
                                    src={`https://img.youtube.com/vi/${getYoutubeId(
                                        userDocument.url
                                    )}/0.jpg`}
                                    className="img-fluid"
                                    alt=""
                                />
                            )}

                            {userDocument &&
                                userDocument.url != null &&
                                userDocument.url.includes("firebasestorage") && (
                                    <>
                                        <video
                                            preload="metadata"
                                        >
                                            <source src={userDocument.url} type="video/mp4" />
                                        </video>

                                    </>
                                )}

                            {!userDocument && (
                                <img
                                    src="assets/images/file_upload.png"
                                    className="upload-icon border-radius-0  height-122"
                                    alt=""
                                />
                            )}

                            {userDocument && (
                                <span className="infoicon">
                                    {isYoutube(userDocument.url) ? (
                                        <i className="fab fa-youtube"></i>
                                    ) : "mp4".split(",").includes("mp4") ? (
                                        <i className="fa fa-play"></i>
                                    ) : (
                                        <i className="fa fa-search-plus"></i>
                                    )}
                                </span>
                            )}


                        </span>
                    </div>
                </div>
                <div className="col-md-8 padding-r-0   kfont lan-rtl ">
                    <div className="user-document-caption kfont lan-rtl lan-text-right">
                        {(!loading && newest_course) && (
                            <Link to="#" onClick={() => { setContestParticipationModalIsOpen(userDocument.talent.id) }} className="user-document-caption-item">
                                <i className="fas fa-music"></i>
                                {userDocument.course && <span>{getDefaultName(userDocument.course.names)}</span>}
                                {!userDocument.course && <span>
                                    {/* <button type="button" className="btn btn-light kfont"></button> */}
                                    {_t("Add to contest")}
                                </span>}
                            </Link>
                        )}
                        
                        {(!loading && !newest_course) && (
                            <Link to="#" onClick={() => { setContestParticipationModalIsOpen(userDocument.talent.id) }} className="user-document-caption-item">
                                <i className="fas fa-music"></i>
                                {userDocument.course && <span>{getDefaultName(userDocument.course.names)}</span>}
                                {!userDocument.course && <span>{_t("there is no contest available at the moment")}</span>}
                            </Link>
                        )}


                        <Link to="#" onClick={() => { setUserDocumentUploadModalIsOpen(userDocument.talent.id) }} className="user-document-caption-item">
                            <i className="fa fa-video"></i>
                            <span>{_t("Change your video")}</span>
                        </Link>
                        {/* <Link className="user-document-caption-item">
                            <i className="fa fa-trophy"></i>
                            <span>{_t("Add new Talent")}</span>
                        </Link> */}
                    </div>
                </div>
            </div>
            <ModalDocumentPreview document={documentPreviewModalFile} setIsOpen={setDocumentPreviewModalIsOpen} isOpen={documentPreviewModalIsOpen} />
            <ModalUserDocumentUpload talent={userDocumentUploadModalIsOpen} setIsOpen={setUserDocumentUploadModalIsOpen} isOpen={userDocumentUploadModalIsOpen} />

            <ModalContestParticipation key={"cpm" + contestParticipationModalIsOpen} setIsOpen={setContestParticipationModalIsOpen} isOpen={contestParticipationModalIsOpen} talent={contestParticipationModalIsOpen} />
        </div>
    )
}

export default UserDocumentWidgetItem


