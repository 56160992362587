import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { PostHttpService } from 'utils/http/post_http_service';

export default function PostVideoGalleryController(id) {

  const [videoGalleries, setVideoGalleries] = useState(null);
 
  const { isLoading, data, refetch } = useQuery(['postGalleries', id], () =>
    PostHttpService.loadPostVideoGalleries(id),
    {
      onSuccess: (data) => {
        if (data.results) {
          setVideoGalleries(data.results)
        }
      },
    }
  );



  var settings = {
    dots: false,
    autoplay:true,
    speed: 1000,
    slidesToShow: videoGalleries?.length<5 ? videoGalleries.length : 5,
    slidesToScroll: 5,
    arrows:true,
    centerMode:true,
    responsive: [
        {
          breakpoint: 1200,
          settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
          }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: false                }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false
            }
        }
    ]
  };

  return {
    isLoading,
    videoGalleries,
    settings,
  };;
}
