import { useStateValue } from 'StateProvider';
import React from 'react'
import { translate } from 'utils';

function FeedHeader({name = 'Feed'}) {
    const [{ translations }] = useStateValue();

  return (
    <section className="page-header pb-0 section-with-shape-divider page-header-modern bg-color-dark-scale-1 page-header-lg  pb-4  page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-7  ">
        <div className="container">
          <div className="row">
            <div className="col-md-12 align-self-center order-1">
              <ul className="breadcrumb d-block text-center lan-rtl">
                <li><a href="/" className="kfont">{translate(translations,'Home')}</a></li>
                <li className="active kfont">{translate(translations,name)}</li>

              </ul>
            </div>

            <div className="col order-3 appear-animation animated fadeInUp appear-animation-visible" data-appear-animation="fadeInUp" data-appear-animation-delay="0" data-appear-animation-duration="1s" >
              <hr className=" slide-line blackhr" />
            </div>
          </div>
        </div>

      </section>
  )
}

export default FeedHeader