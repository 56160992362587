import React, { useEffect, useState } from 'react'
import { useStateValue } from '../../StateProvider';
import { getDefaultValue } from '../../utils';
import { loadUserDocuments } from '../../utils/Network';
import UserDocumentPreview from '../talent/UserDocumentPreview';
import UserDocumentSimplePreview from '../talent/UserDocumentSimplePreview';
import UserDocumentWidgetItem from '../talent/UserDocumentWidgetItem';

function WidgetMyVideos() {

    const [userDocuments, setUserDocuments] = useState(false);
    const [loading, setLoading] = useState(false);


    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find((x) => x.key == str);

        if (translate) return getDefaultValue(translate);
        else return "#" + str;
    };

    useEffect(() => {
        setLoading(true);

        loadUserDocuments(null).then((usertalentDocuments) => {
            setLoading(false);
            setUserDocuments(usertalentDocuments);
        });
    }, []);




    return (
        <div className='widget-my-videos-container '>
            {userDocuments && userDocuments.map(userDocument =>
                (
                    <div key={"udpi"+userDocument.id} className="userdocument-item-box my-video" >
                        {userDocument && <UserDocumentWidgetItem userDocument={userDocument}  />}
                    </div>

                )
            
            )}


        </div>

    )
}

export default WidgetMyVideos