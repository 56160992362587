import React, { useState } from 'react'
import Modal from "react-modal";
import { useStateValue } from '../../StateProvider';
import { getDefaultValue } from '../../utils';
import ModalRules from './ModalRules';

function ModalAcceptRules({ isOpen = false, setIsOpen ,onAccept }) {
    const [rulesmodalIsOpen, setRulesModalIsOpen] = useState(false);
    const [checked, setChecked] = React.useState(false);
    const [rules_error, setRulesError] = React.useState(true);


    const closeModal = () => {
        setIsOpen(false);
    };

    const closeRulesModal = () => {
        setRulesModalIsOpen(false);
    };

    const onSubmit = () => {
        setRulesError("")
        if (!checked) {
            setRulesError(translate("Please accept terms and condititions"))
            return;
        }
        setChecked(true);
        setRulesError(false);
        setIsOpen(false);
        onAccept();
    };

    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find((x) => x.key == str);

        if (translate) return getDefaultValue(translate);
        else return "#" + str;
    };

    return (

        <>
            <Modal
                closeTimeoutMS={500}

                isOpen={isOpen}
                onRequestClose={closeModal}
                className="Modal"
                overlayClassName="Overlay"
            >
                <div className="modal-content lan-rtl">
                    <div className="modal-header ">
                        <h4 className="modal-title lan-text-right" id="defaultModalLabel">{translate('Participate in competition')}</h4>
                        <button type="button" className="close lan-float-left lan-ml-0" onClick={closeModal}>&times;</button>
                    </div>
                    <div className="modal-body ">
                        <p className="text-color-primary lan-text-right">
                            {translate('Thank you for your interest. to participate in a competition you should accept our rules and conditions.')}
                        </p>
                        <div className="">
                            <input id="rules" type="checkbox" defaultChecked={checked} onChange={() => setChecked(!checked)} className="pull-left lan-float-right rules-check" />
                            <label className="label pull-left lan-float-right lan-mr-5" htmlFor="rules">{translate("I accept rules and conditions")}</label>
                            <div className="clear"></div>
                        </div>
                        <p className="text-color-primary font-11 mx-3 lan-text-right">
                            <a href="#rules" onClick={(e) => { e.preventDefault(); setRulesModalIsOpen(true); }}>{translate('kurdish talent rules')}</a>
                        </p>
                        {rules_error &&
                            <div className="alert alert-danger mt-3 lan-text-right">
                                {rules_error}
                            </div>
                        }
                    </div>
                    <div className="modal-footer">
                        <button className="pull-right btn btn-primary" onClick={onSubmit}>{translate('Submit participate')}</button>
                    </div>
                </div>


            </Modal>
            <ModalRules isOpen={rulesmodalIsOpen} setIsOpen={setRulesModalIsOpen} />

        </>
    )
}

export default ModalAcceptRules