import { useStateValue } from 'StateProvider';
import UrlEmbed from 'components/UrlEmbed'
import React from 'react'
import { useState } from 'react';
import { getYoutubeId, isYoutube, translate } from 'utils';
import Modal from 'react-modal';

function VideoCarouselItem({ item }) {
    const [{ translations }] = useStateValue();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modal_loading, setModalLoading] = useState(null);
    const [modal_url, setModalUrl] = useState(null);

    const PrepareModal = (coursehistorygallery) => {
        setModalLoading(true)
        setModalUrl(coursehistorygallery.url)
        setModalIsOpen(true)
        setModalLoading(false)
    }


    const closeModal = () => {
        setModalIsOpen(false)
    }


    return (
        <>
        <div key={item.id} onClick={() => { setModalIsOpen(true); PrepareModal(item) }} style={{marginRight:5, cursor:"pointer"}} >
            {item && isYoutube(item.url) && (
                <img
                    src={`https://img.youtube.com/vi/${getYoutubeId(
                        item.url
                    )}/maxresdefault.jpg`}
                    className="img-fluid"
                    alt=""
                />
            )}

            
        </div>
        
        {modalIsOpen &&
                <Modal
                    closeTimeoutMS={500}
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    className="Modal "
                    ariaHideApp={false}
                    overlayClassName="Overlay"
                >
                    <div className="modal-content">
                        <div className="modal-body row">

                            <div className="col-md-12 file-container flex-center">
                                {modal_loading &&
                                    <>
                                        <div className="bounce-loader">
                                            <div className="bounce1"></div>
                                            <div className="bounce2"></div>
                                            <div className="bounce3"></div>
                                        </div>
                                    </>
                                }
                                {modal_url && <UrlEmbed url={modal_url} />}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light pull-right kfont rtl-pull-left" onClick={closeModal}>{translate(translations, 'Close')}</button>
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
}

export default VideoCarouselItem