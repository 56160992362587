import React, { useEffect, useState } from "react";
import { useStateValue } from "../../StateProvider";
import { getDefaultName, getDefaultValue } from "../../utils";
import { loadNewestCourse } from "../../utils/Network";

function NewestCourse({ talent }) {
  const [newest_course, setNewestCourse] = useState(null);

  const [{ translations }] = useStateValue();
  const translate = (str) => {
    if (!translations) return str;
    var translate = translations.find((x) => x.key == str);

    if (translate) return getDefaultValue(translate);
    else return "#" + str;
  };

  useEffect(() => {
    loadNewestCourse(talent).then((course) => {
      setNewestCourse(course);
    });
  }, []);

  return (
    <>
      {newest_course && (
        <div className="col kfont lan-rtl lan-text-right my-4">
        {translate('After submitting your video, you can proceed to participate in the contest.')}
        <br />
          <i className="fas fa-trophy color-orange margin-right-10 margin-left-20"></i>
            <a
                href={`contests`}
                className="color-orange margin-left-15 kfont"
            >
                {getDefaultName(newest_course.names)}
                {/* <CourseParticipated course={newest_course.id} /> */}
            </a>
        </div>
      )}
    </>
  );
}

export default NewestCourse;
