import React from 'react'
import PostVideoGalleryController from '../controller/PostVideoGalleryController';
import Slider from 'react-slick';
import VideoCarouselItem from 'pages/shared/Home/components/videogallery/VideoCarouselItem';


function PostVideoGallery({ id, translations }) {
    const { videoGalleries, settings } = PostVideoGalleryController(id)

    return (
        <>
            {(videoGalleries && videoGalleries.length>0) && (
                <div className='carousel-container' >
                    <Slider {...settings} >
                        {videoGalleries && videoGalleries.map(item => (
                            <VideoCarouselItem item={item} key={item.id} />
                        )
                        )}
                    </Slider>
                </div>
            )}
        </>

    )
}

export default PostVideoGallery