import React, { useEffect, useState, } from 'react'
import { useStateValue } from '../../StateProvider';
import { getDefaultName, getDefaultValue, } from '../../utils'
import { loadSettings } from 'utils/Network';


const ResponsiveMenu = ({ talents, user, profile, show, hideMenu, current_language }) => {
	const [show_menu, setShowingMenu] = useState(null);
	const [child_show_menu, setChildShowingMenu] = useState(null);
	const [show_profile_menu, setShowProfileMenu] = useState(true);
	const [show_talent_menu, setShowTalentMenu] = useState(false);
	const [show_language_menu, setShowLanguageMenu] = useState(false);
	const [settings, setSettings] = useState(false);


	useEffect(() => {
		loadSettings().then((settings) => {
			setSettings(settings);
		  });
	}, [])


	const openMenu = (e, talent) => {
		if (talent.childs.length > 0) {
			e.preventDefault()
			setShowingMenu(talent.id)
			if (talent.id === show_menu)
				setShowingMenu(null)
		}
	}
	const showLanguageMenu = (e,) => {
		e.preventDefault()
		setShowingMenu(null)
		setShowProfileMenu(false)
		setShowLanguageMenu(!show_language_menu)
	}


	const openChildMenu = (e, talent) => {
		if (talent.childs.length > 0) {
			e.preventDefault()
			setChildShowingMenu(talent.id)
			if (talent.id === child_show_menu)
				setChildShowingMenu(null)
		}
	}

	const openProfileMenu = (e) => {
		e.preventDefault()
		setShowingMenu(null)
		setShowProfileMenu(!show_profile_menu)
	}

	const openTalentsMenu = (e) => {
		e.preventDefault()
		setShowProfileMenu(false)
		setShowTalentMenu(!show_talent_menu)
	}

	const [{ translations }] = useStateValue();
	const translate = (str) => {
		if (!translations) return str;
		var translate = translations.find(x => x.key == str);

		if (translate) return getDefaultValue(translate);
		else return "#" + str;
	};


	return (
		<nav className={`responsive-menu ${show ? "active" : ""}`}>
			<button onClick={hideMenu} className="btn menu-close">
				{/* <i className="fa fa-times"></i>  */}
				{translate("Close")}
			</button>
			<ul className="nav nav-list flex-column">
				<li className="nav-item">
					<a className="nav-link" href={`/`}>{translate("Home")}</a>
				</li>
				<li className="nav-item">
					<a className="nav-link" href={`/about`}>{translate("About")}</a>
				</li>
				<li className="nav-item">
					<a className="nav-link" href={`/kurdishtalent-2022`}>{translate("Contests")}</a>
				</li>

				{/* <li className="nav-item">
					<a href="#talents" onClick={openTalentsMenu} className="nav-link">{translate("Talents")}</a>
					<ul className={show_talent_menu ? "show nav nav-list flex-column mb-5" : "hide"}>
						{talents && talents.map(talent => (
							<li key={talent.id} className="nav-item">
								<a className="nav-link" onClick={(e) => { openMenu(e, talent) }} href={`talent/${talent.id}`}>{getDefaultName(talent.names)}  </a>
								{talent.childs &&
									<ul className={show_menu == talent.id ? "show" : "hide"}>
										{talent.childs.map(child => (
											<li key={child.id} className="nav-item">
												<a className="nav-link" href={`talent/${child.id}`} onClick={(e) => { openChildMenu(e, child) }}>{getDefaultName(child.names)} </a>
												{child.childs &&
													<ul className={child_show_menu == child.id ? "show" : "hide"}>
														{child.childs.map(grand_child => (
															<li key={grand_child.id} className="nav-item">
																<a className="nav-link" href={`talent/${grand_child.id}`}>{getDefaultName(grand_child.names)} </a>

															</li>

														))}

													</ul>
												}
											</li>

										))}

									</ul>
								}
							</li>
						))}
					</ul>
				</li> */}
				<li className="nav-item">
					<a className="nav-link" href={`/blog`}>{translate("Blog")}</a>
				</li>

				<li className="nav-item">
					<a className="nav-link" href={`/faq`}>{translate("FAQ")}</a>
				</li>



				{!user &&
					<li className="dropdown">
						<a className="dropdown-item " href="/signin">
							Sign Up
						</a>

					</li>}
				{user &&
					<li className="nav-item ">
						<a href="/signin" onClick={openProfileMenu} className="noch">
							<img className="rounded-circle user-logo" width="40" height="40" alt="" src={profile && profile.image ? profile.image : "/assets/images/user.png"} />
							{user && (user.first_name ? user.first_name + " " + user.last_name : user.email)}

						</a>
						<ul className={show_profile_menu ? "show mt-3" : "hide "} >
							{profile && profile.role == "referee" &&
								<li >
									<a className="nav-link" href="/referee">
										{translate("Home")}
									</a>
								</li>
							}
							{profile && profile.role == "participant" &&
								<li >
									<a className="nav-link" href="/dashboard">
										{translate("My Profile")}
									</a>
								</li>
							}
							{profile && profile.role == "participant" &&
								<li >
									<a className="nav-link" href="/profile">
										{translate("Manage Profile")}
									</a>
								</li>
							}
							{profile && profile.role == "participant" &&
								<li>
									<a className="nav-link" href="/contests">
										{translate("Contests")}
									</a>
								</li>
							}



							<li >
								<a className="nav-link border-bottom-0" href="/signout">
									{translate("Log Out")}
								</a>
							</li>
						</ul>

					</li>

				}


			</ul>
			<ul className="language-menu show ">
				<li>
					<a className="nav-link border-bottom-0" href={window.location.origin + window.location.pathname + `?lang=en`}>
						English
					</a>
				</li>
				<li>
					<a className="nav-link border-bottom-0 always-kfont" href={window.location.origin + window.location.pathname + `?lang=so`}>
						سۆرانی
					</a>
				</li>
				<li>
					<a className="nav-link border-bottom-0" href={window.location.origin + window.location.pathname + `?lang=ku`}>
						Kurdî‎
					</a>
				</li>
			</ul>

			<ul className="social-icons text-left mt-3 pl-2 social-icons-big social-icons-dark-2">
				<li className="social-icons-facebook"><a href={settings?.facebook} target="_blank" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
				<li className="social-icons-twitter"><a href={settings?.twitter} target="_blank" title="Twitter"><i className="fab fa-twitter"></i></a></li>
				<li className="social-icons-youtube"><a href={settings?.youtube} target="_blank" title="Youtube"><i className="fab fa-youtube"></i></a></li>
				<li className="social-icons-instagram"><a href={settings?.instagram} target="_blank" title="Instagram"><i className="fab fa-instagram"></i></a></li>
			</ul>
		</nav>

	)

}
export default ResponsiveMenu
