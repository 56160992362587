import React from 'react'
import { getDefaultValue } from '../../utils';
import LatestPosts from './LatestPosts'

export default function Intro({ translations }) {
	

	const translate = (str , def) => {
		if(def==null) def = str;
		if (!translations) return def;
		var translate = translations.find(x=>x.key === str );
	
		if (translate) return getDefaultValue(translate);
		else return "#" + str;
	  };
	  
		return (

			<div id="main" role="main" className="main">
				{/* <div className="home-intro" id="home-intro">
					<div className="container">

						<div className="row align-items-center">
							<div className="col-lg-8">
								<p>
									{translate('Do you have a talent you want the world to see?')}
									<span>{translate('Don’t hesitate to register!')}</span>
								</p>
							</div>
							<div className="col-lg-4">
								<div className="get-started text-left text-lg-right">
									<a href="https://apps.apple.com/us/app/kurdish-talent/id1603624883" target="_blank" rel="noreferrer" className="btn btn-primary btn-lg text-3 font-weight-semibold "><img src={'assets/images/apple.png'} alt="" style={{ width:130, }} /></a>
									&nbsp;&nbsp;
									<a href="https://play.google.com/store/apps/details?id=com.solutionscjit.kurdishtalent" target="_blank" rel="noreferrer" className="btn btn-primary btn-lg text-3 font-weight-semibold "><img src={'assets/images/google.png'} alt="" style={{ width:130, }} /></a>
								</div>
							</div>
						</div>

					</div>
				</div> */}
				

				
				

			</div>

		)
}
