import React, { useEffect, useState } from 'react'
import { getCourseNames, getYoutubeId, isDocument, isImage, isYoutube, toTime, translate } from '../../utils';
import { deleteUserTalentDocument } from '../../utils/Network';
import ModalDeleteUserDocument from '../dialogs/ModalDeleteUserDocument';
import parse from 'html-react-parser'
import LoadingOverlay from '../shared/LoadingOverlay';
import { useStateValue } from '../../StateProvider';
import ModalDocumentPreview from '../dialogs/ModalDocumentPreview';
import UrlEmbed from '../UrlEmbed';


function UserDocumentPreview({ showVideoMessage = true, userDocument, setUserDocument, handleEdit }) {
    const [duration, setDuration] = useState(null);
    const [loading, setLoading] = useState(false);
    const [documentPreviewModalFile, setDocumentPreviewModalFile] = useState(null);
    const [documentPreviewModalIsOpen, setDocumentPreviewModalIsOpen] = useState(false);

    const [deleteDocumentModalIsOpen, setDeleteDocumentModalIsOpen] = useState(false);
    const [progress, setProgress] = useState(false);
    const [{ translations }] = useStateValue();

    var editing = false;
    var deleting = false;

    useEffect(() => {

    }, []);


    const handleVideoMounted = (element) => {
        if (element !== null && element) {
            //element.currentTime = 2;
            if (!isNaN(element.duration)) setDuration(element.duration);
        }
    };

    const handleChange = (e) => {
        //e.stopPropagation();
        editing = true;
    };

    const handleDelete = () => {
        //e.stopPropagation();
        deleting = true;
    };



    const handleDocumentDelete = (e) => {
        e.preventDefault();
        setLoading(true)
        setDeleteDocumentModalIsOpen(false);
        deleteUserTalentDocument(userDocument).then((data) => {
            setUserDocument(null);
            setLoading(false)

        });
    };


    const handleClick = (file_path, url) => {
        // when this function called : we may uploading a document or view the document or delete the document

        if (editing || !userDocument) {

            //   setUploadModalError(false);
            //   setUploadModalOpen(true);
            editing = false;
            return;
        } else if (deleting) {
            deleting = false;
            if (userDocument) {
                if (userDocument.courses !== "[]") {
                    //   setUploadError(
                    //     "This document cannot be deleted beacause it is in a contest "
                    //   );
                    //   openModal();
                } else {
                    setDeleteDocumentModalIsOpen(true);
                    // if (window.confirm("Are you sure you want to delete this item ?")) {

                    // }
                }
            }
        }
        else openDocumentPreviewModal(file_path, url);
    };


    const openDocumentPreviewModal = (file_path, url) => {
        var file = {
            isImage: isImage(file_path),
            isDocument: isDocument(file_path),
            path: file_path,
            url: url
        }
        setDocumentPreviewModalFile(file)
        setDocumentPreviewModalIsOpen(true)
    }

    return (
        <div>
            <ModalDeleteUserDocument
                handleDocumentDelete={handleDocumentDelete}
                isOpen={deleteDocumentModalIsOpen}
                setIsOpen={setDeleteDocumentModalIsOpen}
            />
            <ModalDocumentPreview document={documentPreviewModalFile} setIsOpen={setDocumentPreviewModalIsOpen} isOpen={documentPreviewModalIsOpen} />
            <div className="portfolio-item flex-center ">
                {/* <h3>{parse(translate(translations, "Uploaded documents 11"))}</h3> */}

                {(userDocument && userDocument.courses === "[]") ? (
                    <div className="document-preview-top-btns kfont ">
                        <div className="item lan-rtl" onClick={() => {
                            setDeleteDocumentModalIsOpen(true);
                        }} >
                            <i className="fa fa-trash"></i>
                            {translate(translations, "Delete this video")}
                        </div>
                        <div className="item lan-rtl" onClick={() => { handleEdit(); }} >
                            <i className="fa fa-video"></i>
                            {translate(translations, "Change this video")}
                        </div>
                    </div>
                ) : (
                    <div className='document-is-in-contest' >
                        {translate(translations, "This video is in a contest and cannot be changed")}
                    </div>
                )}

                <div className="userdocument-preview-video-container">

                    {userDocument && isYoutube(userDocument.url) && (
                        <UrlEmbed url={userDocument.url} />
                    )}

                    {userDocument &&
                        userDocument.url != null &&
                        userDocument.url.includes("firebasestorage") && (
                            <video key={userDocument.url} controls className="video-fit-contain" >
                                <source src={userDocument.url} type="video/mp4" />
                            </video>
                        )}


                </div>
                {showVideoMessage &&
                    <p className="mt-2 kfont lan-text-right font-11 padding-2" style={{ lineHeight: 1.8 }}>
                        <i className="fa fa-check color-green"></i> &nbsp;
                        {parse(translate(translations, "your document is uploaded"))} &nbsp;
                        {parse(translate(translations,"your video is received"))}

                        
                        <br />
                        {/* <a href={userDocument && userDocument.url} target="_blank">
                            <i className="fa fa-download"></i> {translate("click to download")}
                            </a> */}
                    </p>
                }
            </div>

        </div>
    )
}

export default UserDocumentPreview


