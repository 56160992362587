import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { BuildTalents, getDefaultName, getSelectedTheme, sortCities } from '../../utils'
import { loadCities, loadCity, loadCountries, loadCourses, loadTalents } from '../../utils/Network'

export default function SelectCities({ callback, country, value, width, name , placeholder }) {

    const [SelectOptions, setSelectOptions] = useState([]);

    var theme = getSelectedTheme();

    const customStyles = {
        control: (base, state) => ({
            ...base,

            background: theme === "light" ? "#f7f7f7" : "transparent",
            // match with the menu
            borderRadius: 25,
            // Overwrittes the different states of border
            borderColor: theme === "light" ? "#dee2e6" : "#424240",
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: theme === "dark" ? "#424240" : "#dee2e6"
            }
        }),
        menu: base => ({
            ...base,
            width: "150px",
            '*::-webkit-scrollbar': {
                width: '0.5em'
            },
            '*::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: theme === "dark" ? '#282828' : "#c9c9c9",
                outline: theme === "dark" ? '1px solid #272727' : '1px solid #ddd'
            },
            marginLeft: "16px",
            boxShadow: theme === "dark" ? "1px 1px 1px 1px #0e0e0e80" : "2px 3px 3px 1px #ccc",
            // override border radius to match the box
            borderRadius: 0,
            borderBottomRightRadius: 5,

            borderBottomLeftRadius: 5,
            // kill the gap
            marginTop: 0,
            //borderTop: "3px solid #454542",
            backgroundColor: theme === "light" ? "#f9f9f9" : "#191a1c",
            opacity: "0.97"

        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 0,
            "&:hover": {
                backgroundColor: theme === "light" ? "#f9f9f9" : "#191a1c",
                borderBottomRightRadius: 5,
                borderBottomLeftRadius: 5,
                transition: "all 0.5s ease-out"
            }
        }),
        option: (base, state) => {


            let backgroundColor = "transparent";

            if (state.isSelected) {
                //backgroundColor = theme === "light" ? "#ccc" :"#454542";
            }

            if (state.isFocused) {
                backgroundColor = theme === "light" ? "#ccc" : "#282828";
            }
            if (state.isActive) {
                // backgroundColor = theme === "light" ? "#ccc" :"#454542";
            }

            return {
                ...base,
                color: theme === "light" ? "#282828" : "#ccc",
                fontSize: "14px",
                backgroundColor,
                transition: "all 0.5s ease-out"
            };
        }

    };


    useEffect(() => {
        if (country){
            loadCities(country).then(data => {
                if (data.results) {
                    var cities = data.results
                    sortCities(cities)
                    var temp_options = []
                    cities.map((item, i) => {
                        var option = null;
                        option = {
                            label: getDefaultName(item.names),
                            value: item.id
                        }
                        temp_options.push(option)
                    })
                    //
                    setSelectOptions(temp_options);
                    // callback()
                    //setTalents(temp)
                }

            })
        }
            
    }, [])

    const handleSelect = (e) => {
        var value = e.value;
        callback(name,value)

    }

    return (
        <>
            <div className="filter-select kfont">
                <Select
                    options={SelectOptions}
                    styles={customStyles}
                    onChange={handleSelect}
                    placeholder={placeholder ? placeholder : "Cities"}
                    value={SelectOptions && SelectOptions.filter(obj => obj.value == value)}

                />
            </div>

        </>
    )
}
