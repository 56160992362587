import React, { useEffect, useState } from 'react'
import { getDay, getDefaultValue, getMonth } from '../../../utils';
import { loadPost } from '../../../utils/Network';
import parse from 'html-react-parser'
import { useStateValue } from '../../../StateProvider';
import TalentName from '../../../components/talent/TalentName';
import PostVideoGallery from './components/PostVideoGallery';
import VideoCarousel from '../Home/components/videogallery/VideoCarousel';
import PostsSidebar from './PostsSidebar';


export default function PostContent({ id }) {

    const [loading, setLoading] = useState(false);
    const [post, setPost] = useState(null);

    const current_page_url = window.location.href



    useEffect(() => {
        setLoading(true)
        document.title = 'KurdishTalent | Blog '



        if (id) {
            loadPost(id).then(post => {
                if (post) {
                    setPost(post)
                    document.title = 'KurdishTalent | ' + post.title
                }

                setLoading(false)
            })
        }



    }, [])

    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x => x.key == str);

        if (translate) return getDefaultValue(translate);
        else return "#" + str;
    };


    return (
        <div id="main" role="main" className="main bg-blog">

            <div className="container pb-2">
                {loading &&
                    <>
                        <div className="bounce-loader">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                        </div>
                    </>
                }

                {!loading && post &&
                    <div className="row ">
                        <div className="col ">
                            <div className="blog-posts single-post sm-mt-100 mt-blog-top ">

                                <article className="post post-large blog-single-post border-0 m-0 p-0">
                                    {/* <div className="post-date ml-0 hide-on-small">
                                        <span className="day">{getDay(post.created_at)}</span>
                                        <span className="month">{getMonth(post.created_at)}</span>
                                    </div> */}

                                    <div className="post-content ml-0 ">

                                        <h3 className="font-weight-semi-bold lan-kfont lan-rtl lan-text-right">{post.title}</h3>

                                        <div className="post-meta hide-on-small">
                                            <span><i className="far fa-user"></i> By <a href="#KurdishTalent" className="kfont">{translate('Kurdish Talent')}</a> </span>
                                            <span><i className="far fa-folder"></i> <a href={`blog/${post && post.talent}`} className="kfont"><TalentName id={post.talent} /></a> </span>
                                        </div>
                                    </div>


                                    <div className="description text-justify">
                                        <div className="row lan-rtl">
                                            <div className="col-md-9 order-lg-1 blog-description">
                                                <div className="lan-rtl text-justify" >

                                                    <div className='mb-5'>
                                                        <img src={post.gallery_image ? post.gallery_image.image : "assets/images/default.png"} className="img-fluid full-width" alt="" />

                                                    </div>

                                                    {post.short_description &&
                                                        <div className="short_description lan-kfont ">{post.short_description}</div>
                                                    }

                                                    <div className='text-light-2'>
                                                        {parse(post.description)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 order-lg-2">
                                                <PostsSidebar />
                                            </div>


                                        </div>

                                        <PostVideoGallery id={post.id} translations={translations} />
                                        {/* <VideoCarousel /> */}
                                        <div className="share mb-5">
                                            <h4 className="mb-3 lan-float-right">{translate('Share this post')}</h4>
                                            <div className="clear"></div>
                                            <ul className="social-icons text-left lan-text-right social-icons-medium social-icons-dark-2 lan-text-right">
                                                <li className="social-icons-facebook"><a href={`https://www.facebook.com/sharer/sharer.php?u=${current_page_url}`} target="_blank" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
                                                <li className="social-icons-twitter"><a href={`http://twitter.com/share?text=${post && post.title}&url=${current_page_url}&hashtags=kurdishtalent`} target="_blank" title="Twitter"><i className="fab fa-twitter"></i></a></li>
                                                <li className="social-icons-linkedin"><a href={`https://www.linkedin.com/sharing/share-offsite/?url=${current_page_url}`} target="_blank" title="Share on Linkedin"><i className="fab fa-linkedin-in"></i></a></li>
                                            </ul>
                                        </div>


                                    </div>




                                </article>

                            </div>
                        </div>
                    </div>
                }


            </div>

        </div>
    )

}
