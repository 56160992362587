import React, { Component } from 'react'
import AboutContent from './AboutContent';

//loadProfile();

export default class About extends Component {


    render() {
        document.title = 'KurdishTalent | About'
        return (
            <div>
                <AboutContent />

            </div>
        )

    }
}
