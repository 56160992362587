import React from 'react'
import VideoCarouselItem from './VideoCarouselItem';
import Slider from 'react-slick';
import VideoCarouselController from '../../Controller/VideoCarouselController';
function VideoCarousel() {
    const  { loading,userGalleries, setUserGalleries,} = VideoCarouselController();

    var settings = {
        dots: false,
        autoplay:true,
        speed: 1000,
        slidesToShow: 5,
        slidesToScroll: 5,
        arrows:true,
        centerMode:true,
        responsive: [
            {
              breakpoint: 1200,
              settings: {
                  slidesToShow: 5,
                  slidesToScroll: 5,
              }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
      };


    return (
        <div className='carousel-container' >
            <Slider    {...settings}
            >
                {userGalleries && userGalleries.map(item => (
                    <VideoCarouselItem item={item} key={item.id} />
                )
                )}
            </Slider>
            {/* {userGalleries && userGalleries.map(item => (
                    <VideoCarouselItem item={item} key={item.id} />
                )
                )} */}
        </div>
    )
}

export default VideoCarousel