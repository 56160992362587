import { HttpService } from "../../services/http";
import { ApiBase } from "../Network";

export class ContestHttpService {

  static async saveUsertalentDocument(body) {

    var response;
    var service = new HttpService();
    if (body.id) {
      response = await service.put(`api/usertalent/userdocument/${body.id}`, body);
    }
    else {
      response = await service.post(`api/usertalent/userdocument/`, body);
    }

    return response;
  }

  static async loadContests(query) {

    var service = new HttpService();
    var response = await service.get(`api/course/${query}`);

    return response;
  }

  static async loadCourseParticipant(query) {
    var service = new HttpService();
    var response = await service.get(`api/courseparticipant/${query}`);

    return response;
  }

  static async participate(body) {
    var service = new HttpService();
    var response = await service.post(`api/courseparticipant/participate/`, body);

    return response;
  }

}