import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
import validator from 'validator'
import { useStateValue } from '../../StateProvider';
import { getDefaultValue, setUserSession } from '../../utils';
import { ApiBase, doLogin, resendVerificationEmail } from '../../utils/Network';
import ShowVideoModal from '../dialogs/ShowVideoModal';

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);


    const handleChange = e => {
        setValue(e.target.value);
    }
    return {
        value,
        onChange: handleChange
    }
}

export default function AudienceLoginModal({ isOpen,setOpen, setLoggedIn,onClose }) {

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [show_video_is_open, setShowVideoIsOpen] = useState(false);

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [email_sent, setEmailSent] = useState(false);
    const [login_email_not_verified, setLoginEmailNotVerified] = useState(false);
    const username = useFormInput('');
    const password = useFormInput('');

    const register_username = useFormInput('');
    const register_password = useFormInput('');
    const register_name = useFormInput('');
    const register_surename = useFormInput('');
    const register_phone = useFormInput('');
    const [register_error, setRegisterError] = useState(null);
    const [register_loading, setRegisterLoading] = useState(false);
    const [register_done, setRegisterDone] = useState(false);
    const [register_email_sent, setRegisterEmailSent] = useState(false);


    const closeModal = () => {
        setModalIsOpen(false)
        setOpen && setOpen(false)
        onClose && onClose()
    }


    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x=>x.key == str );
    
        if (translate) return getDefaultValue(translate);
        else return "#" + str;
      };

    useEffect(() => {
        setModalIsOpen(isOpen)
    }, [])

   

    const resendLoginVerificationEmail = (e) => {
        e.preventDefault();
        setEmailSent(false);
        setError(false)

        if (!validator.isEmail(username.value)) {
            setError('Email address is not valid');
            setLoading(false);
            return;
        }
        setLoading(true)
        resendVerificationEmail(username.value).then((data) => {
            setLoading(false)
            setEmailSent(true);
            setLoginEmailNotVerified(false)
        })
    }

    const handleLogin = (e) => {
        e.preventDefault()
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        setError(null);
        setLoading(true);
        setEmailSent(false);
        setLoginEmailNotVerified(false)

        if (!validator.isEmail(username.value)) {
            setError('Email address is not valid');
            setLoading(false);
            return;
        }

        if (!validator.isLength(password.value, { min: 6 })) {
            setError('Password is not valid');
            setLoading(false);
            return;
        }


        doLogin({ email: username.value, password: password.value }).then((data) => {
            setLoading(false)
            if (data.tokens) {
                setUserSession(data.tokens.access, data);

                if (data.role === "referee")
                    window.location.href = "/referee"
                else if (data.role === "participant")
                    window.location.href = "/dashboard"
                else if (data.role === "normal") {
                    closeModal()
                    setLoggedIn(data)
                }

            }
            else {
                setError(data.detail);

                if (data.detail && data.detail.includes("Email")) {
                    setLoginEmailNotVerified(true)
                }
            }
        })

    }

    const handleRegister = () => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        setRegisterError(null);
        setRegisterLoading(true);
        setRegisterDone(false);
        setRegisterEmailSent(false);

        if (!validator.isLength(register_name.value, { min: 3 })) {
            setRegisterError('Please Enter Your Name');
            setRegisterLoading(false);
            return;
        }

        if (!validator.isLength(register_surename.value, { min: 3 })) {
            setRegisterError('Please Enter Your Surname');
            setRegisterLoading(false);
            return;
        }


        if (!validator.isEmail(register_username.value)) {
            setRegisterError('Email address is not valid');
            setRegisterLoading(false);
            return;
        }

        if (!validator.isLength(register_password.value, { min: 6 })) {
            setRegisterError('Password must be 6 characters or more ');
            setRegisterLoading(false);
            return;
        }



        var body = {
            email: register_username.value,
            password: register_password.value,
            first_name: register_name.value,
            last_name: register_surename.value,
            redirect_url: "http://kurdishtalent.com/validate"
        }


        fetch(`${ApiBase}auth/register/`, {
            headers: new Headers(headers),
            method: 'POST',
            body: JSON.stringify(body),

        })
            .then((response) => {
                setRegisterLoading(false);
                console.log(response)
                if (response.status >= 400) {
                    setRegisterError("This email address is already exists.");
                }
                return response.json();
            }).then(function (data) {
                console.log(data);
                setRegisterLoading(false);

                if (data.data && data.data.email) {
                    //setRegisterError(data.email);
                    console.log(data.data)
                    setRegisterDone(true);

                }
                if (data.errors) {
                    setRegisterError("This email address is already exists.");
                }


            });


    }


    const resendRgisterVerificationEmail = (e) => {
        e.preventDefault();
        setRegisterError(false)
        if (!validator.isEmail(register_username.value)) {
            setRegisterError('Email address is not valid');
            setRegisterLoading(false);
            return;
        }

        setRegisterLoading(true)
        resendVerificationEmail(register_username.value).then((data) => {
            setRegisterLoading(false)
            setRegisterEmailSent(true);
            setRegisterDone(false)
        })
    }

    return (
        <>
            <Modal
                closeTimeoutMS={500}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="Modal Modal-login modal-lg"
                ariaHideApp={false}
                overlayClassName="Overlay"
            >
                <div className="modal-content">
                    <div className="modal-header ">
                        <div className="row full-width ">
                            <div className="col-md-6">
                                <h4 className="modal-title" id="defaultModalLabel">{translate('Register')}</h4>
                            </div>
                            <div className="col-md-6">
                                <h4 className="modal-title" id="defaultModalLabel">{translate('Login')}</h4>
                            </div>
                            
                        </div>
                        <button type="button" className="close lan-ml-0" onClick={closeModal}>&times;</button>

                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6">
                                <form action="" className="form-fields-rounded">
                                    <div className="">
                                        <p className="lan-kfont lan-text-right">{translate('Not a member? Register then vote')}</p>
                                    </div>
                                    <div className="form-row lan-rtl">
                                        <div className="form-group col-lg-6">
                                            <input type="text" data-msg-required="Please enter your name." maxlength="100" className="form-control text-3 h-auto py-2 lan-rtl" name="name"  {...register_name} placeholder={translate('Name')} />
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <input type="text" data-msg-required="Please enter your last name." maxlength="100" className="form-control lan-rtl text-3 h-auto py-2" name="surename"  {...register_surename} placeholder={translate("Surename")} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="inputGroup-sizing-default"><i className="fa fa-user"></i></span>
                                                </div>
                                                <input type="email" placeholder={translate("Email")} name="register_username" {...register_username} className="form-control text-3 h-auto py-2" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="inputGroup-sizing-default"><i className="fa fa-lock"></i></span>
                                                </div>
                                                <input type="password" placeholder={translate("Password")} name="register_password" {...register_password} className="form-control text-3 h-auto py-2" required />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col">
                                            {register_loading && <><small className="loading">{translate("Please Wait...")}</small><br /><br /></>}

                                            <button type="button"  onClick={handleRegister} disabled={register_loading} 
                                            className="btn width-200 btn-primary lan-rtl  btn-block text-uppercase rounded-35 font-weight-bold text-3  py-10" data-loading-text="Loading...">
                                                <i className="fas fa-user-plus op-7"></i>&nbsp;
                                                {translate('Register')}
                                            </button>

                                            {register_error && <>
                                                <br />
                                                <div className="alert alert-danger text-2 ">
                                                    {register_error}
                                                </div>
                                            </>}


                                            {register_done && <>
                                                <br />
                                                <div className="alert alert-primary">
                                                    {translate('Register Completed. please check your email address to verify your account.')} <br />
                                                    <a href="#resend_email" onClick={resendRgisterVerificationEmail}>{translate("didn't get verification email ? Send  again")}</a>
                                                </div>
                                            </>


                                            }
                                            {register_email_sent && <>
                                                <br />
                                                <div className="alert alert-primary">
                                                    {translate('Email Sent. please check your email address to verify your account.')}
                                                </div>
                                            </>


                                            }

                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-6">
                                <div className="">
                                    <p className="lan-kfont lan-text-right">{translate('login and vote')}</p>
                                </div>
                                <form onSubmit={handleLogin} className="needs-validation form-fields-rounded">
                                    <div className="form-row">
                                        <div className="form-group col">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="inputGroup-sizing-default"><i className="fa fa-user"></i></span>
                                                </div>
                                                <input type="email" placeholder="Email" name="username"  {...username} className="form-control text-3 h-auto py-2" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="inputGroup-sizing-default"><i className="fa fa-lock"></i></span>
                                                </div>
                                                <input type="password" placeholder="Password" name="password" {...password} className="form-control text-3 h-auto py-2" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row justify-content-between">
                                        <div className="form-group col-md-auto">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="rememberme" />
                                                <label className="custom-control-label cur-pointer text-2" htmlFor="rememberme">{translate('Remember Me')}</label>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-auto">
                                            <a className="text-decoration-none text-color-hover-white color-gray text-2 kfont" href="/forgot">{translate('Forgot Password ?')}</a>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col">
                                            {loading && <><small style={{ color: 'green' }}>{translate('Please Wait...')}</small><br /><br /></>}

                                            <button type="submit" className="btn width-200 lan-rtl  btn-primary  btn-block text-uppercase rounded-35 font-weight-bold text-3 py-10" value={loading ? 'Loading...' : 'Login'} disabled={loading} data-loading-text="Loading...">
                                                <i className="fas fa-user  op-7"></i>&nbsp;
                                                {translate('Login')}
                                            </button>


                                            <br />
                                            {error &&
                                                <>
                                                    <div className="alert alert-danger text-2">
                                                        {error}
                                                    </div>
                                                </>
                                            }
                                            {login_email_not_verified &&
                                                <>
                                                    <div className="alert alert-primary text-2">
                                                    <a href="#resend_email" onClick={resendLoginVerificationEmail}>{translate("didn't get verification email ? Send  again")}</a>

                                                    </div>
                                                </>
                                            }
                                            {email_sent && <>
                                                <div className="alert alert-success">
                                                    {translate('Email Sent. please check your email address to verify your account.')}
                                                </div>
                                            </>


                                            }


                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light pull-left kfont" onClick={closeModal}>{translate('Cancel')}</button>
                        <a href="#show_help" title={translate("Help")} onClick={(e)=>{ e.preventDefault(); setShowVideoIsOpen(true) }} type="button" className="help-icon-modal-footer pull-right" >
                        <img src="assets/images/tutorial_icon.png" style={{"width":40}} /> </a>
                    </div>
                </div>
            </Modal>
            <ShowVideoModal isOpen={show_video_is_open} url={"https://kurdishtalent.com/assets/video/ratinghelp.mp4"} setIsOpen={setShowVideoIsOpen} />

            {/* <Modal
                closeTimeoutMS={500}
                isOpen={register_modal_is_open}
                onRequestClose={closeRegisterModal}
                className="Modal Modal-login"
                ariaHideApp={false}
                overlayClassName="Overlay"
            >
                <div className="modal-content">
                    <div className="modal-header lan-rtl">
                        <h4 className="modal-title" id="defaultModalLabel">{translate('Audience Register')}</h4>
                        <button type="button" className="close lan-ml-0" onClick={closeRegisterModal}>&times;</button>
                    </div>
                    <div className="modal-body">
                        
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light pull-left kfont" onClick={closeRegisterModal}>{translate('Cancel')}</button>
                        <a href="#" onClick={openLoginModal} className=" pull-right kfont" title="Already registered ? Login here" >{translate('Login')}</a>
                    </div>
                </div>
            </Modal> */}
        </>
    )
}
