import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Modal from 'react-modal';
import { useStateValue } from '../../../StateProvider'
import { getDefaultDescription, getDefaultName, getPublicVoteCookie, getUser, setPublicVoteCookie, translate } from '../../../utils'
import {ApiHttpService} from '../../../utils/http/api'
import AudienceLoginModal from '../../../components/user/AudienceLoginModal';

function Poll({PollItem}) {
    document.title = 'KurdishTalent | Vote '
    const {id} = useParams()
    const [loading, setLoading] = useState(false)
    const [modal_loading, setModalLoading] = useState(false)
    
    const [poll, setPoll] = useState(null)
    const [voted, setVoted] = useState(null)
    
    const [selectedItem, setSelectedItem] = useState(null)
    const [{ translations }] = useStateValue();
    
    const [user, setUser] = useState(null);
    const [modal, openModal] = useState("");
    
    useEffect(() => {
        
        _getData()

        setUser(getUser())

    }, [])

    const _getData = ()=>{

        if(PollItem){ // data loaded before and we use it here. ay chon :)
            setPoll(PollItem)
        }
        else{ // Loading by id
            setLoading(true)
            if(id){
                ApiHttpService.loadPoll(id).then(data=>{
                    if(data.id){
                        setPoll(data)
                        _getVote(data.id);
                    }
                })
            }
            else{
                ApiHttpService.loadPolls().then(data=>{
                    if(data.results.length>0){
                        setPoll(data.results[0])
                        _getVote(data.results[0].id);
                    }
                    
                }) 
            }
            setLoading(false)
            
        }
        
        

        
    }

    const _getVote = (poll_id) => {
        var cookie = getPublicVoteCookie()
        if(!cookie)
            cookie = setPublicVoteCookie();
        
        ApiHttpService.loadVotes(poll_id).then(data=>{
            if(data?.results && data.results.length>0){
                var _vote = data.results[0]
                setVoted(_vote.pollitem)
            }
        })
    }

    
    
    const closeModal = () => {
        openModal("")
    }

    const setLoggedIn = (user) => {
        setUser(user)
        openModal("confirm")
    }

    const vote = () => {
        if(!selectedItem) {
            // setError("No PollItem selected")
            return;
        }
        
        var cookie = getPublicVoteCookie()
        if(!cookie)
            cookie = setPublicVoteCookie();

        var body={
            pollitem : selectedItem.id,
            cookie : cookie
        }

        setModalLoading(true)

        ApiHttpService.vote(body).then(data=>{
            if(data.id){
                openModal("done")
                setVoted(selectedItem.id)
            }
            setModalLoading(false)
        })

        // openModal("")


    }

    
  return (
    <div>
                <div id="main" role="main" className="main">
                    {loading &&
                        <>
                            <div className="bounce-loader">
                                <div className="bounce1"></div>
                                <div className="bounce2"></div>
                                <div className="bounce3"></div>
                            </div>
                        </>
                    }
                    <section className="page-header pb-0 section-with-shape-divider page-header-modern bg-color-dark-scale-1 page-header-lg mb-0 pb-4  page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-7 "
                        style={{ backgroundSize: "cover", backgroundPositionX: "center", backgroundImage: true ? "url('assets/images/headerbg.jpeg')" :"" }} >
                        <div className="container ">
                            <div className="row lan-rtl">

                                <div className="col-md-7  text-left lan-text-right">
                                    <h1 className="font-weight-bold text-light mb-20 lan-text-right lan-full-width mobile-poll-title">
                                        {getDefaultName(poll?.names)}
                                    </h1>
                                    <div className=" text-left lan-text-right">
                                        <div className="mb-2 mr-talent-desc text-justify text-talent-desc lan-text-right lan-mr-0 mobile-poll-desc">
                                            {getDefaultDescription(poll?.names)}
                                        </div>

                                    </div>

                                </div>
                                <div className="col-md-5 hide-on-small ">
                                    <img src={poll?.image} alt={poll?.title} className="img-fluid " />
                                </div>
                            </div>
                        </div>
                        <div className="shape-divider shape-divider-bottom" >
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 136" preserveAspectRatio="xMinYMin">
                                <path d="M 0 0.11 L 0 136 L 1920 136 L 1920 1.9 C 1650.0482 79.0955 1327.0648 134 980 134 C 615.6218 134 277.7878 84.5059 0 0.11 Z" fill="#212529" />
                            </svg>
                        </div>
                    </section>
                    
                    <section className="container mt-5">
                        <div className="row">
                        {poll?.items?.map((item, index) => (
                            <div key={index} className="col-md-4 mb-3" 
                            onClick={(e) => { 
                                                e.preventDefault()
                                                // if(!user) {
                                                //     setSelectedItem(item)
                                                //     openModal("login")
                                                // }
                                                // else
                                                 if(voted){
                                                    openModal("restrict")
                                                }
                                                else{
                                                    setSelectedItem(item)
                                                    openModal("confirm")
                                                }
                                                }}>
                                <section className={`call-to-action with-borders button-centered mb-2 ${voted==item.id ? 'voted' : ''}`}>
                                    <div className="col-12">
                                        <div className={`call-to-action-content`}>
                                            <div className="portfolio-item mobile-poll-item"  >
                                                <span className={`thumb-info  thumb-info-lighten circle-grandparent mobile-poll-image-size-p`}>
                                                    <span className="thumb-info thumb-info-centered-icons">
                                                        <span className="thumb-info-wrapper tusi-border circle-vote mobile-poll-image-size">
                                                            {item.usertalent.user.profile.image &&
                                                                <img src={item.usertalent.user.profile.image} className="img-fluid" alt="" />
                                                            }
                                                        </span>
                                                    </span>
                                                </span>
                                            </div>
                                            
                                            <div className="mt-2">
                                                <h4 className="font-18" >{ item.usertalent.user.profile.first_name} { item.usertalent.user.profile.last_name}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        {!voted && (
                                            <div className="call-to-action-btn" >
                                                <a href="#open"  onClick={(e) => { 
                                                    e.preventDefault()
                                                    //PrepareModal(item, item.courseparticipant.file, item.courseparticipant.url) 
                                                    }} className="btn btn-modern text-2 btn-primary">{translate(translations,"Vote Now")}</a>
                                            </div>
                                        )}
                                    </div>
                                </section>
                            </div>
                                

                        ))}
                            
                            
                            
                        </div>
                    </section>
                </div>

                <AudienceLoginModal key={modal} setLoggedIn={setLoggedIn} onClose={closeModal} isOpen={modal==="login"} />
                <Modal
                closeTimeoutMS={500}
                isOpen={modal==="confirm"}
                key={"confirm"+modal}
                onRequestClose={closeModal}
                className="Modal "
                ariaHideApp={false}
                overlayClassName="Overlay"
                >
                    <div className="modal-content">
                        <div className="modal-content">
                            <div className="modal-body ">
                                {modal_loading &&
                                    <>
                                        <div className="bounce-loader">
                                            <div className="bounce1"></div>
                                            <div className="bounce2"></div>
                                            <div className="bounce3"></div>
                                        </div>
                                    </>
                                }
                                <div className="portfolio-item flex justify-content-center "  >
                                    <span className={`thumb-info  circle-grandparent width-165 `}>
                                        <span className="thumb-info thumb-info-centered-icons  ">
                                            <span className="thumb-info-wrapper tusi-border circle-public ">
                                                {selectedItem?.usertalent.user.profile.image &&
                                                    <img src={selectedItem?.usertalent.user.profile.image} className="img-fluid" alt="" />
                                                }
                                            </span>
                                        </span>
                                    </span>
                                   
                                </div>
                                <div className="mt-2 text-center ">
                                    <p className="font-18" >{ selectedItem?.usertalent.user.profile.first_name} { selectedItem?.usertalent.user.profile.last_name}</p>
                                </div>
                                <div className="mt-2 text-center ">
                                    <h4 className="font-18" >{translate(translations,"Are you sure you want to vote for this item ?")}</h4>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light pull-right lan-float-left mx-2 kfont" onClick={vote}>{translate(translations,'Yes')}</button>
                                <button type="button" className="btn btn-light pull-right lan-float-left kfont" onClick={closeModal}>{translate(translations,'No')}</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                closeTimeoutMS={500}
                isOpen={modal==="done" || modal==="restrict"}
                onRequestClose={closeModal}
                className="Modal "
                ariaHideApp={false}
                overlayClassName="Overlay"
                >
                    <div className="modal-content">
                        <div className="modal-content">
                            <div className="modal-body ">
                                {modal==="done" &&
                                    <>
                                        <div className="portfolio-item flex justify-content-center "  >
                                            <span className={`thumb-info  circle-grandparent width-165 `}>
                                                <span className="thumb-info thumb-info-centered-icons  ">
                                                    <span className="thumb-info-wrapper tusi-border circle-public ">
                                                        {selectedItem?.usertalent.user.profile.image &&
                                                            <img src={selectedItem?.usertalent.user.profile.image} className="img-fluid" alt="" />
                                                        }
                                                    </span>
                                                </span>
                                            </span>
                                        
                                        </div>
                                        <div className="mt-2 text-center ">
                                            <p className="font-18" >{ selectedItem?.usertalent.user.profile.first_name} { selectedItem?.usertalent.user.profile.last_name}</p>
                                        </div>
                                        <div className="mt-2 text-center ">
                                            <h4 className="font-18" >{translate(translations,"Your vote saved. Thank you for your participation.")}</h4>
                                        </div>
                                    </>
                                }
                                {modal==="restrict" &&
                                    <>
                                        {selectedItem && 
                                            <div className="mt-2 text-center ">
                                                <p className="font-18" >{ selectedItem?.usertalent.user.profile.first_name} { selectedItem?.usertalent.user.profile.last_name}</p>
                                            </div>
                                        }
                                        <div className="mt-2 text-center ">
                                            <h4 className="font-18" >{translate(translations,"You already voted in this poll.")}</h4>
                                        </div>
                                    </>
                                }
                                
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light pull-right lan-float-left kfont" onClick={closeModal}>{translate(translations,'Close')}</button>
                            </div>
                        </div>
                    </div>
                </Modal>

            </div>
  )
}

export default Poll