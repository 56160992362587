import React from 'react'
import { Link } from 'react-router-dom'
import { getSelectedTheme } from '../../utils';


export default function PageHeader(props) {

    var theme = getSelectedTheme();
    
    return (
        <div>
            <section className="page-header section-with-shape-divider pb-0 page-header-modern page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-7 bg-signup" style={{ backgroundImage: "assets/images/signupheader.jpeg" }}>
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-md-12 align-self-center p-static order-2 text-center appear-animation" data-appear-animation="blurIn" data-appear-animation-delay="1000" data-plugin-options="{'minWindowWidth': 0}">
                            <h1 className="text-color-light font-weight-extra-bold text-12 line-height-1 mb-2 appear-animation" data-appear-animation="blurIn" data-appear-animation-delay="1000" data-plugin-options="{'minWindowWidth': 0}" >{props.title}</h1>
                            
                            <p className="text-4 mt-10 text-color-light font-weight-light opacity-7 mb-0" data-plugin-animated-letters data-plugin-options="{'startDelay': 2000, 'minWindowWidth': 0}">
                                
                            </p>
                        </div>
                        <div className="col-md-12 align-self-center order-1 hide">
                            <ul className="breadcrumb breadcrumb-light d-block text-center">
                                <li>
                                    <a href="">
                                        Home
                                    </a>
                                </li>
                                <li className="active">{ props.name }</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="shape-divider shape-divider-bottom" >
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 136" preserveAspectRatio="xMinYMin">
                        <path d="M 0 0.11 L 0 136 L 1920 136 L 1920 1.9 C 1650.0482 79.0955 1327.0648 134 980 134 C 615.6218 134 277.7878 84.5059 0 0.11 Z" fill={theme === "light" ? "#FFFFFF" : "#212529"} />
                    </svg>
                </div>
            </section>
        </div>
    )
}
