import React, { useEffect, useState } from 'react'
import { getDate, getDefaultName } from '../utils'
import { loadCity, loadPosts } from '../utils/Network'

export default function TalentPosts({ talent_id , featured=0 }) {

    const [posts, setPosts] = useState(null)
    useEffect(() => {
        if (talent_id) {

            loadPosts(talent_id,10,1,featured).then(posts=>{
                setPosts(posts)
            })

        }
    }, [])

    return (
        <>
                <ul className="list-unstyled mb-0 posts-list">
                    {posts && posts.map(post=>(
                        <li key={post.id} className="media mb-3 pb-1">
                            <article className="d-flex lan-rtl lan-full-width">
                                {/* <a href={`post/${post.id}`}>
                                    <img className="mr-3 img-fluid img-header-post" src={post.gallery_image ? post.gallery_image.image : "assets/images/default.png"} alt="" style={{ maxWidth: 70 }} />
                                </a> */}
                                <div className="media-body">
                                    <a href={`post/${post.id}`}>
                                        
                                        <h6 className="text-3 text-color-light opacity-8 ls-0 mb-1 lan-text-right lan-mr-5 kfont">
                                            <i className="fa fa-chevron-left opacity-5"></i> &nbsp;&nbsp;
                                            {post.title}
                                            </h6>
                                        {/* <p className="text-2 mb-0 lan-text-right lan-mr-5">{getDate(post.created_at)}</p> */}
                                    </a>
                                </div>
                            </article>
                        </li>
                    ))}
                    
                    
                </ul>
        </>
            )
}
