import { useStateValue } from 'StateProvider';
import TalentName from 'components/talent/TalentName';
import React from 'react'
import { getDate, getDefaultValue } from 'utils'

function PostItem({ post }) {

    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x=>x.key == str );
    
        if (translate) return getDefaultValue(translate);
        else return "#" + str;
      };

    return (
        <article className="post post-medium appear-animation animated fadeIn margin-right-10 lan-rtl" >
            <div className="row mb-3">
                <div className="col-lg-5 padding-0">
                    <div className="post-image">
                        <a href={`post/${post.id}`}>
                            <img src={post.gallery_image ? post.gallery_image.image : "/assets/images/default.png"} className="img-full-size height-200" alt={post.title} />
                        </a>
                    </div>
                </div>
                <div className="col-lg-7 ">
                    <div className="post-content padding-r-10">
                        <h2 className="font-weight-semibold pt-4 pt-lg-0 text-5 line-height-4 mb-2">
                            <a href={`post/${post.id}`} title={post.title}>
                                {post.title}
                            </a></h2>
                        <p className="mb-0 lan-rtl text-justify text-light-2">{post.short_description}</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="post-meta">
                        {/* <span><i className="far fa-calendar-alt"></i> {getDate(post.updated_at)} </span> */}
                        <span><i className="far fa-user"></i> By <a href="#" className="kfont">{translate('Kurdish Talent')}</a> </span>
                        <span><i className="far fa-folder"></i> <a href={`blog/${post.talent}`} className="kfont">{post.talent && <TalentName id={post.talent} />} </a></span>
                        <span className="d-block d-sm-inline-block float-sm-right mt-3 mt-sm-0"><a href={`post/${post.id}`} className="btn btn-xs btn-light text-1 text-uppercase kfont">{translate("Read More")}</a></span>
                    </div>
                </div>
            </div>
        </article>
    )
}

export default PostItem