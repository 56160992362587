import React, { useEffect, useRef, useState } from 'react'
import { useStateValue } from '../../StateProvider';
import { getDefaultValue } from '../../utils';
import Constants from '../../utils/Constants';
import { ContestHttpService } from '../../utils/http/contest_http_service';
import { addParticipantToCourse, loadProfile } from '../../utils/Network';
import ModalAcceptRules from '../dialogs/ModalAcceptRules';
import ModalCompleteProfile from '../dialogs/ModalCompleteProfile';
import ModalUnroll from '../dialogs/ModalUnroll';

function ParticipateButtonSingle({ course,...props }) {
    const [courseParticipant, setCourseParticipant] = useState(null)
    const [acceptRulesModalIsOpen, setAcceptRulesModalIsOpen] = useState(false);
    const [unrollModalIsOpen, setUnrollModalIsOpen] = useState(false);

    const [completeProfileModalIsOpen, setCompleteProfileModalIsOpen] = useState(false);
    const [loading, setLoading] = useState(null);
    const [error, setError] = useState(null);
    const [profile, setProfile] = useState(null);

    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x => x.key == str);

        if (translate) return getDefaultValue(translate);
        else return "#" + str;
    };

    useEffect(() => {
        _getData()

        return () => {

        }
    }, [])

    const _getData = async () => {
        var query = "?course=" + encodeURIComponent(course)
        ContestHttpService.loadCourseParticipant(query).then((data) => {
            if (data.results) {
                setCourseParticipant(data.results[0])
            }

        });

        _getProfile()
    }

    const _getProfile = () => {
        loadProfile().then((profile) => {
            setProfile(profile);
            setLoading(false);
        }
        )
    }

    const participate = () => {
        setLoading(translate("Please wait..."))
        let body = {
            "course": course
        }
        ContestHttpService.participate(body).then(data => {
            setError("")
            setLoading(false)
            if (data.error) {
                // window.location.reload()
                setError(translate("You already participated in this competition"))

            }
            else if (data.Error) {
                setError(translate("You cannot participate in this course again"))
            }
            else {
                setCourseParticipant(data)
                if(props.onSuccess){ props.onSuccess(data) }
            }

        })
    }

    const _handleClick = () => {
        if (profile && !profile.country_id) {
            setCompleteProfileModalIsOpen(true)
        } else {
            if (courseParticipant) {
                // unroll(course, courseParticipant)
                setUnrollModalIsOpen(true)

            }
            else {
                setAcceptRulesModalIsOpen(true)
            }
        }
    }



    return (
        <div>
            <button type="button"
                onClick={_handleClick}
                data-toggle={"modal"}
                data-target={courseParticipant ? "#unrollModal" : "#rulesmodal"}
                className="btn btn-primary  btn-block font-weight-bold width-200 text-3 my-10  py-10"
            >
                {courseParticipant &&
                    <>
                        <i className="fas fa-user-minus op-7"></i>&nbsp;&nbsp;
                        {translate('Unroll')}
                    </>
                }
                {!courseParticipant &&
                    <>
                        <i className="fas fa-user-plus op-7"></i>&nbsp;
                        {translate('Participate')}
                    </>
                }
            </button>
            {error &&
                <>
                    {translate(error)}
                </>
            }
            <ModalAcceptRules isOpen={acceptRulesModalIsOpen} setIsOpen={setAcceptRulesModalIsOpen} onAccept={participate} />
            <ModalCompleteProfile isOpen={completeProfileModalIsOpen} message={Constants.ContestCompleteYourProfileMessage} setIsOpen={setCompleteProfileModalIsOpen} onSuccess={() => { setAcceptRulesModalIsOpen(true) }} />
            <ModalUnroll course={course} isOpen={unrollModalIsOpen} setIsOpen={setUnrollModalIsOpen} courseParticipant={courseParticipant} onSuccess={() => { setCourseParticipant(null); setUnrollModalIsOpen(false); }} />
            {loading &&
                <div className="col-md-12 file-container flex-center my-2">
                    <>
                        <div className="bounce-loader">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                        </div>
                    </>

                </div>
            }
        </div>
    )
}

export default ParticipateButtonSingle