import React, { useEffect, useState } from "react";
import { getDefaultName, days_between, getCurrentLanguage } from "../../utils";
import { loadCountry, loadTalent } from "../../utils/Network";

export default function CompetitionMenu({
  id,
  enddate,
  country_id,
}) {
  var days_left = days_between(new Date(), new Date(enddate));
  const [talent, setTalent] = useState(null);
  const [country, setCountry] = useState(null);

  const [current_language, setCurrentLanguage] = useState(null);
  useEffect(() => {
    setCurrentLanguage(getCurrentLanguage());

    if (id) {
      loadTalent(id).then((talent) => {
        setTalent(talent);
      });
    }

    if (country_id) {
      loadCountry(country_id).then((res) => {
        setCountry(res);
      });
    }
  }, []);

  return (
    <>
      <ul className="title-icons">
        {country && (
          <li
            title={`only for contestants from ${getDefaultName(country.names)}`}
          >
            <i className="fa fa-map-marker"></i> &nbsp;
            {getDefaultName(country.names)}
          </li>
        )}
        <li>
          <i className="fa fa-music"></i> &nbsp;
          {talent && getDefaultName(talent.names)}
        </li>
        <li title={`Ends at ${enddate}`}>
          <i className="fa fa-hourglass"></i> &nbsp;
          {current_language === "so" && <>{days_left} رۆژ تا کۆتایی</>}
          {current_language !== "so" && (
            <>
              {days_left} day{days_left > 1 ? "s" : null} left
            </>
          )}
        </li>
      </ul>
    </>
  );
}
