import React, { useState } from "react";
import Modal from "react-modal";
import { useStateValue } from "../../StateProvider";
import { getDefaultDescription, getDefaultName, getDefaultValue } from "../../utils";
import parse from 'html-react-parser'
import CompetitionMenu from "../contest/CompetitionMenu";

export default function ModalContestView({ isOpen = false, setIsOpen, contest }) {

  const closeModal = () => {
    setIsOpen(false);
  };


  const [{ translations }] = useStateValue();
  const translate = (str) => {
    if (!translations) return str;
    var translate = translations.find((x) => x.key == str);

    if (translate) return getDefaultValue(translate);
    else return "#" + str;
  };

  return (
    <Modal
      closeTimeoutMS={500}
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
    >
      {contest && (
        <div className="modal-content">
          <div className="modal-header no-border ">
            {/* <h4 className="modal-title lan-text-right lan-rtl" id="defaultModalLabel">{contest && getDefaultName(contest.names)}</h4> */}
            <button type="button" className="close lan-float-left lan-ml-0" onClick={closeModal}>&times;</button>
          </div>
          <div className="modal-body lan-text-right no-padding">
            <div className='flex-center relative '>

              <div className="flex-space-between">
                <h2 className="mb-2">{getDefaultName(contest.names)}</h2>
                <CompetitionMenu id={contest.talent} enddate={contest.enddate}/>
              </div>
              <img src={contest.image} className="contest-image-view " alt="" />

              <div className="mt-2 full-width">
                {getDefaultDescription(contest.names)}
              </div>
              {/* <div className="mt-2 d-flex justify-content-center">
                        {contest && <ParticipateButtonSingle course={contest.id} /> }
                    </div> */}

            </div>
          </div>

        </div>
      )}

    </Modal>
  );
}
