import React from 'react'
import FeedBody from '../components/main/FeedBody';
import FeedHeader from '../components/shared/FeedHeader';

function FeedPage() {

  return (
    <div id="main" role="main" className="main">
      <FeedHeader />
      <FeedBody />
    </div>
  )
}

export default FeedPage