import React, { useState } from "react";
import Modal from "react-modal";
import { useStateValue } from "../../StateProvider";
import { getDefaultValue } from "../../utils";
import parse from 'html-react-parser'

export default function ModalPopupMessage({popupMessage,isOpen = false , setIsOpen }) {

  const closeModal = () => {
    setIsOpen(false);
  };

  
  const [{ translations }] = useStateValue();
  const translate = (str) => {
    if (!translations) return str;
    var translate = translations.find((x) => x.key == str);

    if (translate) return getDefaultValue(translate);
    else return "#" + str;
  };

  return (
    <Modal
      closeTimeoutMS={500}
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="Modal modal-xlg"
      overlayClassName="transparent-overlay"
    >
      <div className="modal-content">
        <div className="modal-body padding-0">
          <button type="button" className="modal-body-close2" onClick={closeModal}>&times;</button>
          <a href={popupMessage && popupMessage.url}>
          <img className="img-fluid"  alt="" 
            src={popupMessage && popupMessage.image} />
            </a>
        </div>
        
      </div>
    </Modal>
  );
}
