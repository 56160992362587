import React, { useEffect, useState } from 'react'
import { loadTalents } from '../utils/Network';
import { BuildTalents, getCurrentLanguageId, getDefaultName, getDefaultValue, getSelectedTheme } from '../utils';
import { useStateValue } from '../StateProvider';

import Modal from 'react-modal';

export default function SelectTalentsDialog({ onValue , placeholder }) {

    const [talents, setTalents] = useState([]);
    // Select    

    const [selected_talent, setSelectedTalent] = useState(null);
    const [show_menu, setShowingMenu] = useState(null);
    const [child_show_menu, setChildShowingMenu] = useState(null);

    const [modalIsOpen, setModalIsOpen] = useState(false)

    var theme = getSelectedTheme();
    
    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x=>x.key == str );
    
        if (translate) return getDefaultValue(translate);
        else return "#" + str;
      };

    useEffect(() => {

        let language_id = getCurrentLanguageId()

        loadTalents(language_id).then(talents => {
            setTalents(BuildTalents(talents,1))
        })
    }, [])

    const handleSelect = (talent) => {
        setSelectedTalent(talent);
        onValue("talent", talent.id)
        closeModal()
    }

    
    const closeModal = () => {
        setModalIsOpen(false)
    }

    const openModal = (e) => {
        e.preventDefault()
        setModalIsOpen(true)
    }

    const openMenu = (e, talent) => {
        e.preventDefault()
        if (talent.childs.length > 0) {
            
            setShowingMenu(talent.id)
            if (talent.id === show_menu)
                setShowingMenu(null)
        }
        else{
            handleSelect(talent)
        }
    }

    const openChildMenu = (e, talent) => {
        e.preventDefault()
        if (talent.childs.length > 0) {
           
            setChildShowingMenu(talent.id)
            if (talent.id === child_show_menu)
                setChildShowingMenu(null)
        }
        else{
            handleSelect(talent)
        }
    }


    return (
        <>
            <div onClick={openModal} className="pill">
                {selected_talent ? getDefaultName(selected_talent.names) : translate("Select a Talent")}
                <i className="fa fa-chevron-down"></i>
            </div>
            <Modal
                closeTimeoutMS={500}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="Modal Modal-login"
                ariaHideApp={false}
                overlayClassName="Overlay"
            >
                <div className="modal-content">
                    <div className="modal-header lan-rtl">
                        <h4 className="modal-title" id="defaultModalLabel">{translate('Select Talent')}</h4>
                        <button type="button" className="close lan-ml-0" onClick={closeModal}>&times;</button>
                    </div>
                    <div className="modal-body">
                        
                    <ul className="nav nav-list nav-list-rtl flex-column mb-5 lan-rtl lan-padding-r-5">
                            {talents && talents.map(talent => (
                                <li key={talent.id} className="nav-item lan-text-right">
                                    <a href="#talent" className="nav-link" onClick={(e) => { openMenu(e, talent) }}>{getDefaultName(talent.names)} </a>
                                    {talent.childs &&
                                        <ul className={show_menu == talent.id ? "show lan-rtl" : "hide lan-rtl"}>
                                            {talent.childs.map(child => (
                                                <li key={child.id} className="nav-item lan-text-right">
                                                    <a href="#talent" className="nav-link" onClick={(e) => { openChildMenu(e, child) }}>{getDefaultName(child.names)}</a>
                                                    {child.childs &&
                                                        <ul className={child_show_menu == child.id ? "show" : "hide"}>
                                                            {child.childs.map(grand_child => (
                                                                <li key={grand_child.id} className="nav-item lan-text-right">
                                                                    <a className="nav-link" href="#talent" onClick={(e) => {e.preventDefault(); handleSelect(grand_child) }}>{getDefaultName(grand_child.names)} </a>

                                                                </li>

                                                            ))}

                                                        </ul>
                                                    }
                                                </li>

                                            ))}

                                        </ul>
                                    }
                                </li>
                            ))}

                        </ul>
                    </div>
                   
                </div>
            </Modal>
        </>
    )

}
