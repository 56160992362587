import { useStateValue } from 'StateProvider';
import React from 'react'
import { getDefaultValue } from 'utils';

function MapIntro() {

    const [{ translations }] = useStateValue();

    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x => x.key == str);

        if (translate) return getDefaultValue(translate);
        else return "#" + str;
    };


    return (
        <div className='container mt-3'>
            {/* <h4 className='text-center mb-1 kfont'>{translate("Season 1 - 2022")}</h4> */}
            {/* <hr className="solid mt-3 lan-float-right full-width" /> */}
            <div className="row counters with-borders ">
                <div className="col-12 col-sm-6 col-lg-3 mb-4 mb-lg-0 animated fadeInUpShorter"
                        style={{ animationDelay: `1s`}}
                        data-appear-animation-duration="0.2s">
                    <div className="counter counter-primary appear-animation animated bounceIn appear-animation-visible" data-appear-animation="bounceIn" data-appear-animation-delay="300" style={{ animationDelay: "300ms" }}>
                        <i className="fas fa-user"></i>
                        <strong className='text-light' data-to="1200" data-append="+">1,200+</strong>
                        <label className='kfont text-light-2'>{translate("Participants")}</label>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 mb-4 mb-lg-0 animated fadeInUpShorter"
                        style={{ animationDelay: `1.5s`}}
                        data-appear-animation-duration="0.2s">
                    <div className="counter counter-primary appear-animation animated bounceIn appear-animation-visible" data-appear-animation="bounceIn" data-appear-animation-delay="600" style={{ animationDelay: "600ms" }}>
                        <i className="fas fa-city "></i>
                        <strong className='text-light' data-to="69">69</strong>
                        <label className='kfont text-light-2'>{translate("Cities")}</label>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 mb-4 mb-sm-0 animated fadeInUpShorter"
                        style={{ animationDelay: `2s`}}
                        data-appear-animation-duration="0.2s">
                    <div className="counter  counter-primary appear-animation animated bounceIn appear-animation-visible" data-appear-animation="bounceIn" data-appear-animation-delay="900" style={{ animationDelay: "900ms" }}>
                        <i className="fa fa-gavel"></i>
                        <strong className='text-light' data-to="10">10</strong>
                        <label className='kfont text-light-2'>{translate("Referees")}</label>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 animated fadeInUpShorter"
                        style={{ animationDelay: `2.5s`}}
                        data-appear-animation-duration="0.2s">
                    <div className="counter counter-primary appear-animation animated bounceIn appear-animation-visible" data-appear-animation="bounceIn" data-appear-animation-delay="1200" style={{ animationDelay: "1200ms" }}>
                        <i className="far fa-chart-bar"></i>
                        <strong className='text-light' data-to="182000" data-append="+">182,521+</strong>
                        <label className='kfont text-light-2'>{translate("Public Votes")}</label>
                    </div>
                </div>
            </div>
            <section className="section section-custom-map appear-animation lazyload map-intro"
                data-appear-animation="fadeInUpShorter" data-src-bg="img/map.png" style={{  backgroundColor: "transparent", backgroundPosition: "center 0", backgroundRepeat: "no-repeat", backgroundSize:"contain" }}>

            </section>
        </div>

    )
}

export default MapIntro