import TalentName from 'components/talent/TalentName'
import React from 'react'

function BlogStyle1({ posts }) {
    return (
        <>
            {(posts && posts.length > 0) && (
                <div className="row pb-1 border-bottom-2 lan-rtl">

                    <div className="col-lg-7 mb-4  pb-2 lan-rtl">
                        {(posts && posts.length > 0) && (
                            <PostStyle1 post={posts[0]} />
                        )}
                    </div>

                    <div className="col-lg-5 lan-rtl">

                        {(posts && posts.length > 0) && posts.map((post, index) => {
                            if (index > 0 && index < 4)
                                return (
                                    <PostStyle2 post={post} border_bottom={index<3} />
                                )
                        }
                        )}


                    </div>
                </div>
            )}
        </>
    )
}

function PostStyle2({ post , border_bottom= true }) {
    return (
        <article className={`thumb-info thumb-info-no-zoom bg-transparent ${border_bottom ? "border-bottom-2" : ""} border-radius-0 pt-2 pb-2 mb-2`}>
            <div className="row align-items-center pb-1">
                <div className="col-5">
                    <a href={`post/${post.id}`}>
                        <img src={post.gallery_image ? post.gallery_image.image : "/assets/images/default.png"} className="img-fluid border-radius-0" alt={post.title} />
                    </a>
                </div>
                <div className="col-7 ps-sm-1">
                    <div className="thumb-info-caption-text lan-text-right">
                        <div className="thumb-info-type text-light text-uppercase d-inline-block bg-black px-2 m-0 mb-1 float-none">
                            <a href={`post/${post.id}`} className="text-decoration-none text-color-light kfont">{post.talent && <TalentName id={post.talent} />}</a>
                        </div>
                        <h2 className="d-block line-height-2 text-4 text-dark font-weight-bold mt-1 mb-0">
                            <a href={`post/${post.id}`} className="text-decoration-none text-light text-color-hover-primary">{post.title}</a>
                        </h2>
                    </div>
                </div>
            </div>
        </article>
    )
}


function PostStyle1({ post }) {
    return (
        <a href={`post/${post.id}`}>
            <article className="thumb-info thumb-info-no-borders thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom border-radius-0">
                <div className="thumb-info-wrapper thumb-info-wrapper-opacity-6">
                    <img src={post.gallery_image ? post.gallery_image.image : "/assets/images/default.png"} className="img-fluid" alt={post.title} />
                    <div className="thumb-info-title title-linear-bg p-4 text-left lan-text-right kfont">
                        <div className="thumb-info-type bg-color-dark px-2 mb-1 lan-text-right">{post.talent && <TalentName id={post.talent} />}</div>
                        <div className="thumb-info-inner mt-1 text-left lan-text-right kfont ">
                            <h2 className="font-weight-bold text-color-light line-height-2 text-5 mb-0 ">{post.title}</h2>
                        </div>
                        <div className="thumb-info-show-more-content text-left lan-text-right kfont">
                            <p className="mb-0 text-1 line-height-9 mb-1 mt-2 text-light opacity-5">{post.short_description}</p>
                        </div>
                    </div>
                </div>
            </article>
        </a>
    )
}


export default BlogStyle1