import React from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  components: {
    MuiPickersDay: {
      styleOverrides: {
        day: {
          borderRadius: '50%',
        },
      },
    },
    MuiPickersBasePicker: {
      styleOverrides: {
        container: {
          borderRadius: '35px',
        },
      },
    },
  },
});

function SelectDate({ value, onDatePicked , name="birth_date", label="Birth date" }) {

    const handleChange = (value) => {
        var gregorian_date = value.format('YYYY-MM-DD');
        onDatePicked(name,gregorian_date);
      };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={darkTheme}>
              <DesktopDatePicker
                  label={label}
                  inputFormat="YYYY-MM-DD"
                  value={value}
                  onChange={handleChange}
                  className="full-width rounded-control"
                  renderInput={(params) => <TextField {...params} />}
              />
            </ThemeProvider>
        </LocalizationProvider>
    );
}

export default SelectDate;
