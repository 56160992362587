export const initialState = {
  user: null,
  language_data: [],
  language: "en",
};

const reducer = (state, action) => {
  //console.log(action);
  switch (action.type) {
    case "SET_LANGUAGE_DATA":
      return {
        ...state,
        language_data: action.language_data,
      };
    case "SET_TRANSLATIONS":
      return {
        ...state,
        translations: action.translations,
      };
    case "SET_CURRENT_LANGUAGE":
      return {
        ...state,
        language: action.language,
      };
    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };
    default:
      return state;
  }
};

export default reducer;
