import React, { useRef, useState } from 'react'
import { useStateValue } from '../../StateProvider';
import { getDefaultName, getDefaultValue, getUser, setUserSession } from '../../utils';
import { deleteCourseParticipant, doLogin } from '../../utils/Network';

function ModalUnroll({ course, courseParticipant, onSuccess }) {
    const [loading, setLoading] = useState(null)
    const [error, setError] = useState(null)
    const [modal_password, setModalPassword] = useState("");

    const btnCloseModal = useRef(null);
    const CurrentUser = getUser()

    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x => x.key == str);

        if (translate) return getDefaultValue(translate);
        else return "#" + str;
    };

    const handleModalOnChange = (e) => {
        //e.preventDefault();
        switch (e.target.name) {
            case "modal_password":
                setError(false)
                setLoading(false)
                setModalPassword(e.target.value)
                break;
            default:
                break;
        }
    }




    const unRoll = () => {
        setError(false)
        if (loading) return;


        if (modal_password.length < 6) {
            setError(translate("Please enter your password"))
            setLoading(false)
            return;
        }
        else {
            setLoading(true)
            doLogin({ email: CurrentUser.email, password: modal_password }).then(data => {
                if (data.tokens) {

                    setUserSession(data.tokens.access, data);

                    if (courseParticipant) {
                        deleteCourseParticipant(courseParticipant?.id).then(data => {
                            setLoading(false)
                            btnCloseModal.current.click()
                            onSuccess()
                        })
                    }
                }
                else {
                    setError(data.detail);
                }
            });
        }
    }

    return (
        <>
            <div
                className="modal fade "
                id="unrollModal"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true"
            >
                <div className="modal-dialog lan-rtl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="defaultModalLabel">
                                {translate("Unroll from")}{" "}
                                {getDefaultName(course.names)}
                            </h4>
                            <button
                                type="button"
                                ref={btnCloseModal}
                                className="close lan-float-left lan-ml-0"
                                data-dismiss="modal"
                                aria-hidden="true"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body ">
                            <p className="text-color-primary lan-text-right">
                                {translate("to confirm , Please enter your password")}
                            </p>
                            <div className="form-group row">
                                <div className="col-lg-8">
                                    <div className="input-group">
                                        <div className="input-group-prepend ">
                                            <span
                                                className="input-group-text lan-border-radius-r"
                                                id="inputGroup-sizing-default"
                                            >
                                                <i className="fa fa-lock"></i>
                                            </span>
                                        </div>
                                        <input
                                            type="password"
                                            name="modal_password"
                                            onChange={handleModalOnChange}
                                            placeholder="Password"
                                            className="form-control lan-ltr border-radius-25 lan-border-radius-l text-3 h-auto py-2"
                                        />
                                    </div>
                                </div>
                                <div className="form-group col-lg-3 lan-padding-r-0">
                                    <input
                                        type="button"
                                        value={translate("Unroll")}
                                        onClick={unRoll}
                                        className="btn rounded-35 btn-rash btn-modern float-right"
                                    />
                                </div>
                            </div>
                            {error && (
                                <div className="alert alert-primary">{error}</div>
                            )}
                            {loading && (
                                <div className="col-md-12 file-container flex-center my-2">
                                    <>
                                        <div className="bounce-loader">
                                            <div className="bounce1"></div>
                                            <div className="bounce2"></div>
                                            <div className="bounce3"></div>
                                        </div>
                                    </>

                                </div>
                            )}
                            <div className="form-group row"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModalUnroll