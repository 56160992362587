import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { ApiHttpService } from 'utils/http/api';

export default function VideoGridController(categoryId) {

  const [userGalleries, setUserGalleries] = useState(null);


    const { isLoading, data, refetch } = useQuery(['userGalleries',categoryId], () =>
        ApiHttpService.loadUserGalleries({categoryId:categoryId}),
        {
            onSuccess: (data) => {
                if (data.results) {
                    setUserGalleries(data.results)

                }
            },
        }
    );
 


  return {
    isLoading,
    userGalleries,
    setUserGalleries,
  };
}
