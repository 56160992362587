import React from 'react'
import  '../Signin.css';
import ForgotPanel from '../../components/user/ForgotPanel';
import PageHeader from '../../components/shared/PageHeader';

export default function Forgot(props) {

   
    return (
        <div>
            <PageHeader title="Forgot Password"  />
            <ForgotPanel />
        </div>
    )
}
