import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { StateProvider } from './StateProvider';
import reducer, { initialState } from "./reducer";
import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
      <QueryClientProvider client={queryClient}>

    <StateProvider initialState={initialState} reducer={reducer}>
      <App />
    </StateProvider>
    </QueryClientProvider>

  </React.StrictMode>,
  document.getElementById("root")
);

