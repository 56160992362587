import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { ApiHttpService } from 'utils/http/api';

export default function VideoCarouselController() {

  const [userGalleries, setUserGalleries] = useState(null);
  const [loading, setLoading] = useState(false);


    const { isLoading, data, refetch } = useQuery(['userGalleries'], () =>
        ApiHttpService.loadUserGalleries({is_featured: 1}),
        {
            onSuccess: (data) => {
                if (data.results) {
                    setUserGalleries(data.results)

                }
            },
        }
    );
 


  return {
    loading,
    userGalleries,
    setUserGalleries,
  };
}
