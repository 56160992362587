import CourseParticipantsTopTenCircle from 'components/contest/CourseParticipantsTopTenCircle'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { ApiHttpService } from 'utils/http/api'
import HomeCourseParticipantsTopTenCircle from './HomeCourseParticipantsTopTenCircle'
import Slider from 'react-slick'

function HomeParticipantsTopTen({ id = 13 }) {

    const [loading, setLoading] = useState(false)
    const [course_history, setCourseHistory] = useState(null)
    const [participants, setParticipants] = useState(null)


    useEffect(() => {
        _getData()
    }, []);

    const _getData = () => {
        setLoading(true)
        ApiHttpService.loadCourseHistory(id).then(data => {
            if (data.id) {
                setCourseHistory(data)
                var ps = data.coursehistorytalents.filter(item => item.topten)
                setParticipants(ps)
            }
            setLoading(false)
        })

    }

    var settings = {
        dots: false,
        autoplay: true,
        speed: 2000,
        slidesToShow: 6,
        slidesToScroll: 6,
        arrows: false,
        centerMode: true,
        responsive: [
            {
              breakpoint: 1200,
              settings: {
                  slidesToShow: 5,
                  slidesToScroll: 5,
              }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows: false                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows: false
                }
            }
        ]
    };



    return (
        <Slider className='mb-4'   {...settings}>
            {participants && participants.map((item, index) => (
                <div className="portfolio-item " key={"ps"+item.id}>
                    <span className="thumb-info thumb-info-centered-icons circle-topten ">
                        <a href={`/${item.courseparticipant?.usertalent?.user.profile?.url ? item.courseparticipant?.usertalent?.user.profile.url : 'people/' + item.courseparticipant?.usertalent?.user.profile.id}`} className="thumb-info-wrapper circle-border circle-topten ">
                            {item.courseparticipant.usertalent.user.profile.thumb &&
                                <img src={item.courseparticipant.usertalent.user.profile.thumb} className="document-user-img" alt={item.courseparticipant.usertalent.user.profile.first_name} />
                            }
                            <span className="circle-artist-name ">
                                {item.courseparticipant.usertalent.user.profile.first_name}
                            </span>
                        </a>
                    </span>
                </div>

            ))}
        </Slider>
    )
}

export default HomeParticipantsTopTen