import React from 'react'

function ExplorePostHeader({post}) {
    console.log(post)
  return (
    <div className="feed-post-header">
        <img className="rounded-circle user-logo" width="40" height="40" alt="" src={ post.user.profile ? "https://kurdishtalent.com"+post.user.profile.avatar : "https://kurdishtalent.com/assets/images/user.png"}></img>
        <h2>{post.user.profile.first_name} {post.user.profile.last_name}</h2>
    </div>
  )
}

export default ExplorePostHeader