import React, { useRef, useState } from 'react'
import { useStateValue } from '../../StateProvider';
import { getDefaultValue } from '../../utils';
import { saveSupportMessage } from '../../utils/Network';

function HelpModal() {

    const btnCloseModal = useRef(null);
    
    const [error, setError] = useState(false);
    const [done, setDone] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    

    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x=>x.key == str );
    
        if (translate) return getDefaultValue(translate);
        else return "#" + str;
      };


    const handleChange = (e) => {

        switch (e.target.name) {
            case "title":
                setTitle(e.target.value);
                break;
            case "message":
                setMessage(e.target.value);
                break;

            default: break;
        }
    }

    const saveChanges = () => {

        setLoading(true)
        setError(false)

        if (message.length<10) {
            setError(translate("Please Enter Message"))
            setLoading(false)
            return
        }

        saveSupportMessage(title,message).then(data => {
            setLoading(false)
            setError(false)
            setDone(translate('Message saved Successfully. we will be in touch as soon as possible.'))
        });

    }

    return (
        <div className="modal fade lan-rtl" id="helpModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="defaultModalLabel">{translate('Help')}</h4>
                            <button type="button" ref={btnCloseModal} className="close lan-float-left lan-ml-0" data-dismiss="modal" aria-hidden="true">&times;</button>

                        </div>
                        <div className="modal-body w-500">
                            <div className="form-row">
                                <div className="form-group col-lg-6">
                                    <input type="text" onChange={handleChange} className="form-control text-3 h-auto py-2" name="title"  placeholder={translate('Subject')} />
                                </div>
                                
                            </div>
                            <div className="form-row">
                                <div className="form-group col-lg-12">
                                    <textarea onChange={handleChange}  className="form-control text-3 h-auto py-2" placeholder={translate("Message")} name="message" ></textarea>
                                </div>
                                
                            </div>
                            <div className="form-row">
                                <div className="form-group col-lg-12">
                                <button type="submit" onClick={saveChanges} className="btn  btn-primary  btn-block text-uppercase rounded-35 font-weight-bold text-3 py-10" value={loading ? 'Loading...' : translate('Send')} disabled={loading} data-loading-text="Loading...">
                                        <i className="fas fa-envelope  op-7"></i>&nbsp;
                                        {translate('SEND')}
                                    </button>
                                </div>
                                
                            </div>
                            
                            {error &&
                                <div className="alert alert-primary lan-rtl kfont lan-text-right">
                                    {error}
                                </div>
                            }
                            {loading &&
                                <div className="alert alert-primary kfont lan-text-right">
                                    {translate("please wait...")}
                                </div>
                            }

                            {done &&
                                <div className="alert alert-primary lan-rtl kfont lan-text-right">
                                    {done}
                                </div>
                            }
                            
                        </div>

                    </div>
                </div>
            </div>
    )
}

export default HelpModal
