import React from 'react'
import FeedPostHeader from './FeedPostHeader'
import { getYoutubeId, isYoutube } from 'utils'

function FeedPost({ post }) {
  return (
    <div className='feed-post'>
      <FeedPostHeader post={post} />
      <div className="feed-post-body full-height">
        <div className="portfolio-item full-height">
          <span className={`thumb-info  thumb-info-lighten thumb-info-no-borders border-radius-0 full-height`}>
            <span className="thumb-info-wrapper border-radius-0 full-height">
              {post && isYoutube(post.url) && (
                <img
                  src={`https://img.youtube.com/vi/${getYoutubeId(
                    post.url
                  )}/hqdefault.jpg`}
                  className="img-full-size"
                  alt=""
                />
              )}


            </span>
            <span className="infoicon font-size-40">
              <i className="fab fa-youtube"></i>
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default FeedPost