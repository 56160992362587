import React from 'react'
import { useState } from 'react';
import { isYoutube , getYoutubeId,translate, userAvatar } from 'utils'
import UrlEmbed from 'components/UrlEmbed';
import Modal from 'react-modal';
import { useStateValue } from 'StateProvider';

function UserGallery({data}) {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modal_loading, setModalLoading] = useState(null);
    const [modal_url, setModalUrl] = useState(null);
    const [{ translations }] = useStateValue();


    const PrepareModal = (UserGallery) => {
        setModalLoading(true)
        setModalUrl(UserGallery.url)
        setModalIsOpen(true)
        setModalLoading(false)
    }

    const closeModal = () => {
        setModalIsOpen(false)
    }

    return (
        <div className="row kfont lan-rtl mt-3">
            {data?.usergallery?.map(item => (
                <div key={item.id} onClick={() => { PrepareModal(item) }} className="col-6 col-sm-6 col-md-4 col-lg-4 mb-2  padding-x-5 ">
                    <div className="portfolio-item">
                        <span className={`thumb-info  thumb-info-lighten thumb-info-no-borders border-radius-0 ${document && document.selected && "selected"} `}>
                            <span className="thumb-info-wrapper upload-icon-container border-radius-0 ">
                                {item && isYoutube(item.url) && (
                                    <img
                                        src={`https://img.youtube.com/vi/${getYoutubeId(
                                            item.url
                                        )}/0.jpg`}
                                        className="img-fluid"
                                        alt=""
                                    />
                                )}

                            </span>
                            <span className="infoicon font-size-40">
                                <i className="fab fa-youtube"></i>
                            </span>
                        </span>
                    </div>
                </div>
            ))}

            <Modal
                closeTimeoutMS={500}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="Modal "
                ariaHideApp={false}
                overlayClassName="Overlay"
            >
                <div className="modal-content">
                    <div className="modal-header height-75 lan-rtl">
                        <img className="rounded-circle user-logo" width="40" height="40" alt="" src={userAvatar(data)}></img>
                        <h4 className="modal-title artist-name-rate lan-mr-5">{data && data?.first_name}</h4>

                        <button type="button" className="close" onClick={closeModal}></button>
                    </div>
                    <div className="modal-body row">

                        <div className="col-md-12 file-container flex-center">
                            {modal_loading &&
                                <>
                                    <div className="bounce-loader">
                                        <div className="bounce1"></div>
                                        <div className="bounce2"></div>
                                        <div className="bounce3"></div>
                                    </div>
                                </>
                            }
                            {modal_url && <UrlEmbed url={modal_url} />}

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light pull-right kfont rtl-pull-left" onClick={closeModal}>{translate(translations, 'Close')}</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default UserGallery