import React from 'react'
import InfiniteScroll from 'react-infinite-scroller';
import FeedPost from './FeedPost';
import { useExplorePosts } from 'features/feature_feed/hooks';

function FeedBody() {

    const  { data, isLoading, isError, hasNextPage, fetchNextPage } = useExplorePosts();

    return (
        <div className="container container-feed pb-2">
            <InfiniteScroll
                hasMore={hasNextPage}
                loadMore={fetchNextPage}
            >
                <>
                {data?.pages.map((page) =>
                    page.response.most_viewed_user_gallery.map((post) => <FeedPost key={post.id} post={post} />)
                )}
                </>

            </InfiniteScroll>

        </div>
    )
}

export default FeedBody