import React, { useEffect, useState } from 'react'
import { getDefaultValue, setUserSession, sortByKey } from '../../utils';
import { ApiBase, resendVerificationEmail, doLogin, loadCountries } from '../../utils/Network';
import validator from 'validator'
import { useStateValue } from '../../StateProvider';
import SelectTalentsDialog from '../../pages/SelectTalentsDialog';

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);


    const handleChange = e => {
        setValue(e.target.value);
    }
    return {
        value,
        onChange: handleChange
    }
}


export default function RegisterAndLoginForm() {

    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x => x.key == str);

        if (translate) return getDefaultValue(translate);
        else return "#" + str;
    };

    const username = useFormInput('');
    const password = useFormInput('');

    const register_username = useFormInput('');
    const register_password = useFormInput('');
    const register_name = useFormInput('');
    const register_surename = useFormInput('');
    const register_phone = useFormInput('');

    const [birthdate, setBirthdate] = useState([]);
    const [gender, setGender] = useState(null);
    const [register_user_info, setRegisterUserInfo] = useState([]);

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const [register_error, setRegisterError] = useState(null);
    const [register_loading, setRegisterLoading] = useState(false);
    const [register_done, setRegisterDone] = useState(false);
    const [register_email_sent, setRegisterEmailSent] = useState(false);
    const [email_sent, setEmailSent] = useState(false);
    const [login_email_not_verified, setLoginEmailNotVerified] = useState(false);
    const [countries, setCountries] = useState(null);
    const [birthCities, setBirthCities] = useState(null);
    const [reesidenceCities, setResidenceCities] = useState(null);
    const [talents, setTalents] = useState(null);

    const [birth_country, setBirthCountry] = useState(null);
    const [birth_city, setBirthCity] = useState(null);

    const [living_country, setLivingCountry] = useState(null);
    const [living_city, setLivingCity] = useState(null);

    const [year, setYear] = useState(null);
    const [month, setMonth] = useState(null);
    const [day, setDay] = useState(null);




    const months = new Array(11);
    months[0] = "Jan";
    months[1] = "Feb";
    months[2] = "Mar";
    months[3] = "Apr";
    months[4] = "May";
    months[5] = "Jun";
    months[6] = "Jul";
    months[7] = "Aug";
    months[8] = "Sep";
    months[9] = "Oct";
    months[10] = "Nov";
    months[11] = "Dec";
    const days_array = range(1, 31);

    useEffect(() => {
        loadCountries(1, 10000).then(countries => {
            if (countries) {
                sortByKey(countries)
                setCountries(countries);
            }

        });


    }, [])

    function range(start, end) {
        var foo = [];
        for (var i = start; i <= end; i++) {
            foo.push(i);
        }
        return foo;
    }

    const onValue = (name, value) => {
        var tu = register_user_info;
        switch (name) {
            case "year":
                var sd = birthdate;
                sd.year = value;
                setYear(value)
                setBirthdate(sd);
                break;
            case "month":
                var sd = birthdate;
                sd.month = value;
                setMonth(value)
                setBirthdate(sd);
                break;
            case "day":
                var sd = birthdate;
                sd.day = value;
                setDay(value)
                setBirthdate(sd);
                break;
            case "gender":
                setGender(value)
                break;
            case "talent":
                tu.talent = value;
                setRegisterUserInfo(tu)
                break;
            case "birth_country":
                tu.country_id = value;
                setBirthCountry(value)
                setRegisterUserInfo(tu)
                break;
            case "birth_city":
                tu.city_id = value;
                setRegisterUserInfo(tu)
                setBirthCity(value)
                break;
            case "living_country":
                tu.livingcountry_id = value;
                setLivingCountry(value)
                setRegisterUserInfo(tu)
                break;
            case "living_city":
                tu.livingcity_id = value;
                setLivingCity(value)
                setRegisterUserInfo(tu)
                break;
            default: break;
        }
    }



    const handleLogin = (e) => {
        e.preventDefault()
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        setError(null);
        setLoading(true);
        setEmailSent(false);
        setLoginEmailNotVerified(false)

        if (!validator.isEmail(username.value)) {
            setError('Email address is not valid');
            setLoading(false);
            return;
        }

        if (!validator.isLength(password.value, { min: 6 })) {
            setError('Password is not valid');
            setLoading(false);
            return;
        }


        doLogin({ email: username.value, password: password.value }).then((data) => {
            setLoading(false)
            if (data.tokens) {
                setUserSession(data.tokens.access, data);

                if (data.role === "referee")
                    window.location.href = "referee"
                else if (data.role === "participant")
                    window.location.href = "dashboard"
                else if (data.role === "normal")
                    window.location.href = "talent"
            }
            else {
                setError(data.detail);

                if (data.detail && data.detail.includes("Email")) {
                    setLoginEmailNotVerified(true)
                }
            }
        })

    }

    const handleRegister = () => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Origin', 'http://localhost:3001');

        setRegisterError(null);
        setRegisterLoading(true);
        setRegisterDone(false);
        setRegisterEmailSent(false);

        if (!validator.isLength(register_name.value, { min: 3 })) {
            setRegisterError(translate('Please Enter Your Name'));
            setRegisterLoading(false);
            return;
        }

        if (!validator.isLength(register_surename.value, { min: 3 })) {
            setRegisterError(translate('Please Enter Your Surname'));
            setRegisterLoading(false);
            return;
        }


        if (!validator.isEmail(register_username.value)) {
            setRegisterError(translate('Email address is not valid'));
            setRegisterLoading(false);
            return;
        }

        if (!validator.isLength(register_password.value, { min: 6 })) {
            setRegisterError(translate('Password must be 6 characters or more '));
            setRegisterLoading(false);
            return;
        }

        // if (!gender) {
        //     setRegisterError(translate('Please Select Gender'));
        //     setRegisterLoading(false);
        //     return;
        // }

        // if (!birthdate.year) {
        //     setRegisterError(translate('Please Select Year of Birth'));
        //     setRegisterLoading(false);
        //     return;
        // }

        // if (!birthdate.month) {
        //     setRegisterError(translate('Please Select Month of Birth'));
        //     setRegisterLoading(false);
        //     return;
        // }



        // if (!birthdate.day) {
        //     setRegisterError(translate('Please Select Day of Birth'));
        //     setRegisterLoading(false);
        //     return;
        // }


        if (!register_user_info.talent) {
            setRegisterError(translate('Please Select Talent'));
            setRegisterLoading(false);
            return;
        }


        // if (!register_user_info.country_id) {
        //     setRegisterError(translate('Please Select a country'));
        //     setRegisterLoading(false);
        //     return;
        // }

        // if (!register_user_info.city_id) {
        //     setRegisterError('Please Select a city');
        //     setRegisterLoading(false);
        //     return;
        // }

        // if (!register_user_info.livingcountry_id) {
        //     setRegisterError(translate('Please Select residence country'));
        //     setRegisterLoading(false);
        //     return;
        // }

        // if (!register_phone) {
        //     setRegisterError(translate('Please Enter phone number'));
        //     setRegisterLoading(false);
        //     return;
        // }



        // if (!register_user_info.livingcity_id) {
        //     setRegisterError('Please Select residence city');
        //     setRegisterLoading(false);
        //     return;
        // }


        var body = {
            email: register_username.value,
            password: register_password.value,
            first_name: register_name.value,
            last_name: register_surename.value,
            // gender: gender,
            // phone_number: register_phone.value ? register_phone.value : "",
            // country_id: register_user_info.country_id,
            // city_id: register_user_info.city_id,
            // livingcountry_id: register_user_info.livingcountry_id,
            // livingcity_id: register_user_info.livingcity_id,
            // birth_date: birthdate.year + "-" + birthdate.month + "-" + birthdate.day,
            talent_id: register_user_info.talent,
            redirect_url: "https://kurdishtalent.com/validate"
        }


        fetch(`${ApiBase}auth/registerparticipant/`, {
            headers: new Headers(headers),
            method: 'POST',
            body: JSON.stringify(body),

        })
            .then((response) => {
                setRegisterLoading(false);
                console.log(response)
                if (response.status >= 400) {
                    setRegisterError(translate("This email address is already exists."));
                }
                return response.json();
            }).then(function (data) {
                console.log(data);
                setRegisterLoading(false);

                if (data.data && data.data.email) {
                    //setRegisterError(data.email);
                    console.log(data.data)
                    setRegisterDone(true);

                }
                if (data.errors) {
                    setRegisterError(translate("This email address is already exists."));
                }


            });


    }


    const resendRgisterVerificationEmail = (e) => {
        e.preventDefault();
        setRegisterError(false)
        if (!validator.isEmail(register_username.value)) {
            setRegisterError(translate('Email address is not valid'));
            setRegisterLoading(false);
            return;
        }

        setRegisterLoading(true)
        resendVerificationEmail(register_username.value).then((data) => {
            console.log(data)
            setRegisterLoading(false)
            setRegisterEmailSent(true);
            setRegisterDone(false)
        })
    }

    const resendLoginVerificationEmail = (e) => {
        e.preventDefault();
        setEmailSent(false);
        setError(false)

        if (!validator.isEmail(username.value)) {
            setError(translate('Email address is not valid'));
            setLoading(false);
            return;
        }
        setLoading(true)
        resendVerificationEmail(username.value).then((data) => {
            setLoading(false)
            setEmailSent(true);
            setLoginEmailNotVerified(false)
        })
    }




    return (
        <div>
            <div className="container py-4">


                    

                    <div className="col-md-12 appear-animation pr-5  appear-animation-visible  " data-appear-animation="fadeInRightShorter" data-appear-animation-delay="300">
                        <h2 className="font-weight-bold text-5 mb-10">{translate('Register')}</h2>

                        <form action="" className="form-fields-rounded">
                            <div className="form-row">
                                <div className="form-group col-lg-12">
                                    <input type="text" maxLength="100" className="form-control text-3 h-auto py-2" name="name"  {...register_name} placeholder={translate('Name')} />
                                </div>
                            </div>
                            <div className="form-row">

                                <div className="form-group col-lg-12">
                                    <input type="text" maxLength="100" className="form-control text-3 h-auto py-2" name="surename"  {...register_surename} placeholder={translate('Surename')} />
                                </div>
                            </div>
                            {/* <div className="form-row">
                                <div className="form-group col-md-6">
                                    <div className="custom-select-1">
                                        <SelectGender placeholder={translate('Gender')} value={gender} callback={onValue} />
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <div className="input-group">
                                        <input type="phone" placeholder={translate("Phone Number")} name="register_phone" {...register_phone} className="form-control text-3 h-auto py-2" />
                                    </div>
                                </div>
                            </div> */}
                            <div className="form-row">
                                <div className="form-group col">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroup-sizing-default"><i className="fa fa-user"></i></span>
                                        </div>
                                        <input type="email" placeholder={translate('Email')} name="register_username" {...register_username} className="form-control text-3 h-auto py-2" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroup-sizing-default"><i className="fa fa-lock"></i></span>
                                        </div>
                                        <input type="password" placeholder={translate('Password')} name="register_password" {...register_password} className="form-control text-3 h-auto py-2" required />
                                    </div>
                                </div>
                            </div>
                            <div className="form-row ">
                                <div className="form-group col">
                                    <div className="custom-select-1">
                                        <SelectTalentsDialog onValue={onValue} />
                                    </div>
                                </div>

                            </div>

                            {/* <div className="form-row">

                                <label className="col-12">{translate("Date of Birth")}</label>
                                <div className="form-group col-lg-4">

                                    <div className="custom-select-1">
                                        <SelectYear placeholder={translate('Year')} name="year" width="100" value={year} callback={onValue} />
                                    </div>
                                </div>
                                <div className="form-group col-lg-4">
                                    <div className="custom-select-1">
                                        <SelectMonth placeholder={translate('Month')} name="month" width="100" value={month} callback={onValue} />
                                    </div>
                                </div>
                                <div className="form-group col-lg-4">
                                    <div className="custom-select-1">
                                        <SelectDay placeholder={translate('Day')} name="day" width="100" value={day} callback={onValue} />
                                    </div>
                                </div>
                            </div>
                            <label>{translate('I am originally from')}</label>
                            <div className="form-group row">
                                <div className="col-lg-6 mb-3 ">
                                    <div className="custom-select-1">
                                        <SelectCountries placeholder={translate('Countries')} kurds={1} callback={onValue} value={birth_country} name="birth_country" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-select-1">
                                        <SelectCities placeholder={translate('City')} key={birth_country} country={birth_country} name="birth_city" value={birth_city} callback={onValue} />
                                    </div>
                                </div>
                            </div>
                            <label>{translate('I am living in')}</label>
                            <div className="form-group row">
                                <div className="col-lg-6 mb-3 ">
                                    <div className="custom-select-1">
                                        <SelectCountries placeholder={translate('Countries')} kurdsOnTop={1} callback={onValue} value={living_country} name="living_country" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-select-1">
                                        <SelectCities placeholder={translate('City')} key={living_country} country={living_country} value={living_city} name="living_city" callback={onValue} />
                                    </div>
                                </div>
                            </div> */}
                            <div className="form-row">
                                <div className="form-group col">
                                    {register_loading && <><small className="loading">{translate('Please Wait...')}</small><br /><br /></>}

                                    <button type="button" onClick={handleRegister} disabled={register_loading} className="btn btn-primary  btn-block text-uppercase rounded-35 font-weight-bold text-3  py-10" data-loading-text="Loading...">
                                        <i className="fas fa-user-plus op-7"></i>&nbsp;
                                        {translate('Register')}
                                    </button>

                                    {register_error && <>
                                        <br />
                                        <div className="alert alert-danger text-2 ">
                                            {register_error}
                                        </div>
                                    </>}


                                    {register_done && <>
                                        <br />
                                        <div className="alert alert-primary">
                                            {translate('Register Completed. please check your email address to verify your account.')} <br />
                                            <a href="#resend_email" onClick={resendRgisterVerificationEmail}>{translate("Didn't you get verification email ? Send again")}</a>
                                        </div>
                                    </>


                                    }
                                    {register_email_sent && <>
                                        <br />
                                        <div className="alert alert-primary">
                                            {translate('Email Sent. please check your email address to verify your account.')}
                                        </div>
                                    </>


                                    }

                                </div>
                            </div>
                        </form>
                        {/* <hr />
                        <h2 className="font-weight-bold text-5 mb-10">{translate('Login')}</h2>
                        <form onSubmit={handleLogin} className="needs-validation form-fields-rounded">
                            <div className="form-row">
                                <div className="form-group col">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroup-sizing-default"><i className="fa fa-user"></i></span>
                                        </div>
                                        <input type="email" placeholder={translate("Email")} name="username"  {...username} className="form-control text-3 h-auto py-2" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroup-sizing-default"><i className="fa fa-lock"></i></span>
                                        </div>
                                        <input type="password" placeholder={translate("Password")} name="password" {...password} className="form-control text-3 h-auto py-2" required />
                                    </div>
                                </div>
                            </div>
                            <div className="form-row justify-content-between">
                                <div className="form-group col-md-auto">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="rememberme" />
                                        <label className="custom-control-label cur-pointer text-2" for="rememberme">{translate('Remember Me')}</label>
                                    </div>
                                </div>
                                <div className="form-group col-md-auto kfont">
                                    <a className="text-decoration-none text-color-hover-white color-gray text-2" href="forgot">{translate('Forgot Password?')}</a>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col">
                                    {loading && <><small style={{ color: 'green' }}>Please Wait...</small><br /><br /></>}

                                    <button type="submit" className="btn  btn-primary  btn-block text-uppercase rounded-35 font-weight-bold text-3 py-10" value={loading ? 'Loading...' : 'Login'} disabled={loading} data-loading-text="Loading...">
                                        <i className="fas fa-user  op-7"></i>&nbsp;
                                        {translate('Login')}
                                    </button>


                                    <br />
                                    {error &&
                                        <>
                                            <div className="alert alert-danger text-2">
                                                {error}
                                            </div>
                                        </>
                                    }
                                    {login_email_not_verified &&
                                        <>
                                            <div className="alert alert-primary text-2">
                                                <a href="#resend_email" onClick={resendLoginVerificationEmail}>didn't get verification email ? Send  again</a>

                                            </div>
                                        </>
                                    }
                                    {email_sent && <>
                                        <div className="alert alert-success">
                                            Email Sent. please check your email address to verify your account.
                                        </div>
                                    </>


                                    }


                                </div>
                            </div>
                        </form> */}
                    </div>




            </div>
        </div>
    )
}

