import React, { useEffect, useState} from 'react'
import Select from 'react-select';
import { loadCourseDocuments } from '../../../utils/Network';
import { useStateValue } from '../../../StateProvider';
import { getDefaultValue } from '../../../utils';


export default function SelectRated({ onValue,course }) {

    const[ratedCourseDocumentsCount, setRatedCourseDocumentsCount] = useState(false)
    const[unRatedCourseDocumentsCount, setUnRatedCourseDocumentsCount] = useState(false)

    useEffect(() => {

        loadCourseDocuments(course, 1).then(data => {
            setRatedCourseDocumentsCount(data.count)
        })

        loadCourseDocuments(course, 0).then(data => {
            setUnRatedCourseDocumentsCount(data.count)
        })

    }, [])
    // Select    

    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x=>x.key == str );
    
        if (translate) return getDefaultValue(translate);
        else return "#" + str;
      };


    const customStyles = {
        control: (base, state) => ({
            ...base,
            width: "160px",
            background: "transparent",
            // match with the menu
            borderRadius: 25,
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "#424240" : "#424240",
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: state.isFocused ? "#424240" : "#424240"
            }
        }),
        menu: base => ({
            ...base,
            width: "130px",
            '*::-webkit-scrollbar': {
                width: '0.4em'
            },
            '*::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: '#c7c7c7',
                outline: '1px solid #16181B'
            },
            marginLeft: "14px",
            boxShadow: "1px 1px 1px 1px #0e0e0e80",
            // override border radius to match the box
            borderRadius: 0,
            borderBottomRightRadius: 5,

            borderBottomLeftRadius: 5,
            // kill the gap
            marginTop: 0,
            backgroundColor: "#27282C",
            opacity: "0.97"

        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 0,
            "&:hover": {
                backgroundColor: "#27282C",
                borderBottomRightRadius: 5,
                borderBottomLeftRadius: 5,
                transition: "all 0.5s ease-out"
            }
        }),
        option: (base, state) => {


            let backgroundColor = "transparent";
            let color = "#c2c2c2";

            // if (state.isSelected) {
            //     backgroundColor = "#454542";
            // }

            if (state.isFocused) {
                //color = "#454542";
            }

            //  if (state.isActive) {
            //      backgroundColor = "#474747";
            //  }

            return {
                ...base,
                color: color,
                padding: 0,
                fontSize: "13px",
                backgroundColor,
                transition: "all 0.5s ease-out",

            };
        }

    };


    // End Select


    const handleSelect = (e) => {
        if (e) {
            onValue("rating", e.value)
        }

    }

    let options = [{
        label:
            <div className={`options no-border-bottom`}>
                <span><i className="fas fa-caret-right mln-10"></i> {translate('All')}</span>
            </div>,
        value: -1
    },{
        label:
            <div className={`options `}>
                <span><i className="fas fa-caret-right mln-10"></i> {translate('Rated')} ({ratedCourseDocumentsCount})</span>
            </div>,
        value: 1
    },
    {
        label:
            <div className={`options no-border-bottom`}>
                <span><i className="fas fa-caret-right mln-10"></i> {translate('Unrated')} ({unRatedCourseDocumentsCount})</span>
            </div>,
        value: 0,
    }
    ]



    return (
        <div className="kfont lan-rtl">
            <Select
                classNamePrefix='filter'
                options={options}
                value={0}
                styles={customStyles}
                onChange={handleSelect}
                placeholder=""
                isSearchable={false}
            />
        </div>

    )

}
