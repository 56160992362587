import { getToken } from "..";
import { ApiBase } from "../Network";


export class HttpService {

    async get(url,body)  {
        // this.setHeaders();
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");   
        const auth = getToken();

        if (auth) headers.append("Authorization", "Bearer " + auth);   


        const response = await fetch(`${ApiBase}${url}`, {
        headers: new Headers(headers),
        method: 'GET',
        });

        const data = await response.json();


        return data;
    }

    async post(url,body,hasFile=false)  {
        var headers = new Headers();
        if(!hasFile)
            headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json"); 
        const auth = getToken();
        if (auth) headers.append("Authorization", "Bearer " + auth);   
        
        const response = await fetch(`${ApiBase}${url}`, {
            headers: new Headers(headers),
            method: 'POST',
            body: hasFile ? body : JSON.stringify(body),
        });



        const data = await response.json();

        // console.log(data)

        return data;
    }

    async put(url,body,hasFile=false)  {
        var headers = new Headers();
        if(!hasFile)
            headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json"); 
        const auth = getToken();
        if (auth) headers.append("Authorization", "Bearer " + auth);   
        
        const response = await fetch(`${ApiBase}${url}`, {
            headers: new Headers(headers),
            method: 'PUT',
            body: hasFile ? body : JSON.stringify(body),
        });

        const data = await response.json();

        console.log(body)
        console.log(data)

        return data;
    }

    async patch(url,body,hasFile)  {
        
        var headers = new Headers();
        if(!hasFile)
            headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json"); 
        const auth = this.getToken();
        if (auth) headers.append("Authorization", "Bearer " + auth);   
        
        const response = await fetch(`${ApiBase}${url}`, {
            headers: new Headers(headers),
            method: 'PATCH',
            body: JSON.stringify(body),
        });

        const data = await response.json();

        return data;
    }

    async delete(url) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json"); 
        const auth = getToken();
        if (auth) headers.append("Authorization", "Bearer " + auth);   

        const response = await fetch(`${ApiBase}${url}`, {
        headers: new Headers(headers),
        method: 'DELETE',
        });

        return ;
    }

    

    
}

