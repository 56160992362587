import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  signInWithRedirect,
  FacebookAuthProvider,
  TwitterAuthProvider
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyCMXpq8b2upE1yka5MI7Vx9-p-6yaLsj2E",
  authDomain: "kurdishtalent-bfa78.firebaseapp.com",
  projectId: "kurdishtalent-bfa78",
  storageBucket: "kurdishtalent-bfa78.appspot.com",
  messagingSenderId: "48516816480",
  appId: "1:48516816480:web:f2dda9d9917d13b30c6d05"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const twitterProvider = new TwitterAuthProvider();

const signInWithFacebook = async (e) => {
  try {
    const res = await signInWithPopup(auth, facebookProvider);
    const user = res.user;  
    console.log(user)
    var _userObj = {
      role: "participant",
      uid: user.uid,
      email: user.email,
      image_path: user.photoURL,
      name: user.displayName,
      authProvider: "facebook",
    };
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      addDoc(collection(db, "users"), _userObj);
    }
    return _userObj;
  } catch (err) {
    console.error(err);
    return null; 
  }
};

const signInWithTwitter = async (e) => {
  try {
    const res = await signInWithPopup(auth, twitterProvider);
    const user = res.user;  
    console.log(user)
    var _userObj = {
      role: "participant",
      uid: user.uid,
      email: user.email,
      image_path: user.photoURL,
      name: user.displayName,
      authProvider: "twitter",
    };
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      addDoc(collection(db, "users"), _userObj);
    }
    return _userObj;
  } catch (err) {
    console.error(err);
    return null; 
  }
};

const signInWithGoogle = async (e) => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;  
    console.log(user)
    var _userObj = {
      role: "participant",
      uid: user.uid,
      email: user.email,
      image_path: user.photoURL,
      name: user.displayName,
      authProvider: "google",
    };
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      addDoc(collection(db, "users"), _userObj);
    }
    return _userObj;
  } catch (err) {
    console.error(err);
    return null; 
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export {
  app,
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  signInWithFacebook,
  signInWithTwitter,
};