import React from 'react'
import VideoCarouselItem from './VideoCarouselItem';
import Slider from 'react-slick';
import VideoGridController from '../../Controller/VideoGridController';
import VideoGridlItem from './VideoGridlItem';
function VideoGrid({ categoryId = 0 }) {

    const { loading, userGalleries, setUserGalleries, } = VideoGridController(categoryId);

    var settings = {
        dots: false,
        autoplay: true,
        speed: 1000,
        fade: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: true,
        centerMode: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    };


    return (
        // <div className='videogrid-container' >
        <div className=' mb-5 mt-5' >
            {/* <div className="row"> */}
            {/* {userGalleries && userGalleries.map(item => (
                    <VideoGridlItem item={item} key={item.id} />
                )
                )} */}

            <Slider    {...settings}
            >
                {userGalleries && userGalleries.map(item => (
                    <VideoCarouselItem item={item} key={item.id} />
                )
                )}
            </Slider>
            {/* </div> */}
        </div>
    )
}

export default VideoGrid