import React, { useEffect, useState } from 'react'
import { BuildTalents, getCurrentLanguageId, getDefaultDescription, getDefaultName, getDefaultValue, getName } from '../../utils';
import { loadSettings, loadTalents } from '../../utils/Network';
import parse from 'html-react-parser'
import { useStateValue } from '../../StateProvider';
import PostsCount from '../../components/shared/PostsCount';


export default function AboutContent({ id }) {

    const [loading, setLoading] = useState(false);
    const [settings, setSettings] = useState(null);
    const [talents, setTalents] = useState(null);
    const [show_menu, setShowingMenu] = useState(null);
    const [child_show_menu, setChildShowingMenu] = useState(null);


    useEffect(() => {
        setLoading(true)

        let language_id = getCurrentLanguageId()

        loadSettings().then(settings => {
            setSettings(settings)
            setLoading(false)
        })

        loadTalents(language_id).then(talents => {
            setTalents(BuildTalents(talents,1))
        })



    }, [])


    const openMenu = (e, talent) => {
        if (talent.childs.length > 0) {
            e.preventDefault()
            setShowingMenu(talent.id)
            if (talent.id === show_menu)
                setShowingMenu(null)
        }
    }

    const openChildMenu = (e, talent) => {
        if (talent.childs.length > 0) {
            e.preventDefault()
            setChildShowingMenu(talent.id)
            if (talent.id === child_show_menu)
                setChildShowingMenu(null)
        }
    }

    const [{ translations }] = useStateValue();
    const translate = (str , def) => {
		if(def==null) def = str;
		if (!translations) return def;
		var translate = translations.find(x=>x.key == str );
	
		if (translate) return getDefaultValue(translate);
		else return "#" + str;
	  };

    return (
        <div id="main" role="main" className="main bg-blog">

            <section className="page-header pb-0 section-with-shape-divider page-header-modern page-header-lg mb-0 pb-4  page-header-background   overlay-op-7"
                style={{ backgroundSize: "cover", backgroundPositionX: "center" }} >
                <div className="container lan-rtl">
                    {loading &&
                        <>
                            <div className="bounce-loader">
                                <div className="bounce1"></div>
                                <div className="bounce2"></div>
                                <div className="bounce3"></div>
                            </div>
                        </>
                    }

                    <div className="row py-4 mb-2">
                        <div className="col-md-8 order-md-1">
                            <div className="overflow-hidden">
                                <h2 className=" font-weight-bold text-10 mb-2 pt-0 mt-0 appear-animation animated maskUp appear-animation-visible" data-appear-animation="maskUp" data-appear-animation-delay="300" style={{ animationDelay: "300ms" }}>
                                    {settings && getDefaultName(settings.names)}
                                </h2>
                            </div>
                            <div className="overflow-hidden mb-3">
                            </div>

                            <p className="pb-3 about-desc appear-animation animated appear-animation-visible text-justify" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800">
                                {settings && getDefaultDescription(settings.names)}
                            </p>

                        </div>
                        <div className="col-md-4 order-md-2 mb-4 mb-lg-0 hide-on-small appear-animation animated appear-animation-visible" data-appear-animation="fadeInRightShorter">
                            <img src={settings && settings.about_image} className="img-fluid mb-2" alt={settings && settings.about_title} />
                        </div>
                        <div className="col-md-12 order-md-3">
                            <h5 className="text-3 mb-3 lan-text-right kfont text-small-center">{translate('FOLLOW US')}</h5>
                            <ul className="social-icons">
                                <li className="social-icons-facebook"><a href={settings?.facebook} target="_blank" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
                                <li className="social-icons-twitter"><a href={settings?.twitter} target="_blank" title="Twitter"><i className="fab fa-twitter"></i></a></li>
                                <li className="social-icons-instagram"><a href={settings?.instagram} target="_blank" title="instagram"><i className="fab fa-instagram"></i></a></li>
                                <li className="social-icons-youtube"><a href={settings?.youtube} target="_blank" title="youtube"><i className="fab fa-youtube"></i></a></li>
                            </ul>
                        </div>
                    </div>


                </div>
               
            </section>



            <div className="appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">
                <div className="home-concept mt-5">
                    <div className="container">

                        <div className="row text-center">
                            <div className="col-lg-2 ml-lg-auto">
                                <div className="process-image kfont">
                                    <img src={settings && settings.about_circle_image_1} alt="" />
                                    <strong >{settings && (getName(settings.names).about_circle_title_1)}</strong>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="process-image process-image-on-middle kfont">
                                    <img src={settings && settings.about_circle_image_2} alt="" />
                                    <strong>{settings && (getName(settings.names).about_circle_title_2)}</strong>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="process-image kfont">
                                    <img src={settings && settings.about_circle_image_3} alt="" />
                                    <strong>{settings && (getName(settings.names).about_circle_title_3)}</strong>
                                </div>
                            </div>
                            <div className="col-lg-4 ml-lg-auto">
                                <div className="project-image">
                                    <div id="fcSlideshow" className="fc-slideshow">
                                        <ul className="fc-slides">
                                            <li><a href="portfolio-single-wide-slider.html"><img className="img-responsive" src={settings && settings.about_circle_image_4} alt={settings && settings.about_circle_title_4} /></a></li>
                                            <li><a href="portfolio-single-wide-slider.html"><img className="img-responsive" src={settings && settings.about_circle_image_4} alt={settings && settings.about_circle_title_4} /></a></li>
                                            <li><a href="portfolio-single-wide-slider.html"><img className="img-responsive" src={settings && settings.about_circle_image_4} alt={settings && settings.about_circle_title_4} /></a></li>
                                        </ul>
                                    </div>
                                    <strong className="our-work kfont">{settings && (getName(settings.names).about_circle_title_4)}</strong>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <section className="section section-default border-0 mt-5 appear-animation animated appear-animation-visible" data-appear-animation="fadeIn" data-appear-animation-delay="1200">
                <div className="container py-4">

                    <div className="row featured-boxes featured-boxes-style-4 lan-rtl">
                        <div className="col-md-6 col-lg-3 my-2">
                            <div className="m-0 featured-box featured-box-primary featured-box-effect-4 appear-animation animated appear-animation-visible" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="1600">
                                <div className="box-content p-0 text-start">
                                    <i className="icon-featured icon-people  icons text-12 m-0 p-0"></i>
                                    <h4 className="font-weight-bold text-light-in-dark text-center">{settings && getName(settings.names).about_icons_title_1}</h4>
                                    <p className="mb-0 text-gray-in-dark ">

                                        {settings && parse(getName(settings.names).about_icons_description_1)}


                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 my-2">
                            <div className="m-0 featured-box featured-box-primary featured-box-effect-4 appear-animation animated appear-animation-visible" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="1400">
                                <div className="box-content p-0 text-start">
                                    <i className="icon-featured icon-volume-2 icons text-12 m-0 p-0"></i>
                                    <h4 className="font-weight-bold text-light-in-dark text-center ">{settings && getName(settings.names).about_icons_title_2}</h4>
                                    <p className="mb-0 text-gray-in-dark ">{settings && parse(getName(settings.names).about_icons_description_2)}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 my-2">
                            <div className="m-0 featured-box featured-box-primary featured-box-effect-4 appear-animation animated appear-animation-visible" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="1400">
                                <div className="box-content p-0 text-start">
                                    <i className="icon-featured icon-diamond icons text-12 m-0 p-0"></i>
                                    <h4 className="font-weight-bold text-light-in-dark text-center">{settings && getName(settings.names).about_icons_title_3}</h4>
                                    <p className="mb-0 text-gray-in-dark">{settings && parse(getName(settings.names).about_icons_description_3)}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 my-2">
                            <div className="m-0 featured-box featured-box-primary featured-box-effect-4 appear-animation animated appear-animation-visible" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="1600">
                                <div className="box-content p-0 text-start">
                                    <i className="icon-featured icon-globe icons text-12 m-0 p-0"></i>
                                    <h4 className="font-weight-bold text-light-in-dark text-center">{settings && getName(settings.names).about_icons_title_4}</h4>
                                    <p className="mb-0 text-gray-in-dark">{settings && parse(getName(settings.names).about_icons_description_4)}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>


            <div className="container pt-5 pb-2 lan-rtl">


                <div className="row ">
                    <div className="col-md-3">
                        <div className="overflow-hidden mb-3">
                            <h2 className="font-weight-normal text-6 mb-0 appear-animation appear-animation-visible" data-appear-animation="maskUp"><strong className="font-weight-extra-bold">{translate('Talents')}</strong></h2>
                        </div>
                        <ul className="nav nav-list nav-list-rtl flex-column mb-5 lan-rtl lan-padding-r-5">
                            {talents && talents.map(talent => (
                                <li key={talent.id} className="nav-item lan-text-right">
                                    <a className="nav-link" onClick={(e) => { openMenu(e, talent) }} href={`talent/${talent.id}`}>{getDefaultName(talent.names)} <PostsCount id={talent.id} /> </a>
                                    {talent.childs &&
                                        <ul className={show_menu == talent.id ? "show lan-rtl" : "hide lan-rtl"}>
                                            {talent.childs.map(child => (
                                                <li key={child.id} className="nav-item lan-text-right">
                                                    <a className="nav-link" href={`talent/${child.id}`} onClick={(e) => { openChildMenu(e, child) }}>{getDefaultName(child.names)} <PostsCount id={talent.id} /></a>
                                                    {child.childs &&
                                                        <ul className={child_show_menu == child.id ? "show" : "hide"}>
                                                            {child.childs.map(grand_child => (
                                                                <li key={grand_child.id} className="nav-item lan-text-right">
                                                                    <a className="nav-link" href={`talent/${grand_child.id}`}>{getDefaultName(grand_child.names)} <PostsCount id={talent.id} /></a>

                                                                </li>

                                                            ))}

                                                        </ul>
                                                    }
                                                </li>

                                            ))}

                                        </ul>
                                    }
                                </li>
                            ))}

                        </ul>
                    </div>
                    <div className="col-md-9 ">
                        <h4 className="mb-2">{translate('How does it work?')}</h4>
                        <div className="process process-vertical py-4">
                            <div className="process-step appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">
                                <div className="process-step-circle">
                                    <strong className="process-step-circle-content ">1</strong>
                                </div>
                                <div className="process-step-content">
                                    <h4 className="mb-1 text-4 font-weight-bold">{translate('1_title','')}</h4>
                                    <p className="mb-0">
                                    {translate('1_desc','')}
                                    </p>
                                </div>
                            </div>
                            <div className="process-step appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400">
                                <div className="process-step-circle">
                                    <strong className="process-step-circle-content ">2</strong>
                                </div>
                                <div className="process-step-content">
                                    <h4 className="mb-1 text-4 font-weight-bold">{translate('2_title','')}</h4>
                                    <p className="mb-0">
                                    {translate('2_desc','')}
                                    </p>
                                </div>
                            </div>
                            <div className="process-step appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600">
                                <div className="process-step-circle">
                                    <strong className="process-step-circle-content ">3</strong>
                                </div>
                                <div className="process-step-content">
                                    <h4 className="mb-1 text-4 font-weight-bold">{translate('3_title','')}</h4>
                                    <p className="mb-0">{translate('3_desc','')}</p>
                                </div>
                            </div>
                            <div className="process-step appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800">
                                <div className="process-step-circle">
                                    <strong className="process-step-circle-content ">4</strong>
                                </div>
                                <div className="process-step-content">
                                    <h4 className="mb-1 text-4 font-weight-bold">{translate('4_title','')}</h4>
                                    <p className="mb-0">{translate('4_desc','')}</p>
                                </div>

                            </div>
                            <div className="process-step appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1000">
                                <div className="process-step-circle">
                                    <strong className="process-step-circle-content ">5</strong>
                                </div>
                                <div className="process-step-content">
                                    <h4 className="mb-1 text-4 font-weight-bold">{translate('5_title','')}</h4>
                                    <p className="mb-0">{translate('5_desc','')}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )

}
