import React, { Component, useEffect, useRef, useState } from "react";
import {
  getCurrentLanguage,
  getDefaultValue,
  setCurrentLanguage,
} from "../utils";
import {
  loadProfile,
  saveProfile,
  changePassword,
} from "../utils/Network";
import { useStateValue } from "../StateProvider";
import PageHeaderLight from "../components/shared/PageHeaderLight";
import DashboardAside from "./sections/participant/DashboardAside";
import ProfileForm from "../components/forms/user/ProfileForm";

export default function Profile() {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordDone, setPasswordDone] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);



  const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x=>x.key == str );
    
        if (translate) return getDefaultValue(translate);
        else return "#" + str;
      };

  useEffect(() => {
    setLoading(true);


    setCurrentLanguage(getCurrentLanguage());
    loadProfile().then((profile) => {
      setUser(profile);
      setLoading(false);
      if (profile) {
        if (profile.role === "normal") {
            window.location.href = "/talent/6"
        }

      }
    });
    
  }, []);

  

  const handleOnChange = (e) => {
    var el = e.target;
    var tu = user;
    switch (el.name) {
      

      case "password":
        tu.password = el.value;
        setUser(tu);
        break;
      case "confirmpassword":
        tu.confirmpassword = el.value;
        setUser(tu);
        break;
      case "website":
        tu.website = el.value;
        setUser(tu);
        break;
      case "facebook":
        tu.facebook = el.value;
        setUser(tu);
        break;
      case "instagram":
        tu.instagram = el.value;
        setUser(tu);
        break;
      case "youtube":
        tu.youtube = el.value;
        setUser(tu);
        break;
      case "snapchat":
        tu.snapchat = el.value;
        setUser(tu);
        break;
      case "spotify":
        tu.spotify = el.value;
        setUser(tu);
        break;
      case "comments":
        tu.comments = el.value;
        setUser(tu);
        break;

      default:
        break;
    }
  };

  const saveChanges = (e) => {
    setLoading(true);
    var temp = user;
    temp.gender = user.gender;
    temp.country_id = user.country;
    temp.city_id = user.city;
    temp.livingcountry_id = user.livingcountry;
    temp.livingcity_id = user.livingcity;

    saveProfile(temp).then((data) => {
      console.log(data);
      setLoading(false);
    });
  };

  const savePassword = (e) => {
    setPasswordError(false);

    if (user) {
      if (!user.password) {
        setPasswordError("Password must be at least 6 characters");
        return;
      }
      if (user.password !== user.confirmpassword) {
        setPasswordError("Password and Confirm don't match");
        return;
      }

      setPasswordLoading(true);

      changePassword(user).then((data) => {
        console.log(data);
        setPasswordLoading(false);
        setPasswordDone("Password Saved Successfully");
      });
    }
  };

  

  return (
    <div>
      <div role="main" className="main">
        <PageHeaderLight />
        <div className="container lan-rtl pt-3 pb-2">
          <div className="row pt-2">
            <DashboardAside />

            <div className="col-lg-9  ">
              <h2 className="font-weight-bold line-height-3 text-7 mb-0">
                {translate("Manage Profile")}
              </h2>
              <p className="mb-4 pb-2 lan-text-right">
                {translate("Update your profile here.")}
              </p>

              <div className="tabs tabs-quaternary">
                <ul className="nav nav-tabs ">
                  <li className={`nav-item `}>
                    <a
                      className={`nav-link active`}
                      href={"#general"}
                      data-toggle="tab"
                    >
                      {translate("General")}
                    </a>
                  </li>
                  <li className={`nav-item `}>
                    <a className={`nav-link`} href={"#about"} data-toggle="tab">
                      {translate("About Me")}
                    </a>
                  </li>
                  <li className={`nav-item `}>
                    <a
                      className={`nav-link `}
                      href={"#security"}
                      data-toggle="tab"
                    >
                      {translate("Security")}
                    </a>
                  </li>
                  <li className={`nav-item `}>
                    <a
                      className={`nav-link `}
                      href={"#social"}
                      data-toggle="tab"
                    >
                      {translate("Social")}
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div id={`general`} className={`tab-pane active pt-4`}>
                    <ProfileForm />
                  </div>
                  <div id={`about`} className={`tab-pane pt-4`}>
                    {/* <div
                      className="col-md-12 px-1 appear-animation appear-animation-visible"
                      data-appear-animation="fadeIn"
                      data-appear-animation-delay="600"
                      data-plugin-options="{'accY': -150}"
                    >
                      <div className="row lan-rtl">
                        <div className="col-md-6 lan-rtl">
                          <h2 className="text-color-primary font-weight-normal text-5 mb-2 ">
                            {translate("Upload Documents")}
                          </h2>
                          <p className="lan-text-right">
                            {translate("Certificates , CV")}
                          </p>
                          <input
                            type="button"
                            onClick={handleClick}
                            className="btn btn-primary btn-lg btn-upload "
                            value={translate("Upload")}
                          />
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            <Certificates key={reload} openModal={openModal} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <input
                      ref={inputFileRef}
                      type="file"
                      name="upload_certificate"
                      className="hide"
                      onChange={handleFileChange}
                    /> */}
                    <form role="form" className="needs-validation styled">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group row">
                            <div className="col-lg-12">
                              <textarea
                                className="form-control text-3 h-auto py-2 rounded-35 about-textarea"
                                style={{ minHeight: "200px" }}
                                type="text"
                                name="comments"
                                defaultValue={user && user.comments}
                                onChange={handleOnChange}
                                placeholder={translate("Write about yourself")}
                              ></textarea>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="form-group col-lg-9"></div>
                            <div className="form-group col-lg-3">
                              <input
                                type="button"
                                value={translate("Save Changes")}
                                className="btn rounded-35 btn-rash btn-modern float-right lan-float-left"
                                disabled={loading}
                                onClick={saveChanges}
                              />
                            </div>
                            {loading && (
                              <div className="alert alert-primary">
                                {translate("Please Wait...")}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div id={`security`} className={`tab-pane  pt-4`}>
                    <form role="form" className="needs-validation styled">
                      <div className="form-group row">
                        <div className="col-lg-6 mb-3">
                          <input
                            placeholder={translate("Email")}
                            className="form-control text-3 h-auto py-2 rounded-35"
                            type="email"
                            value={user && user.email}
                          />
                        </div>
                      </div>

                      <h4>{translate("Change Password")}</h4>
                      <div className="form-group row">
                        <div className="col-lg-6 mb-3">
                          <input
                            placeholder={translate("New Password")}
                            className="form-control text-3 h-auto py-2 rounded-35"
                            type="password"
                            name="password"
                            onChange={handleOnChange}
                          />
                        </div>
                        <div className="col-lg-6">
                          <input
                            placeholder={translate("Confirm Password")}
                            className="form-control text-3 h-auto py-2 rounded-35"
                            type="password"
                            name="confirmpassword"
                            onChange={handleOnChange}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="form-group col-lg-9"></div>
                        <div className="form-group col-lg-3">
                          <input
                            type="button"
                            value={translate("Save Changes")}
                            className="btn rounded-35 btn-rash btn-modern float-right lan-float-left"
                            disabled={passwordLoading}
                            onClick={savePassword}
                          />
                        </div>
                      </div>
                      {passwordLoading && (
                        <div className="alert alert-info">Please wait...</div>
                      )}
                      {passwordError && (
                        <div className="alert alert-danger">
                          {passwordError}
                        </div>
                      )}
                      {passwordDone && (
                        <div className="alert alert-success">
                          {passwordDone}
                        </div>
                      )}
                    </form>
                  </div>
                  <div id={`social`} className={`tab-pane pt-4 `}>
                    <form role="form" className="needs-validation styled">
                      <div className="form-group row">
                        <div className="col-lg-6 mb-3">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text lan-border-radius-r"
                                id="inputGroup-sizing-default"
                              >
                                <i className="fa fa-globe"></i>
                              </span>
                            </div>
                            <input
                              type="url"
                              defaultValue={user && user.website}
                              placeholder="Website"
                              name="website"
                              onChange={handleOnChange}
                              className="form-control ltr border-radius-25 text-3 h-auto py-2 lan-border-radius-l"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text lan-border-radius-r"
                                id="inputGroup-sizing-default "
                              >
                                <i className="fab fa-facebook-f"></i>
                              </span>
                            </div>
                            <input
                              type="url"
                              defaultValue={user && user.facebook}
                              placeholder="Facebook"
                              name="facebook"
                              onChange={handleOnChange}
                              className="form-control ltr  border-radius-25 text-3 h-auto py-2 lan-border-radius-l"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-lg-6 mb-3">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text lan-border-radius-r"
                                id="inputGroup-sizing-default"
                              >
                                <i className="fab fa-instagram"></i>
                              </span>
                            </div>
                            <input
                              type="url"
                              defaultValue={user && user.instagram}
                              placeholder="Instagram"
                              name="instagram"
                              onChange={handleOnChange}
                              className="form-control ltr  border-radius-25 text-3 h-auto py-2 lan-border-radius-l"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text lan-border-radius-r"
                                id="inputGroup-sizing-default"
                              >
                                <i className="fab fa-youtube"></i>
                              </span>
                            </div>
                            <input
                              type="url"
                              defaultValue={user && user.youtube}
                              placeholder="Youtube"
                              name="youtube"
                              onChange={handleOnChange}
                              className="form-control ltr  border-radius-25 text-3 h-auto py-2 lan-border-radius-l"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-lg-6 mb-3">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text lan-border-radius-r"
                                id="inputGroup-sizing-default"
                              >
                                <i className="fab fa-snapchat"></i>
                              </span>
                            </div>
                            <input
                              type="url"
                              defaultValue={user && user.snapchat}
                              placeholder="Snapchat"
                              name="snapchat"
                              onChange={handleOnChange}
                              className="form-control  ltr border-radius-25 text-3 h-auto py-2 lan-border-radius-l"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text lan-border-radius-r"
                                id="inputGroup-sizing-default"
                              >
                                <i className="fab fa-spotify"></i>
                              </span>
                            </div>
                            <input
                              type="url"
                              defaultValue={user && user.spotify}
                              placeholder="Spotify"
                              name="spotify"
                              onChange={handleOnChange}
                              className="form-control ltr border-radius-25 text-3 h-auto py-2 lan-border-radius-l"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="form-group col-lg-9"></div>
                        <div className="form-group col-lg-3">
                          <input
                            type="button"
                            value={translate("Save Changes")}
                            className="btn rounded-35 btn-rash btn-modern float-right lan-float-left"
                            disabled={loading}
                            onClick={saveChanges}
                          />
                        </div>
                        {loading && (
                          <div className="alert alert-primary">
                            Please Wait...
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
