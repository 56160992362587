import React from 'react'
import Slider from 'react-slick';
import UrlEmbed from 'components/UrlEmbed';
import { useState } from 'react';
import { getDefaultValue, getYoutubeId } from 'utils';
import Modal from 'react-modal';
import { useStateValue } from 'StateProvider';


function HomeCarousel() {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modal_loading, setModalLoading] = useState(null);
    const [modal_url, setModalUrl] = useState(null);

    const PrepareModal = (url) => {
        setModalLoading(true)
        setModalUrl(url)
        setModalIsOpen(true)
        setModalLoading(false)
    }

    const [{ translations }] = useStateValue();

    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x => x.key == str);

        if (translate) return getDefaultValue(translate);
        else return "#" + str;
    };



    const closeModal = () => {
        setModalIsOpen(false)
    }

    var settings = {
        dots: true,
        autoplay: true,
        speed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
    };


    return (
        <div className='' data-appear-animation="fadeInDownShorter"
        data-appear-animation-duration="3s"
        data-appear-animation-delay="1000" >
            <Slider className='home-carousel'   {...settings}
            >
                <a href="/kurdishtalent-2022"  style={{ marginRight: 5, cursor: "pointer" }} >
                    <img
                        src={`/assets/images/home/season01.jpg`}
                        className="img-fluid"
                        alt="View blog"
                    />
                </a>
                <a href="/blog"  style={{ marginRight: 5, cursor: "pointer" }} >
                    <img
                        src={`/assets/images/home/kurdishtalentblog.jpg`}
                        className="img-fluid"
                        alt="View blog"
                    />
                </a>
                <a href="https://www.youtube.com/c/KurdishTalent" target='_blank' rel='no-follow'  style={{ marginRight: 5, cursor: "pointer" }} >
                    <img
                        src={`/assets/images/home/kurdishtalentyoutube.jpg`}
                        className="img-fluid"
                        alt="View blog"
                    />
                </a>
                
                {/* <div onClick={() => { setModalIsOpen(true); PrepareModal("https://www.youtube.com/watch?v=yBg_IU5KlBo") }} style={{ marginRight: 5, cursor: "pointer" }} >
                    <img
                        src={`https://img.youtube.com/vi/yBg_IU5KlBo/maxresdefault.jpg`}
                        className="img-fluid"
                        alt=""
                    />
                </div>
                <div onClick={() => { setModalIsOpen(true); PrepareModal("https://www.youtube.com/watch?v=z7dQINaf4Og") }} style={{ marginRight: 5, cursor: "pointer" }} >
                    <img
                        src={`https://img.youtube.com/vi/z7dQINaf4Og/maxresdefault.jpg`}
                        className="img-fluid"
                        alt=""
                    />
                </div>
                <div onClick={() => { setModalIsOpen(true); PrepareModal("https://www.youtube.com/watch?v=rnCOwnNEUcg") }} style={{ marginRight: 5, cursor: "pointer" }} >
                    <img
                        src={`https://img.youtube.com/vi/rnCOwnNEUcg/maxresdefault.jpg`}
                        className="img-fluid"
                        alt=""
                    />
                </div>
                <div onClick={() => { setModalIsOpen(true); PrepareModal("https://www.youtube.com/watch?v=HFAU3QBXJg4") }} style={{ marginRight: 5, cursor: "pointer" }} >
                    <img
                        src={`https://img.youtube.com/vi/HFAU3QBXJg4/maxresdefault.jpg`}
                        className="img-fluid"
                        alt=""
                    />
                </div> */}
                {/* <div  style={{ marginRight: 5, cursor: "pointer" }} >
                    <a href="/kurdishtalent-2022" >
                        <img
                            src={`/assets/images/home/top15.png`}
                            className="img-fluid"
                            alt=""
                        />
                    </a>
                </div>
                 */}
                
            </Slider>
            {modalIsOpen &&
                <Modal
                    closeTimeoutMS={500}
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    className="Modal "
                    ariaHideApp={false}
                    overlayClassName="Overlay"
                >
                    <div className="modal-content">
                        <div className="modal-body row">

                            <div className="col-md-12 file-container flex-center">
                                {modal_loading &&
                                    <>
                                        <div className="bounce-loader">
                                            <div className="bounce1"></div>
                                            <div className="bounce2"></div>
                                            <div className="bounce3"></div>
                                        </div>
                                    </>
                                }
                                {modal_url && <UrlEmbed url={modal_url} />}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light pull-right kfont rtl-pull-left" onClick={closeModal}>{translate('Close')}</button>
                        </div>
                    </div>
                </Modal>
            }
        </div>


    )
}

export default HomeCarousel