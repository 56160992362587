import React, { useEffect, useState } from "react";
import Intro from "components/shared/Intro";
import { getCurrentLanguage, getDefaultValue, setUserSession } from "utils";

import "./Home.css";
import { ApiBase, loadPopupMessages } from "utils/Network";
import { useStateValue } from "StateProvider";
import Cookie from "components/base/Cookie";
import ChangeLanguagePanel from "components/base/ChangeLanguagePanel";
import ModalPopupMessage from "components/dialogs/ModalPopupMessage";
import LoginModal from "components/user/LoginModal";
import VideoCarousel from "./components/videogallery/VideoCarousel";
import LatestPosts from "./components/posts/LatestPosts";
import VideoGrid from "./components/videogallery/VideoGrid";
import SlideShowContent from "components/shared/home/SlideShowContent";
import MapIntro from "components/shared/home/MapIntro";
import HomeParticipantsTopTen from "components/shared/home/HomeParticipantsTopTen";
import HomeCounters from "components/shared/home/HomeCounters";



export default function Home() {


 

  const [{ translations }] = useStateValue();
  const [current_language, setCurrentLanguage] = useState(null);
  const [modalPopupIsOpen, setModalPopupIsOpen] = useState(false);
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState(null);

  const translate = (str) => {
    if (!translations) return str;
    var translate = translations.find(x => x.key == str);

    if (translate) return getDefaultValue(translate);
    else return "#" + str;
};

  useEffect(() => {
    setCurrentLanguage(getCurrentLanguage());
    loadPopupMessages().then(popupmessages => {
      if (popupmessages && popupmessages.length > 0) {
        setPopupMessage(popupmessages[0])
        setModalPopupIsOpen(true)
      }
    })
  }, []);




  return (
    <div>
      <Cookie translations={translations} />
      <LoginModal key={loginModalIsOpen} setOpen={setLoginModalIsOpen} setLoggedIn={() => { }} isOpen={loginModalIsOpen} />

      <section
        className="section border-0 video pb-0 overlay overlay-show overlay-op-8 m-0 slider-height"
        data-video-path="assets/video/kt"
        data-plugin-video-background
        data-plugin-options="{'posterType': 'jpg', 'position': '50% 50%'}"
      >
        <div className="container position-relative z-index-3 h-100">
          <div className="row align-items-center h-100">
            <div className="col">
              <SlideShowContent />
              
              {/* <ChangeLanguagePanel current_language={current_language} /> */}

              {/* <div
                className="col appear-animation animated fadeInUp appear-animation-visible padding-15"
                data-appear-animation="fadeInUp"
                data-appear-animation-delay="0"
                data-appear-animation-duration="1s"
              >
                <hr className="my-1 slide-line" />
              </div> */}
              {/* <HomeLoginForm translations={translations} /> */}



            </div>
          </div>
          <a
            href="#main"
            className="slider-scroll-button hide-on-small position-absolute left-50pct transform3dx-n50"
            data-hash
            data-hash-offset="80"
          >
            Sroll To Bottom
          </a>
        </div>
      </section>
      <ModalPopupMessage popupMessage={popupMessage} isOpen={modalPopupIsOpen} setIsOpen={setModalPopupIsOpen} />
      <Intro translations={translations} />
      <VideoCarousel />


      <div className="container">
        <LatestPosts translations={translations} />
      </div>
      <a href="/kurdishtalent-2022">
        <img
          src="assets/images/season01.png"
          className="home-history-img appear-animation"
          data-appear-animation="fadeInRightShorter"
          data-appear-animation-delay="260"
          data-plugin-options="{'minWindowWidth': 0}"
          alt=""
        />
      </a>
      <div className="flex-center container">
        <hr className="sm-no-margin" />
      </div>

      <div className="container">
        <MapIntro />

        <h3 className="text-center">{translate("Top 15")}</h3>
        <hr className="solid" />
        <HomeParticipantsTopTen />
        <VideoGrid categoryId={17/** Home Category Videos */} />
      </div>
      

      {/* <LatestPosts translations={translations} /> */}
    </div>
  );
}


