import React from 'react'
import PageHeader from '../components/shared/PageHeader';
import { useStateValue } from '../StateProvider';
import {  getDefaultValue } from '../utils';


export default function SignOut(props) {

    const [{ translations }] = useStateValue();

    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x=>x.key == str );
    
        if (translate) return getDefaultValue(translate);
        else return "#" + str;
      };
    return (
        <div>
            <PageHeader title="Sign Out" message="" />

            <div className="row text-center pt-4">
                <div className="col">
                    <h2 className="word-rotator-title font-weight-bold text-6 mb-2 text-center">
                        {translate('You successfully signed out from your account')}
					</h2>
                    <h4 className="text-primary lead tall text-4 text-center">
                        <a href="/" className="text-color-light ">
                            {translate('Back to sign in')}
                        </a>
                    </h4>
                </div>
            </div>
        </div>
    )
}
