import React from 'react'
import PostItemSidebar from './PostItemSidebar'
import { useState } from 'react';
import { loadMenuTalents, loadPosts } from 'utils/Network';
import { useEffect } from 'react';
import { BuildTalents, getDefaultName, getDefaultValue } from 'utils';
import { useStateValue } from 'StateProvider';
import PostsCount from 'components/shared/PostsCount';

function PostsSidebar({id}) {

    const [loading, setLoading] = useState(false);
    const [popular_posts, setPopularPosts] = useState(null);
    const [recent_posts, setRecentPosts] = useState(null);
    const [sidebar_talents, setSidebarTalents] = useState(null);
    const [show_menu, setShowingMenu] = useState(null);
    const [child_show_menu, setChildShowingMenu] = useState(null);



    useEffect(() => {
        setLoading(true)
        document.title = 'KurdishTalent | Blog '


        loadPosts(id).then(posts => {
            setPopularPosts(posts)
            setRecentPosts(posts)
            setLoading(false)
        })

        loadMenuTalents().then(talents => {
            setSidebarTalents(BuildTalents(talents))
        })
    }, [])

    const openMenu = (e, talent) => {
        if (talent.childs.length > 0) {
            e.preventDefault()
            setShowingMenu(talent.id)
            if (talent.id === show_menu)
                setShowingMenu(null)
        }
    }

    const openChildMenu = (e, talent) => {
        if (talent.childs.length > 0) {
            e.preventDefault()
            setChildShowingMenu(talent.id)
            if (talent.id === child_show_menu)
                setChildShowingMenu(null)
        }
    }

    
    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x => x.key == str);

        if (translate) return getDefaultValue(translate);
        else return "#" + str;
    };


    return (
        <aside className="sidebar">
            <form action="page-search-results.html" method="get">
                <div className="input-group mb-3 pb-1 lan-rtl">
                    <input className="form-control text-1 border-radius-0" placeholder={translate("Search...")} name="s" id="s" type="text" />
                    <span className="input-group-append">
                        <button type="submit" className="btn btn-primary border-1 text-1 p-2 "><i className="fas fa-search m-2"></i></button>
                    </span>
                </div>
            </form>
            {/* <h5 className="font-weight-semi-bold pt-4 kfont lan-text-right">{translate('Talents')}</h5> */}
            <ul className="nav nav-list nav-list-rtl flex-column mb-5 lan-rtl">
                {sidebar_talents && sidebar_talents.map(talent => (
                    <li key={talent.id} className="nav-item lan-text-right">
                        <a className="nav-link" onClick={(e) => { openMenu(e, talent) }} href={`blog/${talent.id}`}>{getDefaultName(talent.names)} <PostsCount id={talent.id} /> </a>
                        {talent.childs &&
                            <ul className={show_menu == talent.id ? "show lan-rtl" : "hide lan-rtl"}>
                                {talent.childs.map(child => (
                                    <li key={child.id} className="nav-item lan-text-right">
                                        <a className="nav-link" href={`blog/${child.id}`} onClick={(e) => { openChildMenu(e, child) }}>{getDefaultName(child.names)} <PostsCount id={talent.id} /></a>
                                        {child.childs &&
                                            <ul className={child_show_menu == child.id ? "show" : "hide"}>
                                                {child.childs.map(grand_child => (
                                                    <li key={grand_child.id} className="nav-item lan-text-right">
                                                        <a className="nav-link" href={`blog/${grand_child.id}`}>{getDefaultName(grand_child.names)} <PostsCount id={talent.id} /></a>

                                                    </li>

                                                ))}

                                            </ul>
                                        }
                                    </li>

                                ))}

                            </ul>
                        }
                    </li>
                ))}

            </ul>
            <div className="tabs tabs-dark mb-4 pb-2">
                <ul className="nav nav-tabs">
                    <li className="nav-item active"><a className="nav-link show active text-1 font-weight-bold text-uppercase" href="#popularPosts" data-toggle="tab">{translate("Popular")}</a></li>
                    <li className="nav-item"><a className="nav-link text-1 font-weight-bold text-uppercase" href="#recentPosts" data-toggle="tab">{translate("Recent")}</a></li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane active" id="popularPosts">
                        <ul className="simple-post-list">
                            {popular_posts && popular_posts.map(post => (
                                <PostItemSidebar post={post} />
                            ))}
                        </ul>
                    </div>
                    <div className="tab-pane" id="recentPosts">
                        <ul className="simple-post-list">
                            {recent_posts && recent_posts.map(post => (
                                <PostItemSidebar post={post} />
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </aside>
    )
}

export default PostsSidebar