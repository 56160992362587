import React, { useEffect, useRef, useState } from 'react'
import FacebookLogin from 'react-facebook-login';
import { setUserSession } from '../../utils';
import { ApiBase, resendVerificationEmail, doLogin, requestReset, checkResetToken, completeReset } from '../../utils/Network';
import validator from 'validator'



export default function ResetPanel(params) {


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password_confirm, setPasswordConfirm] = useState("");
    const [loading, setLoading] = useState(null);
    const [error, setError] = useState(null);
    const [done, setDone] = useState(false);
    const [show_form, setShowForm] = useState(false);




    const url = new URL(window.location.href);

    const token_valid = url.searchParams.get('token_valid') ? true : false;
    const message = url.searchParams.get('message') ? url.searchParams.get('message') : "";
    const uidb64 = url.searchParams.get('uidb64') ? url.searchParams.get('uidb64') : "";
    const token = url.searchParams.get('token') ? url.searchParams.get('token') : "";



    useEffect(() => {
        if (token_valid === true && token !== "")
            setShowForm(true)



    }, [])
    const handleChange = (e) => {

        switch (e.target.name) {
            case "password":
                setPassword(e.target.value);
                break;
            case "password_confirm":
                setPasswordConfirm(e.target.value);
                break;

            default: break;
        }
    }

    const handleResetPassword = () => {
        setLoading(true)
        setError(false)

        if (password.length < 6) {
            setError("Password must be at least 6 chracters")
            setLoading(false)
            return
        }
        if (password !== password_confirm) {
            setError("Passwords doesn't match")
            setLoading(false)
            return
        }
        setDone(false)
        completeReset(token, uidb64, password).then(data => {
            console.log(data)
            if (data.detail && data.detail.includes('invalid')){
                setError(data.detail)
            }
            else
                setDone("Your new password saved.")
            setLoading(false)
            
        })

    }

    return (
        <div>
            <div className="container py-4">

                <div className="row justify-content-center">
                    <div className="col-md-6 col-lg-5  appear-animation pr-5  appear-animation-visible  " data-appear-animation="fadeInRightShorter" data-appear-animation-delay="300">
                        {!show_form &&
                            <>
                                <p>Token is expired</p>
                            </>}
                        {show_form &&
                            <form action="" className="form-fields-rounded">

                                <div className="form-row">
                                    <div className="form-group col">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="inputGroup-sizing-default"><i className="fa fa-key"></i></span>
                                            </div>
                                            <input type="password" placeholder="Password" name="password" onChange={handleChange} className="form-control text-3 h-auto py-2" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="inputGroup-sizing-default"><i className="fa fa-key"></i></span>
                                            </div>
                                            <input type="password" placeholder="Confirm Password" name="password_confirm" onChange={handleChange} className="form-control text-3 h-auto py-2" />
                                        </div>
                                    </div>
                                </div>
                                <button type="button" onClick={handleResetPassword} disabled={loading} className="btn btn-primary  btn-block text-uppercase rounded-35 font-weight-bold text-3  py-10" data-loading-text="Loading...">
                                    <i className="fas fa-save op-7"></i>&nbsp;
                                    Save new Password
                                </button>
                                {loading &&
                                    <div className="alert alert-primary mt-2">Please wait...</div>
                                }
                                {error &&
                                    <div className="alert alert-danger mt-2">{error}</div>
                                }
                                {done &&
                                    <>
                                        <div className="alert alert-success mt-2">
                                            {done}
                                        </div>
                                        <br />

                                    </>
                                }

                            </form>
                        }
                        <a href="signin" className="text-color-primary mt-2">
                            <i className="fa fa-chevron-left mr-2"></i>
                            Back to Signin
                        </a>
                    </div>

                </div>
                <div className="row"><div className="col mb-4"><hr className="my-5" /></div></div>
            </div>
        </div>
    )
}

