import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { loadUserTalentDocuments } from "../../utils/Network";
import UploadDocumentForm from "../forms/talent/UploadDocumentForm";
import LoadingOverlay from "../shared/LoadingOverlay";
import UserDocumentPreview from "../talent/UserDocumentPreview";

export default function ModalUserDocumentUpload({ talent, isOpen = false, setIsOpen }) {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [userDocument, setUserDocument] = useState(null);
  const [upload_enabled, setUploadEnabled] = useState(null);

  useEffect(() => {
    setLoading(true);
    setUserDocument(null)

    if (talent) {
      loadUserTalentDocuments(null, talent).then((usertalentDocuments) => {
        setLoading(false);

        if (usertalentDocuments.length > 0) {
          var ud = usertalentDocuments[0];
          setUserDocument(ud);
          setUploadEnabled(false)
        }
        else {
          setUploadEnabled(true)
        }
      });
    }
  }, [talent]);
  const closeModal = () => {
    setIsOpen(false);
  };


  return (
    <Modal
      closeTimeoutMS={500}
      ariaHideApp={false}
      isOpen={isOpen>0}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
    >
      <div className="modal-content bg-upload-material">
        <div className="modal-header no-border pb-0">
        <button type="button" className="close lan-float-left lan-ml-0" onClick={closeModal}>&times;</button>
        </div>
          {(!upload_enabled && userDocument) && <UserDocumentPreview handleEdit={()=>{  setUploadEnabled(true) }} userDocument={userDocument} setUserDocument={(val) => { setUserDocument(val); if (!val) { setUploadEnabled(true) } }} />}
          {upload_enabled && <UploadDocumentForm talentId={talent} 
          userDocumentId={userDocument && userDocument.id}
           onSuccess={(userDocument) => { setError(""); setUserDocument(userDocument); if (userDocument) { setUploadEnabled(false) } }} />}
          <LoadingOverlay key={loading + "ld"} loading={loading} />
      </div>
    </Modal>
  );
}
