import React from 'react'
import { getDate } from 'utils'

function PostItemSidebar({post}) {
    return (
        <li className="lan-rtl">
            <div className="post-image lan-float-right lan-ml-10 lan-mr-0">
                <div className="img-thumbnail img-thumbnail-no-borders d-block ">
                    <a href={`post/${post.id}`} title={post.title}>
                        <img src={post.gallery_image ? post.gallery_image.image : "/assets/images/default.png"} width="50" height="50" alt={post.title} className="lan-float-right post-sidebar-img" />
                    </a>
                </div>
            </div>
            <div className="post-info lan-rtl lan-text-right ">
                <a href={`post/${post.id}`} className="lan-text-right kfont" title={post.title}>
                    {post.title}
                </a>
                <div className="post-meta">
                    {getDate(post.updated_at)}
                </div>
            </div>
        </li>
    )
}

export default PostItemSidebar