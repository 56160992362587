import React, { useEffect, useState } from 'react'
import PostsCount from '../../../components/shared/PostsCount';
import TalentName from '../../../components/talent/TalentName';
import { useStateValue } from '../../../StateProvider';
import { BuildTalents, getDate, getDefaultName, getDefaultValue } from '../../../utils';
import { loadMenuTalents, loadPosts } from '../../../utils/Network';
import PostItem from './PostItem';
import PostItemSidebar from './PostItemSidebar';
import PostsSidebar from './PostsSidebar';
import BlogStyle1 from 'features/feature_blog/components/blog_styles/BlogStyle1';

export default function PostsContent({ id }) {

    const [loading, setLoading] = useState(false);
    const [posts, setPosts] = useState(null);
    const [featured_posts, setFeaturedPosts] = useState(null);
    
    const [popular_posts, setPopularPosts] = useState(null);
    const [recent_posts, setRecentPosts] = useState(null);
    const [show_menu, setShowingMenu] = useState(null);
    const [child_show_menu, setChildShowingMenu] = useState(null);



    useEffect(() => {
        setLoading(true)
        document.title = 'KurdishTalent | Blog '


        loadPosts(id).then(posts => {
            setPosts(posts)
            setPopularPosts(posts)
            setRecentPosts(posts)
            setLoading(false)
        })


        loadPosts(id,4,1,1).then(posts => {
            setFeaturedPosts(posts)
            setLoading(false)
        })

    }, [])



    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x => x.key == str);

        if (translate) return getDefaultValue(translate);
        else return "#" + str;
    };


    return (
        <div id="main" role="main" className="main bg-blog">

            <div className="container py-4">
                {loading &&
                    <>
                        <div className="bounce-loader">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                        </div>
                    </>
                }
                {!loading && <>
                    <div className="row mt-130 lan-rtl mt-blog-top">
                        <div className="col-lg-9 order-lg-1">
                            <div className='mb-4'>
                            <BlogStyle1 posts={featured_posts} />
                            </div>
                            <div className="blog-posts">
                                {posts && posts.map(post => (
                                    <PostItem key={"post" + post.id} post={post} />
                                ))}
                                <ul className="pagination float-right hide">
                                    <li className="page-item"><a className="page-link" href="#"><i className="fas fa-angle-left"></i></a></li>
                                    <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                    <a className="page-link" href="#"><i className="fas fa-angle-right"></i></a>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 order-lg-2">
                            <PostsSidebar />
                        </div>
                    </div>
                </>
                }
            </div>

        </div>
    )

}
