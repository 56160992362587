import React from 'react'
import { getDay, getMonth, translate } from 'utils'

function PostItem({ post , translations}) {
    return (
        <article className="post post-large pb-1 ">
            <div className="post-image">
                <a href={`post/${post.id}`}>
                    <img src={post.gallery_image ? post.gallery_image.image : "/assets/images/default.png"} className="img-fluid img-thumbnail img-thumbnail-no-borders rounded-0" alt={post.title} />
                </a>
            </div>

            {/* <div className="post-date">
                <span className="day">{getDay(post.updated_at)}</span>
                <span className="month">{getMonth(post.updated_at)}</span>
            </div> */}

            <div className="post-content">

                <h4><a href={`post/${post.id}`} className="text-decoration-none">{post.title}</a></h4>
                <p className="mb-1 lan-text-right text-light-2">{post.short_description}</p>
                <a href={`post/${post.id}`} className="read-more text-color-dark font-weight-bold text-2">read more <i className="fas fa-chevron-right text-1 ml-1"></i></a>

            </div>
            {/* <div className="post-meta">
                <span className="d-block d-sm-inline-block float-sm-right mt-3 mt-sm-0"><a href={`post/${post.id}`} className="btn btn-xs btn-light text-1 text-uppercase kfont">{translate(translations,"Read More")}</a></span>
            </div> */}
        </article>
    )
}

export default PostItem