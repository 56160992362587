import React from 'react'
import { getDefaultValue } from '../../utils';

function Cookie({translations}) {

    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x=>x.key == str );
    
        if (translate) return getDefaultValue(translate);
        else return "#" + str;
      };

    return (
        <div>
            <div className="gdpr-bar" data-plugin-gdpr>
			<div className="gdpr-bar-description">
				<p className="text-color-light opacity-7 mb-0">We use cookies to improve user experience and analyze website traffic. Read about how we use cookies and how you can control them by clicking "Privacy Preferences".</p>
			</div>
			<div className="gdpr-bar-actions">
				<a href="#" className="gdpr-preferences-trigger text-decoration-none text-color-light">Privacy Preferences</a>
				<a href="#" className="gdpr-agree-trigger text-decoration-none btn btn-primary btn-modern btn-px-4 py-3 font-weight-bold">I Agree</a>
			</div>
		</div>

		<div className="gdpr-preferences-popup">
			<div className="gdpr-preferences-popup-content position-relative">
				<a href="#" className="gdpr-close-popup text-color-grey text-color-hover-dark">
					<i className="fas fa-times"></i>
				</a>
				<form className="gdpr-preferences-form">
					<div className="gdpr-preferences-popup-content-body">
						<h4 className="text-color-dark font-weight-bold mb-3">{translate("Privacy Preferences")}</h4>
						<p>When you visit any website, it may store or retrieve information through your browser, usually in the form of cookies. Since we respect your right to privacy, you can choose not to permit data collection from certain types of services. However, not allowing these services may impact your experience.</p>
						<hr />

						<div className="gdpr-cookie-item">
							<div className="gdpr-cookie-item-title">
								<strong className="text-color-dark">{translate("Privacy Policy")}</strong>
							</div>
							<div className="gdpr-cookie-item-description">
								<p className="mb-0">You read and agreed to our <a href="privacy">Privacy Policy</a></p>
							</div>
							<div className="gdpr-cookie-item-action">
								<strong className="text-color-dark text-2">REQUIRED</strong>
								<input type="hidden" name="cookies_consent[]" className="gdpr-input" value="privacy-policy" />
							</div>
						</div>
						
						
						
					</div>
					<div className="gdpr-preferences-popup-content-footer">
						<button type="submit" className="btn btn-primary btn-modern btn-px-4 py-3 font-weight-bold">Save Preferences</button>
						<a href="privacy" className="text-color-default text-color-hover-primary">Privacy Policy</a>
					</div>
				</form>
			</div>
		</div>

        </div>
    )
}

export default Cookie
