import React, {  useState } from 'react'
import {  requestReset } from '../../utils/Network';
import validator from 'validator'



export default function ForgotPanel() {


    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(null);
    const [error, setError] = useState(null);
    const [done, setDone] = useState(false);

    const handleChange = (e) => {

        switch (e.target.name) {
            case "email":
                setEmail(e.target.value);
                break;

            default: break;
        }
    }

    



    const handleResetRequest = () => {
        setLoading(true)
        setError(false)
        if (!validator.isEmail(email)) {
            setError("Please Enter a Valid email Address")
            setLoading(false)
            return
        }

        requestReset(email).then(data => {
            console.log(data)
            setDone("Please check your email. We sent you a link to reset your password.")
        })

    }

    return (
        <div>
            <div className="container py-4">

                <div className="row justify-content-center">
                    <div className="col-md-6 col-lg-5  appear-animation pr-5  appear-animation-visible  " data-appear-animation="fadeInRightShorter" data-appear-animation-delay="300">

                        <form action="" className="form-fields-rounded">

                            <div className="form-row">
                                <div className="form-group col">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroup-sizing-default"><i className="fa fa-user"></i></span>
                                        </div>
                                        <input type="email" placeholder="Email Address" name="email" onChange={handleChange} className="form-control text-3 h-auto py-2" />
                                    </div>
                                </div>
                            </div>
                            <button type="button" onClick={handleResetRequest} disabled={loading} className="btn btn-primary  btn-block text-uppercase rounded-35 font-weight-bold text-3  py-10" data-loading-text="Loading...">
                                <i className="fas fa-envelope op-7"></i>&nbsp;
                                Send reset link
                            </button>
                            {error &&
                                <div className="alert alert-danger mt-2">{error}</div>
                            }
                            {done &&
                                <>
                                    <div className="alert alert-success mt-2">
                                        {done}
                                    </div>
                                    <br />

                                </>
                            }
                            <a href="signin" className="text-color-primary mt-2">
                                <i className="fa fa-chevron-left mr-2"></i>
                                Back to Signin
                            </a>
                        </form>
                    </div>

                </div>
                <div className="row"><div className="col mb-4"><hr className="my-5" /></div></div>
            </div>
        </div>
    )
}

