import React, { Component } from 'react'
import PostContent from './PostContent';

//loadProfile();

export default class Post extends Component {


    render() {
        let id = this.props.match.params.id;
        

        return (
                <PostContent id={id} />
        )

    }
}
