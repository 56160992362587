import React, { useEffect, useState } from 'react'
import {getDefaultValue, getYoutubeId } from '../utils';

import InstagramEmbed from 'react-instagram-embed';
import parse from 'html-react-parser'
import { useStateValue } from '../StateProvider';
import { useLocation } from 'react-router-dom';

export default function UrlEmbed({ url ,referee=false, isDocument=false }) {

    const [showVideoMessage, setShowVideoMessage] = useState(false);

    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x=>x.key == str );
    
        if (translate) return getDefaultValue(translate);
        else return "#" + str;
      };

    const location = useLocation();

    useEffect(() => {
        if (url.includes("firebasestorage") && !referee && !location.pathname.includes("talent") ){
             setShowVideoMessage(true)
         } 
    }, [])

    const urlSource = () => {
        if (!url) return;
        if (isDocument) return 'document'
        if (url.includes("instagram.com")) return 'instagram'
        if (url.includes("youtube.com") || url.includes("youtu.be")) return 'youtube'
        if (url.includes("tiktok.com")) return 'tiktok'
        if (url.includes("firebasestorage")) return 'file'
        return "web"
    }

    


    return (
        <>
            {urlSource() === "instagram" &&
                <>
                {/* <InstagramEmbed
                    url='https://instagr.am/p/Zw9o4/'
                    clientAccessToken='123|456'
                    maxWidth={320}
                    hideCaption={false}
                    containerTagName='div'
                    protocol=''
                    injectScript
                    onLoading={() => {}}
                    onSuccess={() => {}}
                    onAfterRender={() => {}}
                    onFailure={() => {}}
                    /> */}
                <a href={url} target="_blank"  rel='noreferrer' className="weburl" title="click to view content">

                    <h4 className="url-content">
                        <i className="fab fa-instagram"></i>
                        <p> Click to view instagram post</p>
                    </h4>
                </a>
                </>
            }

            {urlSource() === "youtube" &&
                <>
                <iframe className='youtube-iframe' title="youtube" src={`//www.youtube.com/embed/${getYoutubeId(url)}`} frameborder="0" allowfullscreen>

                </iframe>

                </>
            }
            {urlSource() === "file" &&
                <>
                    <video controls  width="100%" height="400"  className="video-fit-contain" >
                        <source src={url} type="video/mp4" />
                    </video>

                    { showVideoMessage &&
                    (
                    <p className="mt-2 lan-rtl kfont lan-text-right font-14" style={{lineHeight:1.8}}>
                        <i className="fa fa-check color-green"></i> &nbsp;
                        {parse(translate("your video is received"))}
                       
                    </p>)}
                </>
            }

            {urlSource() === "document" &&
            <>
                    <a href={url} target="_blank" rel='noreferrer' className="weburl" title="click to view content">

                        <h4 className="url-content">
                            <i className="fa fa-file"></i>
                            <p>Click to view document</p>
                        </h4>
                    </a>
                </>
            }
            

            {urlSource() === "web" &&
                <>
                    <a href={url} target="_blank" rel='noreferrer' className="weburl" title="click to view content">

                        <h4 className="url-content">
                            <i className="fa fa-play"></i>
                            <p> Click to visit url</p>
                        </h4>
                    </a>
                </>
            }

            {urlSource() === "tiktok" &&
                <>
                    <a href={url} target="_blank" className="weburl" title="click to view content">

                        <h4 className="url-content">
                            <i className="fab fa-tiktok"></i>
                        <p> Click to visit Tiktok post</p>
                        </h4>
                    </a>
                </>
            }
        </>
    )
}
