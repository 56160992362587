import React, { useEffect, useState } from "react";
import {
  loadProfile,
} from "../../../utils/Network";
import {
  getCurrentLanguage,
  getDefaultName,
  getDefaultValue,
  logout,
} from "../../../utils";
import TalentPanel from "../../TalentPanel";
import { useStateValue } from "../../../StateProvider";
import UserHeader from "../../../components/profile/UserHeader";
import DashboardAside from "./DashboardAside";
import PageHeaderLight from "../../../components/shared/PageHeaderLight";

//loadProfile();

export default function Artist() {
  const [profile, setProfile] = useState(null);
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(false);

  const [current_language, setCurrentLanguage] = useState(null);

  useEffect(() => {
    setLoading(true);


    setCurrentLanguage(getCurrentLanguage());
    loadProfile().then((profile) => {
      setProfile(profile);
      setLoading(false);
      if (profile) {
        setRole(profile.role);
        if (profile.role === "referee") {
          window.location.href = "referee";
        }

      } else {
        logout();
        window.location.href = "signin";
      }
    });
  }, []);

  const [{ translations }] = useStateValue();
  const translate = (str) => {
    if (!translations) return str;
    var translate = translations.find(x => x.key == str);

    if (translate) return getDefaultValue(translate);
    else return "#" + str;
  };

  return (
    <div>
      <div id="main" role="main" className="main">
        <PageHeaderLight />

        <div className="container pt-3 pb-2 lan-rtl">
          <div className="row pt-2">
            <DashboardAside />
            <div className="col-lg-9">
              <h2 className="font-weight-bold line-height-3 text-7 mb-0">{translate("My Talent")}</h2>
              <p className="mb-4 pb-2" >{translate("Welcome to My Talents")}</p>
              {role === "participant" && (
                <TalentPanel />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
