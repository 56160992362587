import React, { useEffect, useRef, useState } from 'react'
import SelectTalentWithAddButton from './sections/talent/SelectTalentWithAddButton';
import UserTalents from '../components/UserTalents';
import { getDefaultValue } from '../utils';
import { useStateValue } from '../StateProvider';


export default function TalentPanel() {

    
    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x=>x.key == str );
    
        if (translate) return getDefaultValue(translate);
        else return "#" + str;
      };


    return (
        <div className="mt-2">
            
            
            <SelectTalentWithAddButton placeholder={translate('Select a Talent')} />
            <UserTalents  />


        </div>

    )

}
