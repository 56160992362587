import React from "react";
import Modal from "react-modal";

export default function ShowVideoModal({isOpen = false , poster="" , setIsOpen , url }) {

  const closeModal = () => {
    setIsOpen(false);
  };

  

  return (
    <Modal
      closeTimeoutMS={500}
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
    >
      <div className="modal-content">
      
        <div className="modal-body lan-text-right">
          <video controls width="100%" height="400" poster={poster}  className="video-fit-contain" >
              <source src={url} type="video/mp4" />
          </video>
        </div>
        
      </div>
    </Modal>
  );
}
