import { useInfiniteQuery } from 'react-query';
import { ExploreHttpService } from '../core/service';

/**
 * A custom React hook for fetching and paginating explore posts.
 * @returns {{
 *   data: any,
 *   isLoading: boolean,
 *   isError: boolean,
 *   hasNextPage: boolean,
 *   fetchNextPage: () => void
 * }}
 */
function useExplorePosts() {
    const {
        data,
        isLoading,
        isError,
        hasNextPage,
        fetchNextPage
    } = useInfiniteQuery(
        "explore_posts",
        ExploreHttpService.getExplorePosts,
        {
            getNextPageParam: (lastPage, pages) => {
                if (lastPage.nextPage < lastPage.totalPages) {
                    return lastPage.nextPage;
                }
                return undefined;
            },
            onSuccess: (data) => {
                // console.log(data);
            }
        }
    );

    return { data, isLoading, isError, hasNextPage, fetchNextPage };
}

export default useExplorePosts;