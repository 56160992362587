import React from 'react'
import { Link } from 'react-router-dom'
import { getSelectedTheme } from '../../utils';


export default function PageHeaderLight(props) {

    var theme = getSelectedTheme();
    
    return (
        <div>
            <section className="page-header">
               
            </section>
        </div>
    )
}
