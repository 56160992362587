import React, { useEffect, useState } from 'react'
import {  loadPostsCount } from '../../utils/Network'

export default function PostsCount({id}) {

    const [count, setCount] = useState(null)
    useEffect(() => {
        if(id){
            loadPostsCount(id).then(count =>{
                setCount(count)
            })
        }
    }, [])

    return (
        <>
            {count && parseInt(count)>0 && `(${count})`}
        </>
    )
}
