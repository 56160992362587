import React, { useState } from 'react'
import { useStateValue } from '../../StateProvider';
import { getDefaultValue ,setUserSession,useFormInput} from '../../utils';
import validator from "validator";
import { ApiBase } from '../../utils/Network';

function LoginForm({email}) {
    const [{ translations }] = useStateValue();

    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x=>x.key == str );
    
        if (translate) return getDefaultValue(translate);
        else return "#" + str;
      };

  const username = useFormInput(email);
  const password = useFormInput("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    
    setError(null);
    setLoading(true);

    if (!validator.isEmail(username.value)) {
      setError(translate("Email address is not valid"));
      setLoading(false);
      return;
    }

    if (!validator.isLength(password.value, { min: 6 })) {
      setError(translate("Password is not valid"));
      setLoading(false);
      return;
    }

    fetch(`${ApiBase}auth/login/`, {
      headers: new Headers(headers),
      method: "POST",
      body: JSON.stringify({
        email: username.value,
        password: password.value,
        //     email: 'jhiyar1@gmail.com',
        //    password: '123456asdasd'
      }),
    })
      .then((response) => {
        setLoading(false);
        var responsejson = response.json();
        if (response.status >= 400) {
          setError(translate("Wrong username or password"));
        }
        return responsejson;
      })
      .then(function (data) {
        if (data.tokens) {
          setUserSession(data.tokens.access, data);

          if (data.role === "referee") window.location.href = "referee";
          else window.location.href = "dashboard";
        } else {
          setError(translate("Wrong username or password"));
        }
      });
  };

    return (
        <>
        <div
                                    className="d-flex flex-row align-items-center justify-content-center appear-animation animated fadeIn appear-animation-visible"
                                    data-appear-animation="fadeIn"
                                    data-appear-animation-delay="1s"
                                    data-appear-animation-duration="3s"
                                >
                                    <form
                                    className="form-row slide-form form-fields-rounded "
                                    onSubmit={handleLogin}
                                    >
                                    <div className="form-group col-lg-5">
                                        <input
                                        type="email"
                                        {...username}
                                        placeholder={translate("Email")}
                                        maxLength="100"
                                        className="form-control text-3 h-auto py-2 text"
                                        name="username"
                                        required=""
                                        />
                                    </div>
                                    <div className="form-group col-lg-5 col-sm-7">
                                        <input
                                        type="password"
                                        {...password}
                                        placeholder={translate("Password")}
                                        data-msg-email={translate("Please enter a valid email address")}
                                        maxLength="100"
                                        className="form-control text-3 h-auto py-2 text"
                                        name="password"
                                        required=""
                                        />
                                    </div>
                                    <div className="form-group col-lg-2 col-sm-7">
                                        <button
                                        type="submit"
                                        className="btn btn-dark  btn-block text-uppercase rounded-35 font-weight-bold text-3 "
                                        data-loading-text="Loading..."
                                        >
                                        {translate("Login")}
                                        </button>
                                    </div>
                                    {error && (
                                        <div className="alert alert-danger text-2 ">{error}</div>
                                    )}
                                    {loading && (
                                        <small className="loading">
                                        {translate("Please Wait")}
                                        </small>
                                    )}
                                    {/* <div className="col-lg-12">
                                        <a href="forgot" className="slide-link link-first ">
                                        {translate("Forgot Password ?")}
                                        </a>
                                        <a href="signin" className="slide-link">
                                        {translate("Sign Up")}
                                        </a>
                                    </div> */}
                                    </form>
                                </div>
            
        </>
    )
}

export default LoginForm
