import React, { useEffect, useState } from 'react'
import RegisterAndLoginForm from '../components/user/RegisterAndLoginForm';
import './Signin.css';
import { getCurrentLanguage, getDefaultValue } from '../utils';
import { useStateValue } from '../StateProvider';
import LoginModal from '../components/user/LoginModal';
import SocialLogin from '../components/forms/user/SocialLogin';
import EmailLoginModal from '../components/user/EmailLoginModal';

export default function SignIn() {

    let _lan = getCurrentLanguage()
    const [emailLoginModalIsOpen, setEmailLoginModalIsOpen] = useState(false);

    const [current_language, setCurrentLanguage] = useState(null)
    useEffect(() => {

        setCurrentLanguage(_lan)
    }, [])


    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x => x.key == str);

        if (translate) return getDefaultValue(translate);
        else return "#" + str;
    };


    return (
        <div>
            <div>

                <section className="page-header section-with-shape-divider pb-0 page-header-modern page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-7 bg-signup" style={{ backgroundImage: "assets/images/signupheader.jpeg" }}>
                    <div className="container">
                        <div className="row mt-5">
                            <div className="col-md-12 align-self-center p-static order-2 text-center appear-animation" data-appear-animation="blurIn" data-appear-animation-delay="1000" data-plugin-options="{'minWindowWidth': 0}">
                                <h1 className="text-color-light font-weight-extra-bold text-12 line-height-1 mb-2 appear-animation" data-appear-animation="blurIn" data-appear-animation-delay="1000" data-plugin-options="{'minWindowWidth': 0}" >{translate("Sign Up")}</h1>

                                <p className="text-4 mt-10 text-color-light font-weight-light opacity-7 mb-0" data-plugin-animated-letters data-plugin-options="{'startDelay': 2000, 'minWindowWidth': 0}">
                                    {current_language && current_language === "so" &&
                                        <>بۆ ئەوەی لە کوردیش تالێنت دا بتوانیت بەشدار بیت، پێویستە خۆت تۆمار بکەیت</>}
                                    {current_language && current_language === "ku" &&
                                        <>Platformek ji bo nasandin û nirxandina zîrektîyên te</>}
                                    {(!current_language || current_language === "en") &&
                                        <>Do you have a talent you want the world to see?</>}

                                </p>
                            </div>
                            <div className="col-md-12 align-self-center order-1 hide">
                                <ul className="breadcrumb breadcrumb-light d-block text-center">
                                    <li>
                                        <a href="/">
                                            Home
                                        </a>
                                    </li>
                                    <li className="active">{translate("Sign Up")}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="shape-divider shape-divider-bottom" >
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 136" preserveAspectRatio="xMinYMin">
                            <path d="M 0 0.11 L 0 136 L 1920 136 L 1920 1.9 C 1650.0482 79.0955 1327.0648 134 980 134 C 615.6218 134 277.7878 84.5059 0 0.11 Z" fill={"#212529"} />
                        </svg>
                    </div>
                </section>
            </div>

            <div className="text-center">
                <SocialLogin goToEmailRegister={()=>{ setEmailLoginModalIsOpen(true) }} />
                <EmailLoginModal key={emailLoginModalIsOpen} setOpen={setEmailLoginModalIsOpen} setLoggedIn={()=>{}} isOpen={emailLoginModalIsOpen} />

            </div>

            {/* <div className="row justify-content-center mb-80">

                <div className="col-md-6 mb-5 mb-lg-0  appear-animation pr-5 appear-animation-visible    " data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="300">
                    <div className="mb-5 ">
                        <h2 className="font-weight-bold text-5 mb-10">{translate('How to register')}</h2>
                        <video controls width="100%" playsInline >
                            <source src="assets/video/register.mp4#t=1.5" type="video/mp4" />
                        </video>
                    </div>

                </div>
                <RegisterAndLoginForm />
            </div> */}

        </div>
    )
}
