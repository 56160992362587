import React, { useEffect, useRef, useState } from "react";
import parse from 'html-react-parser'

import uuid from "react-uuid";
import {
  getCourseNames,
  getDefaultValue,
  getExtension,
  isImage,
  isDocument,
  isYoutube,
  Mg,
  toTime,
  getYoutubeId,
} from "../utils";

import ModalDeleteUserDocument from "./dialogs/ModalDeleteUserDocument";

import {
  uploadUsertalentDocument,
  updateUserDocument,
  loadUserTalentDocuments,
  deleteUserTalentDocument,
  loadNewestCourse,
  loadTalentDocument,
} from "../utils/Network";
import Modal from "react-modal";
import { useStateValue } from "../StateProvider";
import HTMLReactParser from "html-react-parser";
import { app } from "../services/third-party/firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import ModalContestSuggestion from "./dialogs/ModalContestSuggestion";
import { useLocation } from "react-router-dom";

export default function UserTalentDocumentUpload({
  index =0,
  multiple = false,
  usertalent,
  openModal,
  document_id,
  max_file_size,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [deleteDocumentModalIsOpen, setDeleteDocumentModalIsOpen] =
    useState(false);

  const [userDocument, setUserDocument] = useState(null);
  const [userDocumentUrl, setUserDocumentUrl] = useState(null);
  const [userTalentDocuments, setUserTalentDocuments] = useState(null);
  const [duration, setDuration] = useState(null);
  const [upload_error, setUploadError] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [upload_modal_is_open, setUploadModalOpen] = useState(false);
  const [upload_modal_error, setUploadModalError] = useState(false);
  const [upload_modal_loading, setUploadModalLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showVideoMessage, setShowVideoMessage] = useState(false);
  const [newest_course, setNewestCourse] = useState(null);
  const [modalContestIsOpen, setModalContestIsOpen] = useState(false);
  
  var upload_enabled = true;

  const location = useLocation();
  var is_contest_page=false;
  if (location.pathname === "/contests") {
     is_contest_page = true;
  }

  const [{ translations }] = useStateValue();
  const translate = (str) => {
    if (!translations) return str;
    var translate = translations.find((x) => x.key == str);

    if (translate) return getDefaultValue(translate);
    else return "#" + str;
  };

  var editing = false;
  var deleting = false;
  const inputFileRef = useRef(null);
  var talent_id;

  const handleVideoMounted = (element) => {
    if (element !== null && element) {
      //element.currentTime = 2;
      if (!isNaN(element.duration)) setDuration(element.duration);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setUploadError(false);
  };

  const closeUploadModal = () => {
    setUploadModalOpen(false);
    setLoading(false);
    setUploadModalError(false);
  };

  async function uploadToFirebase(file) {
    setUserDocument(null)
    setLoading(true);
    var uid = uuid();
    var file_name = uid + file.name.substr(file.name.length - 5);
    const storage = getStorage(app);
    const storageRef = ref(storage, `videos/${file_name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    await uploadTask.on(
      "state_changed",
      (snapShot) => {
        var progress = Math.round(
          (snapShot.bytesTransferred / snapShot.totalBytes) * 100
        );
        setProgress(progress);
        console.log(progress + " %");
      },
      (err) => {
        //catches the errors
        console.log(err);
      },
      () => {
        setLoading(false);
        console.log("File uploaded successfully. saving changes ...");
        getDownloadURL(ref(storage, `videos/${file_name}`))
          .then((url) => {
            console.log(url);
            saveFirebaseUrl(url);
          })
          .catch((error) => {
            // Handle any errors
          });
      }
    );
  }

  const handleFileChange = (e) => {
    var selectedFiles = e.target.files;
    var userdocument_id = e.target.getAttribute("userdocument_id");

    if (selectedFiles.length) {
      //console.log(selectedFiles[0].name)
      let ext = getExtension(selectedFiles[0].name);
      let file_size = selectedFiles[0].size;
      if (file_size >= max_file_size + 1) {
        setUploadError(
          "it seems that your file is too big. Maximum allowed file size is " +
            Mg(max_file_size) +
            " MB"
        );
        setModalIsOpen(true);
      } else {
        console.log("Uploading...");
        if (allowed_extensions && allowed_extensions.split(",").includes(ext)) {
          //if selected file is a video, Upload it to firebase storage.
          if (allowed_extensions.split(",").includes("mp4")) {
            uploadToFirebase(selectedFiles[0]).then((data) => {});
          } else {
            // if selected file is not a video, upload it to our server.
            if (userdocument_id) {
              setLoading(true);
              updateUserDocument(
                userdocument_id,
                document_id,
                selectedFiles[0]
              ).then((data) => {
                setUserDocument(data);
                setLoading(false);
              });
            } else {
              setLoading(true);
              uploadUsertalentDocument(
                usertalent,
                document_id,
                selectedFiles[0],
                "",
                null,
                talent_id
              ).then((data) => {
                if (data.Error) alert(data.Error);
                else setUserDocument(data);
                setLoading(false);
              });
            }
          }
        } else {
          setUploadError(
            "This document type is not allowed. allowed types :" +
              allowed_extensions
          );
          setModalIsOpen(true);
        }
      }
    }
  };

  var allowed_extensions="mp4,mpeg4,mov";
  if(usertalent?.talent?.documents.length > 0)
      allowed_extensions = usertalent.talent.documents[0].extensions

    if(usertalent?.talent.id){
      talent_id = usertalent?.talent.id
    }else if(props.talent_id){
      talent_id = props.talent_id
    }
    else{
      console.log("talent_id cant be null")
    }

  useEffect(() => {
    setLoading(true);

    // if(talent_id){
    //   loadTalentDocument(talent_id).then((document)=>{
    //     // setDocument(document);
    //   })
    // }

    if(usertalent?.id || talent_id){
      loadUserTalentDocuments(usertalent?.id,talent_id).then((usertalentDocuments) => {
        setUserTalentDocuments(usertalentDocuments);
        if (usertalentDocuments.length > index) {
          var ud = usertalentDocuments[index];
          setUserDocument(ud);
          if (ud && ud.url != null && ud.url.includes("firebasestorage")) {
            setShowVideoMessage(true);
          }
        }
      });
    }
    

    if(!is_contest_page){
      loadNewestCourse(talent_id).then((course) => {
        setNewestCourse(course);
      });
    }
   

    setLoading(false);
  }, []);

  const handleClick = (file_path, url) => {
    // when this function called : we may uploading a document or view the document or delete the document

    if (editing || !userDocument) {
      
      setUploadModalError(false);
      setUploadModalOpen(true);
      editing = false;
      return;
    } else if (deleting) {
      deleting = false;
      if (userDocument) {
        if (userDocument.courses !== "[]") {
          setUploadError(
            "This document cannot be deleted beacause it is in a contest "
          );
          openModal();
        } else {
          setDeleteDocumentModalIsOpen(true);
          // if (window.confirm("Are you sure you want to delete this item ?")) {

          // }
        }
      }
    } else openModal(file_path, url);
  };

  const handleChange = (e) => {
    //e.stopPropagation();
    editing = true;
  };

  const handleDocumentDelete = (e) => {
    e.preventDefault();
    setDeleteDocumentModalIsOpen(false);
    deleteUserTalentDocument(userDocument).then((data) => {
      setUserDocument(null);
    });
  };

  const handleTextChange = (e) => {
    switch (e.target.name) {
      case "document_url":
        setUserDocumentUrl(e.target.value);
        break;

      default:
        break;
    }
  };

  const handleDelete = (e) => {
    //e.stopPropagation();
    deleting = true;
  };

  const handleEdit = (e) => {
    editing = true;
  };

  

  const selectFile = () => {
    inputFileRef.current.click();
    setUploadModalOpen(false);
  };

  const saveUserDocumentUrl = () => {
    if (!userDocumentUrl) {
      setUploadModalError("Please Enter the url");
      return;
    }

    setUploadModalError(false);
    setLoading(true);
    setUploadModalLoading(true);
    uploadUsertalentDocument(usertalent?.id, document_id, null, userDocumentUrl, userDocument?.id,talent_id).then(
      (data) => {
        console.log(data)
        if (data)
          if (data.Error) setUploadModalError(data.Error);
          else {
            setUserDocument(data);
            setUploadModalOpen(false);
            if(newest_course){
              setModalContestIsOpen(true)
            }
          }
        setUploadModalOpen(false);
        setLoading(false);
        setUploadModalLoading(false);
      }
    );
  };

  const saveFirebaseUrl = (url) => {
    setLoading(true);
    uploadUsertalentDocument(usertalent.id, document_id, null, url,userDocument?.id).then((data) => {
      if (data)
        if (data.Error) alert(data.Error);
        else {
          setUserDocument(data);
          setUploadModalOpen(false);
          if(newest_course){
            setModalContestIsOpen(true)
          }
        }
      setLoading(false);
    });
  };

  return (
    <div
      className={` ${multiple ? "col-6 " : "col-12"} mb-2 mt-2 padding-x-5 `}
    >
      <ModalContestSuggestion isOpen={modalContestIsOpen} setIsOpen={setModalContestIsOpen} course={newest_course} />
      <Modal
        closeTimeoutMS={500}
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="defaultModalLabel">
              Error
            </h4>
            <button
              type="button"
              onClick={closeModal}
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body ">
            <p className="text-color-primary">{upload_error}</p>
          </div>
        </div>
      </Modal>
      <Modal
        closeTimeoutMS={500}
        ariaHideApp={false}
        isOpen={upload_modal_is_open}
        onRequestClose={closeUploadModal}
        className="Modal submit-modal lan-rtl"
        overlayClassName="Overlay"
      >
        <div className="modal-content bg-upload-material">
          <div className="modal-header">
            <h4 className="modal-title" id="defaultModalLabel">
              {translate("Submit your material")}
            </h4>

            <button
              type="button"
              onClick={closeUploadModal}
              className="close lan-float-left lan-ml-0"
              data-dismiss="modal"
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body ">
            <div className="row ">
              {upload_enabled && (
                <div className="col-md-12 text-center mb-2 ">
                  {allowed_extensions.split(",").includes("mp4") ? (
                    <p>{translate("Upload from your device")}</p>
                  ) : (
                    <>
                      <p>{translate("Upload your document here")}</p>
                    </>
                  )}

                  <button
                    className="btn btn-primary btn-upload-file"
                    onClick={()=>{ selectFile() }}
                  >
                    <i className="fa fa-upload"></i> &nbsp;
                    {allowed_extensions.split(",").includes("mp4") ? (
                      <>{translate("Upload your video")}</>
                    ) : (
                      <>{translate("Upload")}</>
                    )}
                  </button>
                  <br />
                  <small>
                    {"Max Size:" +
                      " " +
                      Mg(max_file_size) +
                      " Mb  | " +
                      "Allowed formats:" +
                      " " +
                      allowed_extensions.replaceAll(",", ", ")}
                  </small>
                </div>
              )}

              {allowed_extensions.split(",").includes("mp4") && (
                <>
                  <div className="full-width col-md-12 orcontainer">
                    <div className="width-40p  ">
                      <hr className="full-width" />
                    </div>
                    <div className="width-20p kfont text-center mb-4">
                      {translate("Or")}
                    </div>
                    <div className="width-40p ">
                      <hr className="full-width" />
                    </div>
                  </div>
                  <div className="col-md-12 text-center ">
                    <div className="text-color-primary mb-5">
                      <p className="lan-text-right text-center ">
                        {HTMLReactParser(
                          translate(
                            "1- Upload your video to one of the following platforms: Youtube, Facebook, Instagram or Telegram"
                          )
                        )}
                      </p>
                      {/* <p className="lan-text-right">
                        {HTMLReactParser(
                          translate(
                            "2- Copy/Paste the link to the video and submit"
                          )
                        )}
                      </p> */}

                      <div className="sendurlcontainer">
                        <div className="padding-5">
                          <i className="fab fa-youtube font-34"></i>
                        </div>
                        <div className="padding-5 mb-2 big">
                          <input
                            type="text"
                            name="document_url"
                            onChange={handleTextChange}
                            className="form-control ltr mb-1 "
                            placeholder="https://"
                            // defaultValue={(userDocument && 
                            //   !userDocument.url.includes("firebasestorage") ) &&  userDocument.url }
                          />
                        </div>
                        <div className="padding-5">
                          <button
                            disabled={upload_modal_loading}
                            className="btn btn-primary "
                            onClick={saveUserDocumentUrl}
                          >
                            {translate("Save")}
                          </button>
                        </div>
                      </div>
                      {upload_modal_error && (
                        <>
                          <div className="alert alert-danger">
                            {upload_modal_error}
                          </div>
                        </>
                      )}
                      {upload_modal_loading && (
                        <>
                          <div className="alert alert-primary">
                            {translate("please wait...")}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>

      <ModalDeleteUserDocument
        handleDocumentDelete={handleDocumentDelete}
        isOpen={deleteDocumentModalIsOpen}
        setIsOpen={setDeleteDocumentModalIsOpen}
      />
      <div className="portfolio-item">
        {/* {loading && (
          <div
            className="loading-overlay-showing"
            data-loading-overlay
            data-plugin-options="{'hideDelay': 500, 'effect': 'floatRings'}"
          >
            <div className="loading-overlay">
              <div className="bounce-loader">
                <div className="cssload-float-rings-loader">
                  <div className="cssload-float-rings-inner cssload-one"></div>
                  <div className="cssload-float-rings-inner cssload-two"></div>
                  <div className="cssload-float-rings-inner cssload-three"></div>
                </div>
              </div>
            </div>
          </div>
        )} */}

        <input
                type="file"
                ref={inputFileRef}
                userdocument_id={userDocument && userDocument.id}
                name="upload_file"
                className="hide"
                onChange={handleFileChange}
              />


        <div className="relative">
          {duration && (
            <p className="duration">{toTime(Math.round(duration))}</p>
          )}
          <span className="thumb-info thumb-info-lighten thumb-info-no-borders border-radius-0">
            <span
              onClick={() => {
                handleClick(
                  userDocument && userDocument.file,
                  userDocument && userDocument.url
                );
              }}
              title={
                userDocument && userDocument.courses != "[]"
                  ? getCourseNames(userDocument && userDocument.courses)
                  : "View"
              }
              className={`thumb-info-wrapper border-radius-0 upload-icon-container ${
                multiple && " height-160 "
              } `}
            >
              {userDocument && isImage(userDocument.file) && (
                <img
                  src={
                    userDocument
                      ? userDocument.file
                      : "/assets/images/artist.jpg"
                  }
                  className=""
                  alt=""
                />
              )}
              {userDocument && isDocument(userDocument.file) && (
                <img
                  src="/assets/images/pdf_icon.png"
                  style={{ width: 100 }}
                  className=""
                  alt=""
                />
              )}

              {userDocument && isYoutube(userDocument.url) && (
                <img
                  src={`https://img.youtube.com/vi/${getYoutubeId(
                    userDocument.url
                  )}/0.jpg`}
                  className="img-fluid"
                  alt=""
                />
              )}

              {userDocument &&
                userDocument.url != null &&
                userDocument.url.includes("firebasestorage") && (
                  <>
                    <video
                      preload="metadata"
                      onLoadedMetadata={handleVideoMounted}
                      ref={handleVideoMounted}
                    >
                      <source src={userDocument.url} type="video/mp4" />
                    </video>
                  </>
                )}

              {!userDocument && !loading && (
                <img
                  src="assets/images/file_upload.png"
                  className="upload-icon border-radius-0  height-122"
                  alt=""
                />
              )}
              
              {userDocument && (
                <span className="infoicon">
                  {isYoutube(userDocument.url) ? (
                    <i className="fab fa-youtube"></i>
                  ) : allowed_extensions.split(",").includes("mp4") ? (
                    <i className="fa fa-play"></i>
                  ) : (
                    <i className="fa fa-search-plus"></i>
                  )}
                </span>
              )}
              <span className="thumb-info-action ">
                {!userDocument && (
                  <span
                    onClick={handleChange}
                    className={`thumb-info-action-icon bg-dark opacity-8 ${
                      userDocument ? "" : "no-doc"
                    }`}
                    title="Upload new file"
                  >
                    <i className="fa fa-plus"></i>
                  </span>
                )}

                {(userDocument && userDocument.courses === "[]") && (
                  <span
                    onClick={() => {
                      handleDelete();
                    }}
                    className="thumb-info-action-icon bg-dark opacity-8 "
                  >
                    <i className="fa fa-trash"></i>
                  </span>
                )}

                {(userDocument && userDocument.courses === "[]") &&
                  <span
                    onClick={() => {
                      handleEdit();
                    }}
                    className="thumb-info-action-icon bg-dark opacity-8 r-30 "
                  >
                    <i className="fa fa-edit"></i>
                  </span>
                }
                  
              </span>

              {progress > 0 && progress < 100 && (
                <>
                  <div className="progress-container">
                    <p>{progress} %</p>
                    <progress id="file" value={progress} max="100">
                      {progress} %
                    </progress>
                  </div>
                </>
              )}
            </span>
          </span>
        </div>
      </div>
      { showVideoMessage &&
       <p className="mt-2 kfont lan-text-right font-11" style={{lineHeight:1.8}}>
        <i className="fa fa-check color-green"></i> &nbsp;
        {parse(translate("your document is uploaded"))}
        <br />
        {/* <a href={userDocument && userDocument.url} target="_blank">
          <i className="fa fa-download"></i> {translate("click to download")}
        </a> */}
      </p>}
    </div>
  );
}
