import React, { Component } from 'react'
import PostsContent from './PostsContent';

//loadProfile();

export default class Posts extends Component {


    render() {
        let id = this.props.match.params.id;

        return (
            <div>
                <PostsContent id={id} />

            </div>
        )

    }
}
