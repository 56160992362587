import React, { useEffect, useReducer, useState } from 'react'
import {  getDefaultValue, getUser, isDocument, isImage, isVideo,setPublicVoteCookie,getPublicVoteCookie } from '../utils';
import {  loadNewestCourse, loadPublicCourseDocuments, loadPublicDegree, loadTalent, savePublicDegree, saveQuestionDegree } from '../utils/Network';
import Modal from 'react-modal';
import ReactStars from "react-rating-stars-component";
import UrlEmbed from '../components/UrlEmbed';
import { useStateValue } from '../StateProvider';
import AudienceLoginModal from '../components/user/AudienceLoginModal';


export default function VideoPanel({ talent_id, callback }) {

    const [loading, setLoading] = useState(false);
    const [current_degree, setDegree] = useState(null);

    const [, forceUpdatePage] = useReducer((x) => x + 1, 0);
    const [modal_file, setModalFile] = useState(null);
    const [modal_url, setModalUrl] = useState(null);
    const [modal_artist, setModalArtist] = useState(null);
    const [modal_loading, setModalLoading] = useState(null);
    const [courseDocuments, setCourseDocuments] = useState(null);
    const [selectedCourseDocument, setSelectedCourseDocument] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);
    const [talent, setTalent] = useState(false);
    const [course, setCourse] = useState(false);
    const [force_update, forceUpdate] = useReducer((x) => x + 1, 0);
    const [user, setUser] = useState(null);
    const [saved, satSaved] = useState(null);
    const [saving, satSaving] = useState(null);
    const [no_course, setNoCourse] = useState(null);

    useEffect(() => {
        setLoading(true)


        if (!talent_id)
            loadPublicCourseDocuments({ talent: talent_id, size: 3 }).then(courseDocuments => {
                setCourseDocuments(courseDocuments)
                setLoading(false)
            })

        if (talent_id) {
            loadTalent(talent_id).then(talent => {
                setTalent(talent)
                callback(talent)
            })

            loadNewestCourse(talent_id,1).then(course => {
                setCourse(course)
                if (course) {
                    loadPublicCourseDocuments({ talent: talent_id, course: course.id, page: 1, size: 40 }).then(courseDocuments => {
                        setCourseDocuments(courseDocuments)
                        setLoading(false)

                    })
                }
                else {
                    setNoCourse(true)
                    setLoading(false)
                }


            })
        }

        setUser(getUser())

    }, [])

    
    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x=>x.key == str );
    
        if (translate) return getDefaultValue(translate);
        else return "#" + str;
      };

    const ratingChanged = (newRating) => {
        satSaved(false)
        if(!user) {
            setLoginModalIsOpen(true)
            forceUpdate()
        }
        else 
        saveDegree(newRating * 2)

    };


    const saveDegree = (degree) => {
        if (!selectedCourseDocument) return;

        satSaving(true)
        satSaved(0)
        var cookie = getPublicVoteCookie()
        if(!cookie)
            cookie = setPublicVoteCookie();

        savePublicDegree(current_degree && current_degree.id, degree, selectedCourseDocument.course.id, selectedCourseDocument.userdocument.usertalent.id,cookie).then(data => {
            satSaving(false)
            satSaved(true)
        })
    }

    

    const PrepareModal = (courseDocument, file, url) => {

        if (url === "undefined") url = null

        satSaved(false)
        setModalLoading(true)
        setModalFile(file)

        setModalUrl(url)
        setSelectedCourseDocument(courseDocument)
        setModalArtist(courseDocument.userdocument.usertalent.user.profile)

        if(courseDocument?.userdocument?.usertalentgallery && courseDocument?.userdocument?.usertalentgallery.length>0){
            var usg = courseDocument?.userdocument?.usertalentgallery[0]
            setModalUrl(usg.url)

        }


        loadPublicDegree(courseDocument.course.id, courseDocument.userdocument.usertalent.id).then(degree => {
            setDegree(degree)
            setModalLoading(false)
        }).catch(() => {
            setModalLoading(false)
        })
        forceUpdatePage()

        setModalIsOpen(true)


    }



    const closeModal = () => {
        setModalIsOpen(false)

    }

    const setLoggedIn = (user) => {

        //var user = getUser()
        setUser(user)
        setModalLoading(true)
        if (selectedCourseDocument) {
            loadPublicDegree(selectedCourseDocument.course.id, selectedCourseDocument.userdocument.usertalent.id).then(degree => {
                setDegree(degree)
                setModalLoading(false)
            }).catch(() => {
                setModalLoading(false)
            })
        }

        forceUpdatePage()
     }

    return (
        <div className="">
            <AudienceLoginModal key={force_update} setLoggedIn={setLoggedIn} isOpen={loginModalIsOpen} />
            <Modal
                closeTimeoutMS={500}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="Modal "
                ariaHideApp={false}
                overlayClassName="Overlay"
            >
                <div className="modal-content">
                    <div className="modal-header height-75 lan-rtl">
                        <img className="rounded-circle user-logo" width="40" height="40" alt="" src={modal_artist && modal_artist.avatar ? modal_artist.avatar : "/assets/images/user.png"}></img>
                        <h4 className="modal-title artist-name-rate lan-mr-5">{translate('Rate')} {modal_artist && modal_artist.first_name}</h4>
                        <div className="stars-container lan-ltr">
                            {!modal_loading &&
                                <>

                                    <ReactStars
                                        key={force_update}
                                        count={5}
                                        onChange={ratingChanged}
                                        size={42}
                                        isHalf={true}
                                        classNames="react-stars lan-mr-5"
                                        value={current_degree && current_degree.degree / 2}
                                        emptyIcon={<i className="far fa-star"></i>}
                                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                                        fullIcon={<i className="fa fa-star"></i>}
                                        activeColor="#e6f51a"
                                    />
                                    <div className="saving-star">
                                        {saving && "saving..."}
                                        {saved && translate("saved")}
                                    </div>
                                </>
                            }
                        </div>
                        <button type="button" className="close" onClick={closeModal}></button>
                    </div>
                    <div className="modal-body row">

                        <div className="col-md-12 file-container flex-center">
                            {modal_loading &&
                                <>
                                    <div className="bounce-loader">
                                        <div className="bounce1"></div>
                                        <div className="bounce2"></div>
                                        <div className="bounce3"></div>
                                    </div>
                                </>
                            }
                            {modal_url && <UrlEmbed url={modal_url} />}
                            {!modal_url &&
                                <>
                                    {modal_file && isImage(modal_file) &&
                                        <img src={modal_file} className="img-fluid  " alt="" />
                                    }
                                    {modal_file && isVideo(modal_file) &&
                                        <video controls width="100%" playsInline >
                                            <source src={modal_file} type="video/mp4" />
                                        </video>
                                    }
                                    {modal_file && isDocument(modal_file) &&
                                        <a href={modal_file} target="_blank">
                                            <img src="assets/images/pdf_icon.png" className="img-fluid border-radius-0 " alt="" />
                                        </a>

                                    }
                                </>

                            }

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light pull-right kfont rtl-pull-left" onClick={closeModal}>{translate('Close')}</button>
                    </div>
                </div>
            </Modal>

            <div className=" mt-2 mx-0 row mb-4">
                {/* <h2 className="mb-1 text-course lan-text-right">{course && getDefaultName(course.names)}</h2>
                {course && <>
                    <p className="hide text-course">Start : {getYearMonth(course.startdate)} , End : {getYearMonth(course.enddate)}</p>
                    <p className="text-course lan-text-right lan-rtl">{getDefaultDescription(course.names)}</p>
                </>
                }
                {no_course && <h3 className="lan-rtl lan-text-right kfont " style={{fontSize:20}}>
                    {parse(translate("There is no contest available"))}
                </h3>} */}
                <div className="coursedocuments">
                    {courseDocuments && courseDocuments.map((item, index) => (
                        <div key={index} className="mb-3 ">
                            <div className="portfolio-item " onClick={() => { PrepareModal(item, item.userdocument.file, item.userdocument.url) }} >
                                <span className={`thumb-info  thumb-info-lighten circle-grandparent `}>
                                    <span className="thumb-info thumb-info-centered-icons ">
                                        <span className="thumb-info-wrapper tusi-border circle-public ">

                                            {item.userdocument.usertalent.user.profile.avatar &&

                                                <img src={item.userdocument.usertalent.user.profile.avatar} className="document-user-img" alt="" />

                                            }

                                            {!item.userdocument.usertalent.user.profile.avatar &&
                                                <>
                                                    {item.userdocument && item.userdocument.file && isImage(item.userdocument.file) &&
                                                        <img src={item.userdocument.file} className="jury-media" alt="" />
                                                    }
                                                    {item.userdocument && item.userdocument.file && isVideo(item.userdocument.file) &&
                                                        <video height="200"  >
                                                            <source src={item.userdocument.file} type="video/mp4" />
                                                        </video>
                                                    }

                                                    {item.userdocument && item.userdocument.file && isDocument(item.userdocument.file) &&
                                                        <img src="assets/images/pdf_icon.png" className="img-fluid border-radius-0 full-width jury-media" alt="" />
                                                    }
                                                </>
                                            }

                                            <span className="circle-artist-name">
                                                {item.userdocument.usertalent.user.profile.first_name}
                                            </span>

                                            <span className="thumb-info-action">
                                                <a href="#" data-toggle="modal" data-target="#defaultModal">
                                                    <span className="thumb-info-action-icon thumb-info-action-icon-light"><i className="fas fa-play-circle text-dark text-dark"></i></span>
                                                </a>

                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </div>
                        </div>

                    ))}
                </div>
                {loading &&
                    <>
                        <div className="bounce-loader">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                        </div>
                    </>}
                <div className="clear"></div>
            </div>


        </div>

    )

}
