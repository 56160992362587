import React, { useEffect, useState } from "react";
import parse from "html-react-parser";

import {
  loadCountry,
  loadMessageReplies,
  loadProfile,
  loadSupportMessage,
  loadSupportMessages,
  saveReply,
} from "../utils/Network";
import { getDefaultValue, readLanguageFile } from "../utils";
import { useStateValue } from "../StateProvider";

export default function MessageDetailsContent({ id }) {
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [done, setDone] = useState(false);

  const [profile, setProfile] = useState(null);
  const [country, setCountry] = useState(null);
  const [livingcountry, setLivingCountry] = useState(null);
  const [current_language, setCurrentLanguage] = useState(null);
  
  const [reply, setReply] = useState("");
  const [replies, setReplies] = useState(null);
  const [message, setMessage] = useState(null);

  const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x=>x.key.toLowerCase() == str.toLowerCase() );
    
        if (translate) return getDefaultValue(translate);
        else return "#" + str;
      };

  useEffect(() => {
    setLoading(true);
    
    loadProfile().then((profile) => {
      setProfile(profile);
      setLoading(false);
      if (profile && profile.country_id) {
        loadCountry(profile.country_id).then((country) => {
          setCountry(country);
        });
      }
      if (profile && profile.livingcountry_id) {
        loadCountry(profile.livingcountry_id).then((country) => {
          setLivingCountry(country);
        });
      }
    });

    loadSupportMessage(id).then((message) => {
      setMessage(message);
    });

    loadMessageReplies(id).then((response) => {
      setReplies(response.results);
    });
  }, []);

  const saveChanges = (e) => {
    if(reply==""){
      setError(translate('Please Enter Reply'))
      return;
    }

    setLoading(true)
    setDone(false)

    saveReply(id,reply).then((response)=>{
      var t = [response,...replies];
      setReplies(t)
      
      setLoading(false)
      setDone(true)
    })
  }

  const handleChange = (e) => {
    // eslint-disable-next-line default-case
    switch (e.target.name) {
        case "reply":
            setReply(e.target.value);
            break;
       
    }
  }

  return (
    <div>
      <div role="main" className="main">
        <section className="page-header pb-0 section-with-shape-divider page-header-modern bg-color-dark-scale-1 page-header-lg mb-0 pb-4  page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-7 bg-artist">
          <div className="container">
            <div className="row">
              <div className="col-md-12 align-self-center p-static order-2 text-center">
                <div className="d-flex justify-content-center mb-4">
                  <div className="profile-image-outer-container">
                    <div className="profile-image-inner-container bg-color-primary">
                      <img
                        alt={profile && profile.first_name}
                        src={
                          profile && profile.image
                            ? profile.image
                            : "assets/images/user.png"
                        }
                      />
                    </div>
                  </div>
                </div>

                <h1 className="font-weight-bold text-light mb-20">
                  {profile && profile.first_name + " "}
                  {profile && profile.last_name}
                </h1>
                <div
                  className=" appear-animation mb-20"
                  data-appear-animation="fadeIn"
                  data-appear-animation-delay="600"
                  data-plugin-options="{'accY': -150}"
                >
                  <ul className="social-icons text-center rtl-text-center ">
                    {profile && profile.website ? (
                      <>
                        <li className="social-icons-website">
                          <a
                            href={profile.website}
                            target="_blank"
                            rel="noreferrer"
                            title="Website"
                          >
                            <i className="fa fa-home"></i>
                          </a>
                        </li>
                      </>
                    ) : null}
                    {profile && profile.facebook ? (
                      <>
                        <li className="social-icons-facebook">
                          <a
                            href={profile.facebook}
                            target="_blank"
                            rel="noreferrer"
                            title="Facebook"
                          >
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                      </>
                    ) : null}
                    {profile && profile.instagram ? (
                      <>
                        <li className="social-icons-instagram">
                          <a
                            href={profile.instagram}
                            target="_blank"
                            rel="noreferrer"
                            title="Instagram"
                          >
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                      </>
                    ) : null}
                    {profile && profile.youtube ? (
                      <>
                        <li className="social-icons-youtube">
                          <a
                            href={profile.youtube}
                            target="_blank"
                            rel="noreferrer"
                            title="Youtube"
                          >
                            <i className="fab fa-youtube"></i>
                          </a>
                        </li>
                      </>
                    ) : null}
                    {profile && profile.snapchat ? (
                      <>
                        <li className="social-icons-snapchat">
                          <a
                            href={profile.snapchat}
                            target="_blank"
                            rel="noreferrer"
                            title="Snapchat"
                          >
                            <i className="fab fa-snapchat"></i>
                          </a>
                        </li>
                      </>
                    ) : null}
                    {profile && profile.spotify ? (
                      <>
                        <li className="social-icons-spotify">
                          <a
                            href={profile.spotify}
                            target="_blank"
                            rel="noreferrer"
                            title="Spotify"
                          >
                            <i className="fab fa-spotify"></i>
                          </a>
                        </li>
                      </>
                    ) : null}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="shape-divider shape-divider-bottom">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 1920 136"
              preserveAspectRatio="xMinYMin"
            >
              <path
                d="M 0 0.11 L 0 136 L 1920 136 L 1920 1.9 C 1650.0482 79.0955 1327.0648 134 980 134 C 615.6218 134 277.7878 84.5059 0 0.11 Z"
                fill="#212529"
              />
            </svg>
          </div>
        </section>
        <div className="container pt-3 pb-2">
          <div className="row pt-2 lan-rtl">
            <div className="col-lg-3">
              <ul className="list list-icons list-primary list-borders text-2">
                <li className="lan-text-right ">
                  <i
                    className={`fas fa-caret-${
                      current_language === "SO" ? "right" : "left"
                    } sidebar-list-style`}
                  ></i>{" "}
                  <strong className="text-color-primary lan-mr-25 kfont">
                    <a href="dashboard">{translate("My Profile")}</a>
                  </strong>
                </li>
                <li className="lan-text-right ">
                  <i
                    className={`fas fa-caret-${
                      current_language === "SO" ? "right" : "left"
                    } sidebar-list-style`}
                  ></i>{" "}
                  <strong className="text-color-primary lan-mr-25 kfont">
                    <a href="dashboard">{translate("Manage Profile")}</a>
                  </strong>
                </li>
                <li className="lan-text-right ">
                  <i
                    className={`fas fa-caret-${
                      current_language === "SO" ? "right" : "left"
                    } sidebar-list-style`}
                  ></i>{" "}
                  <strong className="text-color-primary lan-mr-25 kfont">
                    <a href="contests">{translate("Contests")}</a>
                  </strong>
                </li>
                <li className="lan-text-right ">
                  <i
                    className={`fas fa-caret-${
                      current_language === "SO" ? "right" : "left"
                    } sidebar-list-style`}
                  ></i>{" "}
                  <strong className="text-color-primary lan-mr-25 kfont">
                    <a href="support">{translate("Support")}</a>
                  </strong>
                </li>
              </ul>
            </div>
            <div className="col-lg-9">
              <section
                className="section  py-2  m-0 border-0 border-top-primary mb-4 brb-5"
              >
                <div className="container ">
                  <div className="row align-items-center justify-content-center">
                    <div className="col">
                      

                      <div className="row lan-rtl">
                        <div className="col-md-12 col-sm-12 pr-4 pl-4 lan-text-right">
                          <div className="row form-group p-2">
                            <textarea name="reply" onChange={handleChange} placeholder={translate("Write your reply here ...")} className="form-control"></textarea>
                          </div>
                          <div className="row pull-right  form-group p-1">
                            <button
                              type="button"
                              onClick={saveChanges}
                              disabled={loading}
                              className="btn btn-primary width-200  btn-block text-uppercase  font-weight-bold text-3  py-10"
                              data-loading-text="Loading..."
                            >
                              <i className="fas fa-reply op-7"></i>&nbsp;
                              {translate("Send Reply")}
                            </button>
                          </div>
                        </div>
                      </div>
                      {loading && <div className="alert alert-info">Please wait...</div>}
                      {error && <div className="alert alert-danger">{error}</div>}
                      {done && <div className="alert alert-success">Reply Saved Successfully</div>}
                    </div>
                  </div>
                </div>
              </section>

              {replies?.map((reply) => (
                <section
                  key={reply.id}
                  className="section  py-2  m-0 border-0 border-top-primary mb-4 brb-5"
                >
                  <div className="container ">
                    <div className="row align-items-center justify-content-center">
                      <div className="col">
                        <div className="row mb-4 px-2 ">
                          <div className="col-md-12 px-0 border-bottom border-bottom-primary ">
                            <div className="pull-right mt-1">
                              &nbsp;&nbsp;
                              <i className="fa fa-clock"></i>&nbsp;
                              {reply.updated_at
                                .replace("T", " ")
                                .replace("Z", " ")}
                            </div>
                          </div>
                        </div>

                        <div className="row lan-rtl">
                          <div className=" p-2 lan-text-right">
                            {parse(reply.message)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              ))}

              {message && (
                <section
                  key={message.id}
                  className="section  py-2  m-0 border-0 border-top-primary mb-4 brb-5"
                >
                  <div className="container ">
                    <div className="row align-items-center justify-content-center">
                      <div className="col">
                        <div className="row mb-4 px-2 ">
                          <div className="col-md-12 px-0 border-bottom border-bottom-primary ">
                            <h2 className=" font-weight-normal text-6 mb-0 title-cont lan-text-right ">
                              <a href={`support/${message.id}`}>
                                {message.user.profile.first_name}
                                &nbsp; {message.user.profile.last_name}
                              </a>
                            </h2>
                            <div className="pull-right mt-1">
                              &nbsp;&nbsp;
                              <i className="fa fa-clock"></i>&nbsp;
                              {message.updated_at
                                .replace("T", " ")
                                .replace("Z", " ")}
                            </div>
                          </div>
                        </div>

                        <div className="row lan-rtl">
                          <div className="p-2 lan-text-right">
                            <p>{message.title}</p>
                            <p>{message.message}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
