import React, { useEffect, useRef, useState } from 'react'
import { useStateValue } from '../../StateProvider';
import { getDefaultValue } from '../../utils';
import { loadTalentDocument, loadUserTalentDocuments } from '../../utils/Network';
import ContestParticipation from '../contest/ContestParticipation';
import StepIndicator from '../contest/StepIndicator';
import UploadDocumentForm from '../forms/talent/UploadDocumentForm';
import ProfileForm from '../forms/user/ProfileForm';
import SelectTalent from '../select/SelectTalent';
import LoadingOverlay from '../shared/LoadingOverlay';
import UserDocumentPreview from '../talent/UserDocumentPreview';

function AddUserTalentModal() {

    const btnCloseModal = useRef(null);

    const [talent, setTalent] = useState(null);
    const [step, setStep] = useState(0);
    const [error, setError] = useState(null);
    const [upload_enabled, setUploadEnabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userDocument, setUserDocument] = useState(false);


    useEffect(() => {
        setLoading(true);
        setUserDocument(null)

        // if(talent){
        //   loadTalentDocument(talent).then((document)=>{
        //     console.log(document)
        //     // setDocument(document);
        //   })
        // }

        if (talent) {
            loadUserTalentDocuments(null, talent).then((usertalentDocuments) => {
                setLoading(false);

                if (usertalentDocuments.length > 0) {
                    var ud = usertalentDocuments[0];
                    setUserDocument(ud);
                    setUploadEnabled(false)
                    // if (ud && ud.url != null && ud.url.includes("firebasestorage")) {
                    //     setShowVideoMessage(true);
                    // }
                }
                else {
                    setUploadEnabled(true)
                }
            });
        }
    }, [talent]);


    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x => x.key == str);

        if (translate) return getDefaultValue(translate);
        else return "#" + str;
    };

    const _next = () => {

        setError("")
        if (_canGoNext()) {
            if (step === 2) {
                setStep(0)
            }
            else {
                setStep(step + 1);
            }
        }


    }

    const _finish = () => {
        btnCloseModal.current.click()
    }

    const _canGoNext = () => {
        setError("")
        if (step === 0) {
            if (!talent) {
                setError("Please select a talent")
                return false;
            }
        }
        else if (step === 1) {
            if (!userDocument) {
                setError("Please add a document")
                return false;
            }
        }
        return true;
    }


    const _back = () => {
        if (step === 0) {
            setStep(2)
        }
        else {
            setStep(step - 1);
        }
    }


    return (
        <div className="modal fade lan-rtl " id="addUserTalentModal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog w-700 ">
                <div className="modal-content bg-upload-material">
                    <div className="modal-header no-border pb-0">
                        <button type="button" ref={btnCloseModal} className="close lan-float-left lan-ml-0" data-dismiss="modal" aria-hidden="true">&times;</button>
                    </div>
                    <div className="modal-body padding-top-0">
                        <div className="steps">
                            <div className={`step step-select-talent ${step === 0 ? 'step-active' : ""}`}>
                                <h2>{translate("Please choose your talent")}</h2>
                                <SelectTalent
                                    onselect={(name, value) => {
                                        setError("")
                                        setTalent(value)
                                        setStep(step + 1)
                                    }}
                                />
                            </div>
                            <div className={`step  ${step === 1 ? 'step-active' : ''}`}>
                                {userDocument && <UserDocumentPreview userDocument={userDocument} setUserDocument={(val) => { setUserDocument(val); if (!val) { setUploadEnabled(true) } }} />}
                                {upload_enabled && <UploadDocumentForm talentId={talent} onSuccess={(userDocument) => { setError(""); setUserDocument(userDocument); if (userDocument) { setUploadEnabled(false) } }} />}
                                <LoadingOverlay key={loading + "ld"} loading={loading} />
                            </div>
                            <div className={`step ${step === 2 ? 'step-active' : ''}`}>
                                <ContestParticipation key={talent} talentId={talent} />
                            </div>
                        </div>
                    </div>
                    <div className="footer-flex-center">
                        {error &&
                            <>
                                <div className="alert alert-danger text-2">
                                    {translate(error)}
                                </div>
                            </>
                        }
                        <StepIndicator index={step} />
                        <hr className="full-width my-3" />
                        <div className="modal-step-actions">
                            <div>
                                {step > 0 && (
                                    <button className="btn btn-previous" onClick={_back}>{translate('Back')}</button>

                                )}
                            </div>
                            <div>

                                {step < 2 && (
                                    <button className="btn btn-outlined" onClick={_next}>{translate('Next')}</button>
                                )}
                                {step === 2 && (
                                    <button className="btn btn-outlined" onClick={_finish}>{translate('Finish')}</button>
                                )}
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default AddUserTalentModal
