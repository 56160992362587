import React, { useEffect, useState } from 'react'
import { useStateValue } from '../../StateProvider';
import {  getDefaultValue } from '../../utils';
import { loadPages } from '../../utils/Network';
import parse from 'html-react-parser'

export default function PageContent({ pageItem,url }) {

    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(null);
    

    useEffect(() => {
        setLoading(true)
        //document.title = 'KurdishTalent |'
        if(pageItem){
            setPage(pageItem);
            setLoading(false)
            document.title = 'KurdishTalent | '+ pageItem.title
        }
        else{
            loadPages(url).then(pages => {
                if(pages.length>0){
                    setPage(pages[0]);
                    document.title = 'KurdishTalent | '+ pages[0].title
                    setLoading(false)
                }
                
            })
        }
       
        
    }, [])

    
    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x=>x.key == str );
    
        if (translate) return getDefaultValue(translate);
        else return "#" + str;
      };


    return (
        <div id="main" role="main" className="main">

            <section className="page-header pb-0 section-with-shape-divider page-header-modern bg-color-dark-scale-1 page-header-lg  pb-4  page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-7  ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 align-self-center order-1">
                            <ul className="breadcrumb d-block text-center lan-rtl">
                                <li><a href="" className="kfont">{translate('Home')}</a></li>
                                <li className={"active kfont"}>{page ? page.title : "Page" }</li>

                            </ul>
                        </div>

                        <div className="col order-3 appear-animation animated fadeInUp appear-animation-visible" data-appear-animation="fadeInUp" data-appear-animation-delay="0" data-appear-animation-duration="1s" >
                            <hr className=" slide-line blackhr" />
                        </div>
                    </div>
                </div>

            </section>

            <div className="container py-4">
                {loading &&
                    <>
                        <div className="bounce-loader">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                        </div>
                    </>
                }

                {!loading &&
                    
				<div className="container py-4">

                <div className="row">
						<div className="col">

							<h2 className="font-weight-normal text-7 mb-2">{page ? page.title : loading ? "" :"Page not found"}</h2>
							<p className="lead lan-rtl lan-text-right kfont">{page ? parse(page.description) : ""}</p>							

						</div>

					</div>


				</div>


                }


            </div>

        </div>
    )

}
