import React, {  useEffect, useState } from 'react'
import './Dashboard.css'
import { loadProfile, uploadProfileImage } from '../utils/Network';
import {  getDefaultValue, logout, readLanguageFile } from '../utils';
import { useLocation } from 'react-router';
import RefereePanel from './RefereePanel';
import { useStateValue } from '../StateProvider';
import SelectRated from './sections/contest/SelectRated';

//loadProfile();

export default function Referee() {
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [profileImageLoading, setProfileImageLoading] = useState(false);
    const [selected_course, setSelectedCourse] = useState(false);
    const [selected_rating, setSelectedRating] = useState(0);

    useEffect(() => {
        setLoading(true)


        loadProfile().then(profile => {
            setProfile(profile);
            setLoading(false)
            if (profile) {

                if (profile.role !== "referee") {
                    window.location.href = "signout"
                }


            }
            else {
                logout()
                window.location.href = "signin"
            }
        });



    }, [])
    
    const handleChanges = (e) => {
        var file = e.target.files[0]

        if (file) {
            //setFile(file);
            setProfileImageLoading(true)
            uploadProfileImage(profile.id, file).then((data) => {
                console.log(data)
                if (data.image) {
                    setProfile(data)
                }
                setProfileImageLoading(false)
            });
        }

    }

    const onValue = (name, value) => {

        switch (name) {
            case "course":
                setSelectedCourse(value)
                break;
            case "rating":
                setSelectedRating(value)
                break;

            default:
                break;
        }

    }

    return (
        <div>
            <div id="main" role="main" className="main">

                <section className="page-header pb-0 section-with-shape-divider page-header-modern bg-color-dark-scale-1 page-header-lg mb-0 pb-4  page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-7 bg-artist">
                    <div className="container">
                        <div className="row">

                            <div className="col-md-12 align-self-center p-static order-2 text-center">

                                <div className="d-flex justify-content-center mb-4">
                                    <div className="profile-image-outer-container">
                                        <div className="profile-image-inner-container bg-color-primary">

                                            <img alt={profile && profile.first_name} src={profile && profile.image ? profile.image : "assets/images/user.png"} />

                                            <span className="profile-image-button bg-color-dark">
                                                <i className="fas fa-camera text-light"></i>
                                            </span>

                                            {profileImageLoading && <>
                                                <div className="loading-overlay-showing border-radius-50 opacity-8" data-loading-overlay data-plugin-options="{'hideDelay': 500, 'effect': 'floatRings'}">
                                                    <div className="loading-overlay border-radius-50">
                                                        <div className="bounce-loader">
                                                            <div className="cssload-float-rings-loader">
                                                                <div className="cssload-float-rings-inner cssload-one"></div>
                                                                <div className="cssload-float-rings-inner cssload-two"></div>
                                                                <div className="cssload-float-rings-inner cssload-three"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>}
                                        </div>
                                        <input type="file" id="file" name="profileimage" onChange={handleChanges} className="profile-image-input" />
                                    </div>
                                </div>

                                <h1 className="font-weight-bold text-light mb-20">
                                    {profile && profile.first_name + " "}
                                    {profile && profile.last_name}

                                </h1>
                                <div className="col-md-12 jurytop lan-rtl">
                                    {/* <div className="mr-2 mb-2">
                                        <SelectCompetition placeholder={translate('Select a Contest')} onValue={onValue} />
                                    </div> */}
                                    <div className="mr-2 mb-2">
                                        <SelectRated course={selected_course} onValue={onValue} />
                                    </div>
                                    
                                </div>

                            </div>


                        </div>
                    </div>
                    <div className="shape-divider shape-divider-bottom" >
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 136" preserveAspectRatio="xMinYMin">
                            <path d="M 0 0.11 L 0 136 L 1920 136 L 1920 1.9 C 1650.0482 79.0955 1327.0648 134 980 134 C 615.6218 134 277.7878 84.5059 0 0.11 Z" fill="#212529" />
                        </svg>
                    </div>
                </section>

                <div className="container pt-3 pb-2">

                    <RefereePanel key={selected_course + selected_rating} course={selected_course} is_rated={selected_rating} />

                </div>

            </div>

        </div>
    )
}
