import React, { useEffect } from 'react'
import PageHeaderLight from '../../../components/shared/PageHeaderLight';
import ProfileInformationLinear from '../../../components/shared/ProfileInformationLinear';
import WidgetAddDocument from '../../../components/widgets/WidgetAddDocument';
import WidgetMyVideos from '../../../components/widgets/WidgetMyVideos';
import WidgetUpdateProfile from '../../../components/widgets/WidgetUpdateProfile';
import { useStateValue } from '../../../StateProvider';
import { getDefaultValue } from '../../../utils';

import DashboardAside from './DashboardAside';


export default function Dashboard() {

    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find((x) => x.key == str);

        if (translate) return getDefaultValue(translate);
        else return "#" + str;
    };

    useEffect(() => {

    }, [])

    return (
        <div>
            <div role="main" className="main page-background">
                {/* <UserHeader /> */}
                <PageHeaderLight />
                {/* <ImageCrop /> */}
                <div className="container pt-3 pb-2">

                    <div className="row pt-2 lan-rtl">
                        <DashboardAside />
                        <ProfileInformationLinear />
                        <div className="col-lg-9 ">
                            <h2 className="font-weight-bold line-height-3 text-7 mb-0">{translate("Dashboard")}</h2>
                            <p className="mb-4 pb-2 lan-text-right" >{translate("Welcome to KurdishTalent dashboard")}</p>
                            {/* <hr className="my-5" /> */}
                            <WidgetMyVideos />
                            <div className="widgets">
                                <WidgetAddDocument />
                                <WidgetUpdateProfile />
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )

}
