import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import parse from "html-react-parser";

import { useStateValue } from '../StateProvider'
import { getDefaultName, translate, userImage } from '../utils'
import { ApiHttpService } from 'utils/http/api'
import UserTalentGallery from 'components/contest/history/UserTalentGallery';
import UserGallery from 'components/contest/history/UserGallery';

function People({ profile }) {

    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)
    const [{ translations }] = useStateValue();

    useEffect(() => {

        _getData()

    }, [])

    const _getData = () => {
        if (!id && !profile) return
        if (profile) {
            document.title += ' | ' + profile?.first_name + " " + profile?.last_name;
            setData(profile)
        }
        else {
            setLoading(true)
            ApiHttpService.loadPublicProfile(id).then(data => {
                if (data.id) {
                    document.title += ' | ' + data?.first_name + " " + data?.last_name;
                    setData(data)
                }
                setLoading(false)
            })
        }

    }



    return (
        <div>
            <div id="main" role="main" className="main bg-blog">
                <section className="page-header pb-0  page-header-modern page-header-lg mb-0 pb-4  "  >
                    <div className="container ">
                        <div className="row ">

                            {/* <div className="col-md-12 align-self-center mb-20">
                                <ul className="breadcrumb d-block text-center lan-rtl">
                                    <li><a href="" className="kfont">{translate(translations, 'Home')}</a></li>
                                    {/* <li className={!id && "active kfont"}>{translate(translations,'People')}</li>
                                    {data &&
                                        <li className="active kfont">{data?.first_name} {data?.last_name}</li>
                                    }

                                </ul>
                            </div> */}

                            <div className="col-md-12 text-center d-flex-center ">
                                <h1 className="font-weight-bold text-light mb-20 lan-full-width text-center">
                                    {data?.first_name} {data?.last_name}
                                </h1>
                                <p>
                                    {getDefaultName(data?.country?.names)} {getDefaultName(data?.city?.names)}
                                </p>
                                <p className="">
                                    {parse(data?.bio ? data?.bio : "")}
                                </p>

                            </div>
                            <div className="col-md-12 flex-center ">
                                {data?.image && (
                                    <span className={`thumb-info  thumb-info-lighten circle-big padding-5 `}>
                                        <span className="thumb-info thumb-info-centered-icons ">
                                            <span className="thumb-info-wrapper tusi-border circle-people ">
                                                <img src={userImage(data)} className="profile-big-img" alt="" />
                                            </span>
                                        </span>
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
                <div className="container pb-2 mb-5">
                    <UserTalentGallery data={data} />
                </div>
                <div className="container pb-2 mb-5">
                    <UserGallery data={data} />
                </div>

            </div>



        </div>


    )
}

export default People