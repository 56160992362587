import React, { useEffect, useState } from 'react'
import { useStateValue } from '../../StateProvider';
import { getCurrentLanguage, getDefaultValue, logout } from '../../utils';
import { loadProfile, uploadProfileImage } from '../../utils/Network';
import ModalCompleteProfile from '../dialogs/ModalCompleteProfile';

function UserHeader() {
  const [profile, setProfile] = useState(null);
  const [role, setRole] = useState(null);
  const [profileImageLoading, setProfileImageLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [complete_profile_open, setCompleteProfileOpen] = useState(false);

  useEffect(() => {
    setLoading(true);



    loadProfile().then((profile) => {
      setProfile(profile);
      setLoading(false);
      if (profile) {
        setRole(profile.role);
      } else {
        logout();
        window.location.href = "signin";
      }
    });
  }, []);

  const [{ translations }] = useStateValue();
  const translate = (str) => {
    if (!translations) return str;
    var translate = translations.find(x => x.key == str);

    if (translate) return getDefaultValue(translate);
    else return "#" + str;
  };

  const handleChanges = (e) => {
    var file = e.target.files[0];

    if (file) {
      //setFile(file);
      setProfileImageLoading(true);
      uploadProfileImage(profile.id, file).then((data) => {
        if (data.image) {
          setProfile(data);
        }
        setProfileImageLoading(false);
      });
    }
  };
  return (
    <section className="page-header pb-0 section-with-shape-divider page-header-modern bg-color-dark-scale-1 page-header-lg mb-0 pb-4  page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-7 bg-artist">
      {profile && !profile.country_id && (
        <ModalCompleteProfile isOpen={complete_profile_open} message={'Please complete your profile'} setIsOpen={(value) => { setCompleteProfileOpen(value) }} profile={profile} />
      )}
      <div className="container">
        <div className="row">
          <div className="col-md-12 align-self-center p-static order-2 text-center">
            <div className="d-flex justify-content-center mb-4">
              <div className="profile-image-outer-container">
                <div className="profile-image-inner-container bg-color-primary">
                  <span className="profile-image-button bg-color-dark">
                    <i className="fas fa-camera text-light"></i>
                  </span>
                  <img
                    alt={profile && profile.first_name}
                    src={
                      profile && profile.image
                        ? profile.image
                        : "assets/images/user.png"
                    }
                  />
                  {profileImageLoading && (
                    <>
                      <div
                        className="loading-overlay-showing border-radius-50 opacity-8"
                        data-loading-overlay
                        data-plugin-options="{'hideDelay': 500, 'effect': 'floatRings'}"
                      >
                        <div className="loading-overlay border-radius-50">
                          <div className="bounce-loader">
                            <div className="cssload-float-rings-loader">
                              <div className="cssload-float-rings-inner cssload-one"></div>
                              <div className="cssload-float-rings-inner cssload-two"></div>
                              <div className="cssload-float-rings-inner cssload-three"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <input
                  type="file"
                  id="file"
                  name="profileimage"
                  onChange={handleChanges}
                  className="profile-image-input"
                />
              </div>
            </div>

            <h1 className="font-weight-bold text-light mb-20">
              {profile && profile.first_name + " "}
              {profile && profile.last_name}
            </h1>
            <div
              className=" appear-animation mb-20"
              data-appear-animation="fadeIn"
              data-appear-animation-delay="600"
              data-plugin-options="{'accY': -150}"
            >
              <ul className="social-icons text-center rtl-text-center">
                {profile && profile.website ? (
                  <>
                    <li className="social-icons-website">
                      <a
                        href={profile.website}
                        rel="noreferrer"
                        target="_blank"
                        title="Website"
                      >
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                  </>
                ) : null}
                {profile && profile.facebook ? (
                  <>
                    <li className="social-icons-facebook">
                      <a
                        href={profile.facebook}
                        rel="noreferrer"

                        target="_blank"
                        title="Facebook"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                  </>
                ) : null}
                {profile && profile.instagram ? (
                  <>
                    <li className="social-icons-instagram">
                      <a
                        href={profile.instagram}
                        rel="noreferrer"

                        target="_blank"
                        title="Instagram"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </>
                ) : null}
                {profile && profile.youtube ? (
                  <>
                    <li className="social-icons-youtube">
                      <a
                        href={profile.youtube}
                        rel="noreferrer"

                        target="_blank"
                        title="Youtube"
                      >
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                  </>
                ) : null}
                {profile && profile.snapchat ? (
                  <>
                    <li className="social-icons-snapchat">
                      <a
                        href={profile.snapchat}
                        rel="noreferrer"

                        target="_blank"
                        title="Snapchat"
                      >
                        <i className="fab fa-snapchat"></i>
                      </a>
                    </li>
                  </>
                ) : null}
                {profile && profile.spotify ? (
                  <>
                    <li className="social-icons-spotify">
                      <a
                        href={profile.spotify}
                        rel="noreferrer"
                        target="_blank"
                        title="Spotify"
                      >
                        <i className="fab fa-spotify"></i>
                      </a>
                    </li>
                  </>
                ) : null}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="shape-divider shape-divider-bottom">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 1920 136"
          preserveAspectRatio="xMinYMin"
        >
          <path
            d="M 0 0.11 L 0 136 L 1920 136 L 1920 1.9 C 1650.0482 79.0955 1327.0648 134 980 134 C 615.6218 134 277.7878 84.5059 0 0.11 Z"
            fill="#212529"
          />
        </svg>
      </div>
    </section>
  )
}

export default UserHeader