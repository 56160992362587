import { getCurrentLanguageId } from "utils";
import { HttpService } from ".";

export class PostHttpService {

    static async loadPosts(talent, size = 10, page = 1,featured=0) {

        var service = new HttpService();

        let language_id = getCurrentLanguageId();

        var query_text = "?size=" + size;
        if (page) query_text += "&page=" + page;
        if (talent) query_text += "&talent=" + talent;
        if (language_id) query_text += "&language=" + language_id;
        if (language_id) query_text += "&featured=" + featured;

        var response = await service.get(`postapi/post/${query_text}`, null);

        return response;
    }

    static async loadPostVideoGalleries(id){
        // var query = `?size=50`;
        
        var service = new HttpService();
        var response = await service.get(`postapi/post/videogallery/?post=${id}`,null);
        return response;
    }


}