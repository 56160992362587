import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
import ShowVideoModal from '../dialogs/ShowVideoModal';
import SocialLogin from '../forms/user/SocialLogin';
import RegisterAndLoginForm from './RegisterAndLoginForm';


export default function EmailLoginModal({ isOpen,setOpen, setLoggedIn,onClose }) {

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [show_video_is_open, setShowVideoIsOpen] = useState(false);

    useEffect(() => {
        setModalIsOpen(isOpen)
    }, [])

    const closeModal = () => {
        setModalIsOpen(false)
        setOpen && setOpen(false)
        onClose && onClose()
    }


    return (
        <>
            <Modal
                closeTimeoutMS={500}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="Modal modal-social-login "
                ariaHideApp={false}
                overlayClassName="Overlay"
            >
                <div className="modal-content">
                    
                    <div className="modal-body">
                    <div className="btn btn-back" onClick={closeModal}><i className="fa fa-arrow-left"></i></div>

                        <RegisterAndLoginForm />
                    </div>
                    
                </div>
            </Modal>

            
        </>
    )
}
