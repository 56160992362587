import React, { useEffect, useState } from 'react'
import { getDefaultValue, getDefaultDescription, isImage } from '../../utils';
import { addParticipantToCourse, loadCourseDocument, loadCourseParticipants, loadTalentDocument, loadUserTalentDocuments } from '../../utils/Network'
import Modal from 'react-modal';
import { useStateValue } from '../../StateProvider';
import ModalRules from '../dialogs/ModalRules'
import parse from 'html-react-parser'
import UserTalentDocumentUpload from '../userTalentDocumentUpload';
import ModalAcceptRules from '../dialogs/ModalAcceptRules';
import ContestParticipation from './ContestParticipation';

export default function ParticipateButton({ course_id, course, usertalents,
    talent_id, unroll, setMessage }) {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [rules_error, setRulesError] = useState(null)
    const [courseParticipant, setCourseParticipant] = useState(null)
    const [courseParticipants, setCourseParticipants] = useState(null)
    const [courseDocument, setCourseDocument] = useState(null)

    const [usertalent_id, setUserTalentId] = useState(null);
    const [acceptRulesModalIsOpen, setAcceptRulesModalIsOpen] = useState(null);
    const [contestParticipationModalIsOpen, setContestParticipationModalIsOpen] = useState(null);


    const [documents, setDocuments] = useState(null);
    const [talent_document, setTalentDocument] = useState(null);



    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x => x.key == str);

        if (translate) return getDefaultValue(translate);
        else return "#" + str;
    };



    useEffect(() => {

        if (talent_id) {
            //Load document for this talent 
            setLoading(true)

            loadTalentDocument(talent_id).then((document) => {
                setTalentDocument(document)
                setLoading(false)

            })
        }

        if (usertalents) {
            var usertalent = usertalents.find(x => x.talent.id == talent_id);
            if (usertalent) {
                setUserTalentId(usertalent.id);

                if (usertalent) {
                    getUserTalentDocuments(usertalent.id);
                }
            }
        }

        loadCourseParticipants(course_id).then(courseparticipants => {
            setCourseParticipants(courseparticipants)
            var courseparticipant = courseparticipants.find(x => x.course.id === course_id)
            if (courseparticipant) {

                setCourseParticipant(courseparticipant)
                loadCourseDocument(course_id).then(data => {
                    if (data && data.userdocument)
                        setCourseDocument(data.userdocument.file)
                })
            }
        })
    }, [])

    const getUserTalentDocuments = (usertalent_id) => {
        if (!usertalent_id) {
            setDocuments(null)
            return;
        }

        setLoading(true)
        loadUserTalentDocuments(usertalent_id).then(documents => {
            var temp = [];
            setLoading(false)
            documents.map((item, index) => {
                if (index < 1) {
                    item.selected = 1;
                }
                else
                    item.selected = 0;

                temp.push(item)
            })
            setDocuments(temp)
        })

        setLoading(true)

        loadCourseParticipants(course_id).then(courseparticipants => {
            setCourseParticipants(courseparticipants)
            setLoading(false)
            var courseparticipant = courseparticipants.find(x => x.course.id === course_id)
            if (courseparticipant) {

                setCourseParticipant(courseparticipant)
                loadCourseDocument(course_id).then(data => {
                    if (data && data.userdocument)
                        setCourseDocument(data.userdocument.file)
                })
            }
        })


    }
    const setDocumentSelected = document_id => {
        var temp = [];
        documents.map((item, index) => {
            if (item.id === document_id)
                item.selected = item.selected ? 0 : 1;
            else
                item.selected = 0;
            temp.push(item)
        })
        setDocuments(temp)
    }

    const checkIfParticipated = () => {
        loadCourseParticipants().then(courseparticipants => {

            setCourseParticipants(courseparticipants)
            if (usertalent_id) {
                var courseparticipant = courseparticipants.find(x => x.course.id === course_id)

                if (courseparticipant) {

                    setCourseParticipant(courseparticipant)
                }
            }
        })
    }

    const handleChange = (e) => {
        var value = e.target.value;
        setUserTalentId(value)

        getUserTalentDocuments(value);

        if (courseParticipants && value) {
            var courseparticipant = courseParticipants.find(x => x.course === course_id && x.usertalent == value)
            if (courseparticipant) {
                setCourseParticipant(courseparticipant)
            }
        }
        else {
            setCourseParticipant(null)
        }
    }



    const saveChanges = () => {
        // setRulesError("")
        setError(false)
        setLoading(false)

        if (!usertalent_id) {
            setError("Please select a talent")
            return
        }
        else {

            if (courseParticipant) {
                unroll(course, courseParticipant)
            }
            else {

                let selected_document = documents.find(x => x.selected === 1);
                if (!selected_document) {
                    setError(translate("Please select a document"))
                    return;
                }

                setContestParticipationModalIsOpen(true)


            }
        }
    }

    const handleVideoMounted = (element) => {
        if (element !== null && element) {
            element.currentTime = 5;
            //   if (!isNaN(element.duration)) setDuration(element.duration);
        }
    };



    const participate = () => {
        setRulesError("")
        setLoading(false)
        // if (!checked) {
        //     setRulesError(translate("Please accept terms and condititions"))
        //     return;
        // }
        let selected_document = documents.find(x => x.selected === 1);
        setLoading(translate("Please wait..."))
        addParticipantToCourse(course_id, usertalent_id, [selected_document.id]).then(data => {
            setError(false)
            setLoading(false)
            if (data.error) {
                window.location.reload()
                setRulesError(translate("You already participated in this competition"))

            }
            else if (data.Error) {
                setRulesError(translate("You cannot participate in this course again"))
            }
            else {
                setMessage(course_id)
                window.location.reload(false)
                checkIfParticipated()

            }

        })
    }
    return (
        <>
            <ModalAcceptRules isOpen={acceptRulesModalIsOpen} setIsOpen={setAcceptRulesModalIsOpen} />
            <div className="row lan-rtl mt-3">
                <div className="col-md-8 col-sm-8 pr-5 lan-text-right kfont">
                    {courseParticipant ? parse(translate("You already participated in this competition.")) : getDefaultDescription(course.names)}
                </div>
                <div className="col-md-4 col-sm-4  p-x-1">
                    {(!courseParticipant && !loading) &&
                        <div className=" lan-text-right lan-rtl kfont">

                            <>
                                {/* {translate("You havn't uploaded any documents for this talent.")} <a href="dashboard">{translate('return to profile')} </a> */}
                                <div className="row margin-0">
                                    {talent_document && <>
                                        <UserTalentDocumentUpload
                                            talent_id={talent_id}
                                            document_id={talent_document?.id}
                                            max_file_size={200 * 1024 * 1024}
                                            openModal={() => { }}
                                        />
                                    </>}
                                </div>
                            </>

                            <>

                            </>

                        </div>
                    }

                    {(courseDocument && !loading) && <>
                        {isImage(courseDocument) &&
                            <img src={courseDocument} className="img-fluid border-radius-0  " alt="You participated in the course with this document" />
                        }
                        {!isImage(courseDocument) &&
                            <video height="80"  >
                                <source src={document.file} type="video/mp4" />
                            </video>
                        }
                    </>}



                    {(documents && documents.length > 0 && !loading) &&
                        <>

                            <button type="button"
                                onClick={() => { saveChanges() }}
                                data-toggle={courseParticipant && "modal"}
                                data-target={courseParticipant && "#deleteModal"}
                                className="btn btn-primary  btn-block  rounded-35 font-weight-bold text-3 my-10  py-10"
                            >
                                {courseParticipant &&
                                    <>

                                        {translate('Unroll')}
                                    </>
                                }
                                {(!courseParticipant && !loading) &&
                                    <>
                                        <i className="fas fa-user-plus op-7"></i>&nbsp;
                                        {translate('Participate')}
                                    </>
                                }
                            </button>
                        </>
                    }

                    {error &&
                        <div className="alert alert-danger lan-rtl lan-text-right kfont mt-3">
                            {error}
                        </div>
                    }

                    <Modal
                        closeTimeoutMS={500}
                        isOpen={contestParticipationModalIsOpen}
                        onRequestClose={() => { setContestParticipationModalIsOpen(false) }}
                        className="Modal "
                        ariaHideApp={false}
                        overlayClassName="Overlay"
                    >
                        <ContestParticipation talentId={talent_id} onSuccess={(courseParticipant) => { setCourseParticipant(courseParticipant); setContestParticipationModalIsOpen(false) }} />
                    </Modal>
                </div>
            </div>
        </>
    )
}
