import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { ApiHttpService } from '../utils/http/api';
import CourseHistory from './sections/contest/CourseHistory';
import People from './People';
import PageContent from './shared/PageContent';

function Slug() {
    const [loading, setLoading] = useState(true);
    const [type, setType] = useState(null);
    const [data, setData] = useState(null);
    
    const {slug} = useParams()

    useEffect(() => {
        setLoading(true)
        ApiHttpService.findBySlug(slug).then(res=>{
            if(res.length>0){
                console.log(res)
                setData(res[0].object);
                setType(res[0].type)
            }
            setLoading(false)
        })
        
    }, [])
  return (
    <div className="main-parent">
        {loading &&
            <>
                <div className="bounce-loader">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            </>
        }
        {type==="page" && <PageContent key={type} pageItem={data}   />}
        {type==="course_history" && <CourseHistory key={type} courseHistoryItem={data}   />}
        {type==="people" && <People key={type} profile={data}   />}
    </div>
  )
}

export default Slug