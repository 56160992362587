import React, { useState } from 'react'
import {  saveUserTalent } from '../../../utils/Network';
import SelectTalentsDialog from '../../SelectTalentsDialog';
import { useStateValue } from '../../../StateProvider';
import { getDefaultValue } from '../../../utils';


export default function SelectTalentWithAddButton() {

    const [talent, setTalent] = useState(null);

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    // Select    

    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x=>x.key == str );
    
        if (translate) return getDefaultValue(translate);
        else return "#" + str;
      };

    

    const handleSelect = (name,value) => {
        setTalent(value)

    }

    const addTalent = () => {

        setLoading(true)
        setError(null)

        if (!talent) {
            setError("Please select a talent")
            setLoading(false)
            return;
        }

        saveUserTalent(talent).then(data => {
            setLoading(false)
           // callback()
            window.location.href = "dashboard"
        })
    }



    return (
        <div className="row mb-2">
            <div className="col">
                <div className="flex">
                    <div className="form-group mb-1 talentselect-input ">
                        <div >
                            <SelectTalentsDialog onValue={handleSelect} />
                        </div>
                    </div>
                    <div className="form-group mb-1 lan-rtl ">
                        <input type="button" onClick={addTalent} disabled={loading} value={translate("Add Talent")} className="btn btn-primary border-radius-25 padding-12 lan-mr-5 lan-float-right" />
                    </div>
                </div>

                {error && (
                    <>
                        <div className="alert alert-danger">
                            {error}
                        </div>
                    </>
                )}

                {loading && (
                    <>
                        <div className="alert alert-primary">
                            please wait...
                        </div>
                    </>
                )}
            </div>
        </div>
    )

}
