import React, { useState } from "react";
import Modal from "react-modal";
import ImageCrop from "../shared/ImageCrop";

export default function ModalCropImage({ file,onSuccess,onOutput,isOpen = false, setIsOpen ,...props}) {

  const closeModal = () => {
    setIsOpen(false);
  };
  
  return (
    <Modal
      closeTimeoutMS={500}
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
    >
      <div className="modal-content bg-upload-material">
        <div className="modal-header no-border ">
          <button type="button" className="close lan-float-left lan-ml-0" onClick={closeModal}>&times;</button>
        </div>
        <div className="modal-body lan-text-right">
          <ImageCrop file={file} onSuccess={onSuccess} onOutput={onOutput} />
        </div>

      </div>
    </Modal>
  );
}
