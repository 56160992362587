import React, {  useEffect, useState } from 'react'

import {  loadProfile, loadSupportMessages } from '../utils/Network'
import { getDefaultValue } from '../utils';
import { useStateValue } from '../StateProvider';
import HelpModal from '../components/dialogs/HelpModal';
import PageHeaderLight from '../components/shared/PageHeaderLight';
import DashboardAside from './sections/participant/DashboardAside';

export default function Message() {
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState(null);
    const [messages, setMessages] = useState(null)

    
    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x=>x.key == str );
    
        if (translate) return getDefaultValue(translate);
        else return str;
      };

    

    useEffect(() => {
        setLoading(true)
        
        loadProfile().then(profile => {
            setProfile(profile);
            setLoading(false)
            
        });

        
        loadSupportMessages().then(messages=>{
            setMessages(messages);
        });

    }, [])

    return (
      <div>
        <div role="main" className="main">
          <PageHeaderLight />
          <div className="container pt-3 pb-2">
           
            <div className="row pt-2 lan-rtl">
              <DashboardAside />
              <div className="col-lg-9 order-lg-2">
                <h2 className="font-weight-bold line-height-3 text-7 mb-0">
                {translate("Support")}
              </h2>
              <p className="mb-4 pb-2 lan-text-right">
                {translate("Explore frequently asked questions or add a new one")}
              </p>

              <div className="mb-2">
              <a href="faq" className="btn width-200 btn-primary kfont mx-2 btn-upload-file " >
                    {
                      ( <>{translate("FAQ")}</>)             
                    }
                </a>
                &nbsp;
                <a href="#help" className="btn width-200 btn-primary kfont btn-upload-file " data-toggle="modal" data-target="#helpModal">
                    {
                      ( <>{translate("New Ticket")}</>)             
                    }                 
                </a>
               
              </div>

              {messages?.map(message=>(
              <section key={message.id} className="section  py-2  m-0 border-0 border-top-primary mb-4 brb-5">
                      <div className="container ">
                        <div className="row align-items-center justify-content-center">
                          
                            <div className="col" >
                            <div className="row mb-4 px-2 ">
                              <div className="col-md-12 px-0 border-bottom border-bottom-primary ">
                                <h2 className=" font-weight-normal text-6 mb-0 title-cont lan-text-right ">
                                </h2>
                                <div className="pull-right mt-1">
                                
                                  <i className="fa fa-clock"></i>&nbsp;
                                  {message.updated_at.replace('T',' ').replace('Z',' ')}
                                  
                                </div>
                              </div>
                            </div>

                            <div className="row lan-rtl">
                              <div className="col-md-8 col-sm-8 pr-5 kfont lan-text-right">
                              <a href={`support/${message.id}`}>{message.title}</a>
                              </div>
                              
                            </div>
                          </div>
                          
                          
                        </div>
                      </div>
                    </section>
                ))}
              </div>
            </div>
          </div>
        </div>
        <HelpModal />

      </div>
    );
}
