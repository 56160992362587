import React, { Component } from 'react'
import './Dashboard.css'
import MessageDetailsContent from './MessageDetailsContent';

//loadProfile();

export default class MessageDetails extends Component {

    render() {
        let id = this.props.match.params.id;
        

        return (
            <div>
                <MessageDetailsContent id={id} />

            </div>
        )

    }
}
