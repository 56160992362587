import React, { Component, useState } from "react";
import { getTheme, setTheme, changeTheme, getDefaultValue } from "../../utils";
import { loadSettings, saveNewsletter } from "../../utils/Network";
import { useEffect } from "react";
import { useStateValue } from "../../StateProvider";
import NewsLetterForm from "components/shared/NewsLetterForm";

export default function Footer({ }) {
  const [selected_theme, setSelectedTheme] = useState(getTheme());
  const [newsletter_email, setNewsLetterEmail] = useState("");
  const [settings, setSettings] = useState(false);

  const [{ translations }] = useStateValue();

  useEffect(() => {
    setTheme();
    loadSettings().then((settings) => {
      setSettings(settings);
    });
  }, []);

  const translate = (str) => {
    if (!translations) return str;
    var translate = translations.find(x => x.key == str);

    if (translate) return getDefaultValue(translate);
    else return "#" + str;
  };

  const setCurrentTheme = (e) => {
    changeTheme(e);

    setSelectedTheme(getTheme);
  };


  return (
    <footer id="footer" className="bg-color-dark-100 border border-end-0 border-start-0 border-bottom-0 border-color-light-3 mt-0">
      <div className="container text-center my-3 py-5">
        <a href="index.html">
          <img src="/assets/images/logo_top.png" data-src="/assets/images/logo_top.png" width="180" className="mb-4 appear-animation animated fadeIn appear-animation-visible lazyloaded" alt="Kurdishtalent" data-appear-animation="fadeIn" data-appear-animation-delay="300" style={{ animationDelay: "300ms", }} />
        </a>
        <div className="footer-icons-container mt-3">
          <a href="https://apps.apple.com/us/app/kurdish-talent/id1603624883" target="_blank" rel="noreferrer" className="btn btn-primary btn-lg text-3 font-weight-semibold "><img src={'assets/images/apple.png'} alt="" style={{ width: 130, }} /></a>
          &nbsp;&nbsp;
          <a href="https://play.google.com/store/apps/details?id=com.solutionscjit.kurdishtalent" target="_blank" rel="noreferrer" className="btn btn-primary btn-lg text-3 font-weight-semibold "><img src={'assets/images/google.png'} alt="" style={{ width: 130, }} /></a>
        </div>
        <div className="footer-copyright my-4">
          <nav id="sub-menu" className="d-flex align-items-center justify-content-center ">
            <ul>
              <li>
                <i className="fas fa-angle-right"></i>
                <a href="#" onClick={setCurrentTheme} className="ml-1 text-decoration-none">
                  {" "}
                  {translate(selected_theme)}
                </a>
              </li>
              <li>
                <i className="fas fa-angle-right"></i>
                <a href="faq" className="ml-1 text-decoration-none">
                  {" "}
                  {translate("FAQ")}
                </a>
              </li>
              <li>
                <i className="fas fa-angle-right"></i>
                <a href="blog" className="ml-1 text-decoration-none">
                  {" "}
                  {translate("Blog")}
                </a>
              </li>
              <li>
                <i className="fas fa-angle-right"></i>
                <a href="signin" className="ml-1 text-decoration-none">
                  {" "}
                  {translate("Sign Up")}
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <ul className="social-icons social-icons-big social-icons-dark-2">
          <li className="social-icons-facebook"><a href={settings?.facebook} target="_blank" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
          <li className="social-icons-twitter"><a href={settings?.twitter} target="_blank" title="Twitter"><i className="fab fa-twitter"></i></a></li>
          <li className="social-icons-youtube"><a href={settings?.youtube} target="_blank" title="Youtube"><i className="fab fa-youtube"></i></a></li>
          <li className="social-icons-instagram"><a href={settings?.instagram} target="_blank" title="Instagram"><i className="fab fa-instagram"></i></a></li>
        </ul>

      </div>
      <div className="copyright bg-color-dark-100">
        <div className="container text-center py-2">
          <p className="mb-0 footer-cpright">Copyright 2014 - 2024 - Kurdishtalent - All Rights Reserved</p>
        </div>
      </div>
    </footer>
  )
}
