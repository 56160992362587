import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import ContestParticipation from "../contest/ContestParticipation";

export default function ModalContestParticipation({ talent,isOpen = false, setIsOpen }) {
  
  const closeModal = () => {
    setIsOpen(false);
  };


  return (
    <Modal
      closeTimeoutMS={500}
      ariaHideApp={false}
      isOpen={isOpen>0}
      onRequestClose={closeModal}
      className="Modal "
      overlayClassName="Overlay"
    >
      <div className="modal-content w-700 bg-upload-material">
          <div className="modal-header no-border">
            <button type="button" onClick={closeModal} className="close lan-float-left lan-ml-0" data-dismiss="modal" aria-hidden="true">&times;</button>
          </div>
          <div className="modal-body ">
            <ContestParticipation key={talent} talentId={talent} />
          </div>

        </div>
      
    </Modal>
  );
}
